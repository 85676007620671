import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Panel from "../../panel/Panel";
import Form from "./forms/Edit";
import {removeAction as removePanelAction, addAction as addPanelAction} from "../../../actions/panel";
import {getEntitiesById, getEntityById} from "../../../selectors/entity";
import {CheckCircleTwoTone, EditOutlined, TeamOutlined} from '@ant-design/icons';
import {Button, Divider, Result, Spin, Table, Typography} from "antd";
import {fetch} from "../../../api/rest";
import SearchFilter from "../../common/table/filter/Search";
import {PANEL_USER_EDIT} from "../../../factories/panel";
import TableContainer from "../../common/table/Container";

const UserListView = connect(
    (state, {itemIds}) => ({
        items: getEntitiesById(state, itemIds),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({filters, items, loading, onAddPanel, onChange, pagination}) =>
    <Table
        dataSource={items}
        loading={loading}
        onChange={onChange}
        pagination={pagination}
        rowKey={record => record['@id']}
        size="small"
        style={{marginTop: '16px'}}
    >
        <Table.Column
            dataIndex="id"
            filterDropdown={props => <SearchFilter {...props}/>}
            title="ID"
        />

        <Table.Column
            dataIndex="username"
            title="Benutzername"
            sorter={!filters.q}
            width={220}
        />

        <Table.Column
            dataIndex="email"
            title="E-Mail-Adresse"
        />

        <Table.Column
            dataIndex="asanaId"
            title="Asana-ID"
        />

        <Table.Column
            dataIndex="manager"
            render={value => <>{!!value && <CheckCircleTwoTone twoToneColor="#9c9" />}</>}
            title="Manager"
        />

        <Table.Column
            dataIndex="active"
            //            defaultFilteredValue={[true]}
            filterMultiple={false}
            filters={[{text: 'Ja', value: true}, {text: 'Nein', value: false}]}
            render={value => <>{!!value && <CheckCircleTwoTone twoToneColor="#9c9" />}</>}
            title="aktiviert"
        />

        <Table.Column
            render={(value, record, index) => (
                <Button icon={<EditOutlined />} shape="circle" size="small" type="primary" onClick={() => onAddPanel({
                    type: PANEL_USER_EDIT,
                    uid: `users:${record['@id']}:edit`,
                    config: {
                        userId: record['@id']

                    }
                })}/>
            )}
        />
    </Table>
);

export default connect(
    (state, {teamId}) => ({
        team: getEntityById(state, teamId),
    }),
    dispatch => ({
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({team, teamId, onRemovePanel}) => {
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            if (!team) {
                setLoading(true);
                fetch(teamId).catch().finally(() => setLoading(false));
            }
        }, [team, teamId]);

        return (
            <Panel
                backgroundColor="#ef7e06"
                id={`teams:${teamId}:edit`}
                title={<span><TeamOutlined /> {!!team ? team.name : '404 - Not Found'}</span>}
                width={"auto"}
            >
                <Spin spinning={loading} size="large">
                    {team && <>
                        <Form teamId={teamId}/>

                        <Divider style={{marginBottom: 30, marginTop: 20}}/>
                        <Typography.Title level={4} type="secondary">Mitglieder</Typography.Title>
                        <TableContainer resourceUrl={`${teamId}/members`} pageSize={100}>
                            <UserListView/>
                        </TableContainer>
                    </>}

                    {!team && <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, the page you visited does not exist."
                        extra={<Button type="primary" onClick={() => onRemovePanel(`teams:${teamId}:edit`)}>close</Button>}
                    />}
                </Spin>
            </Panel>
        );
    }
);
