import React from "react";
import {connect} from 'react-redux';
import { CheckCircleTwoTone, EditOutlined, PlusOutlined, UserOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Input, Table } from "antd";
import {getEntitiesById, getEntityById} from '../../selectors/entity';
import {addAction as addPanelAction} from "../../actions/panel";
import Panel from "../panel/Panel";
import SearchFilter from "../common/table/filter/Search";
import {
    PANEL_USER_EDIT,
    PANEL_USER_NEW,
    PANEL_USER_TEAM_EDIT
} from "../../factories/panel";
import TableContainer from "../common/table/Container";
import EntityLoader from "../common/EntityLoader";
import {CSVLink} from "react-csv";

const TeamColumn = connect(
    null,
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({entity, entityId, onAddPanel}) => <>
    {entity && <Button
        onClick={() => onAddPanel(
            {
                type: PANEL_USER_TEAM_EDIT,
                uid: `teams:${entityId}:edit`,
                config: {
                    teamId: entityId
                }
            }
        )}
        style={{
            display: 'inline',
            height: 'initial',
            padding: '0',
            textAlign: 'inherit',
            whiteSpace: 'normal'
        }}
        type="link"
    >{entity.name} ({entity.id}/{entity.projectPrefix})</Button>}
</>);

const AuconCSVButton = connect(
    (state, {users}) => ({
        teamUsers: users.map(v => {
            const teamUser = {...v}
            teamUser.currentTeam = getEntityById(state, teamUser.currentTeam) || {
                id: null,
                name: null,
                projectPrefix: null
            };

            return teamUser;
        }),
    })
)(({teamUsers}) => {
    return <Button>
        <CSVLink
            data={teamUsers}
            headers={[
                { label: 'ID', key: 'id' },
                { label: 'Benutzername', key: 'username' },
                { label: 'E-mail', key: 'email' },
                { label: 'Asana ID', key: 'asanaId' },
                { label: 'Aucon Perso Nummer', key: 'auconEmployeeNumber' },
                { label: 'Team Name', key: 'currentTeam.name' },
                { label: 'Team ID', key: 'currentTeam.id' },
                { label: 'Team Prefix', key: 'currentTeam.projectPrefix' },
                { label: 'Aktiviert', key: 'active' },
                { label: 'Manager', key: 'manager' },
            ]}
        ><DownloadOutlined/> CSV für AUCON</CSVLink>
    </Button>
});

const ListView = connect(
    (state, {itemIds}) => ({
        items: getEntitiesById(state, itemIds),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({filters, items, loading, onAddPanel, onChange, onSearch, pagination}) =>
<Panel
    backgroundColor="#ef7e06"
    title={<span><UserOutlined /> Benutzerliste</span>}
    id="users::list"
    width="auto"
>
    <div style={{display: 'flex'}}>
        <Input.Search
            allowClear
            size="large"
            placeholder="Suche nach Benutzern"
            onChange={e => onSearch(e.currentTarget.value)} onSearch={onSearch}
        />
        <Button size="large" style={{marginLeft: '16px'}} type="primary" onClick={() => onAddPanel(
            {
                type: PANEL_USER_NEW,
                uid: `users::new`,
            },
            'users::list')}>
            <PlusOutlined /> Benutzer hinzufügen
        </Button>
    </div>

    <Table
        dataSource={items}
        loading={loading}
        onChange={(pagina, filter = {}, sorter = {}) => {
            if (!!filter.active) filter.active = filter.active[0];

            onChange(pagina, filter, sorter)
        }}
        pagination={pagination}
        rowKey={record => record['@id']}
        size="small"
        style={{marginTop: '16px'}}
        footer={(currentPageData) => (!loading && <AuconCSVButton users={currentPageData}/>)}
    >
        <Table.Column
            dataIndex="id"
            filterDropdown={props => <SearchFilter {...props}/>}
            title="ID"
        />

        <Table.Column
            dataIndex="username"
            filterDropdown={props => <SearchFilter {...props}/>}
            title="Benutzername"
            sorter={!filters.q}
            width={220}
        />

        <Table.Column
            dataIndex="email"
            filterDropdown={props => <SearchFilter {...props}/>}
            title="E-Mail-Adresse"
        />

        <Table.Column
            dataIndex="asanaId"
            title="Asana-ID"
        />

        <Table.Column
            dataIndex="manager"
            render={value => <>{!!value && <CheckCircleTwoTone twoToneColor="#9c9" />}</>}
            title="Manager"
        />

        <Table.Column
            dataIndex="active"
//            defaultFilteredValue={[true]}
            filterMultiple={false}
            filters={[{text: 'Ja', value: true}, {text: 'Nein', value: false}]}
            render={value => <>{!!value && <CheckCircleTwoTone twoToneColor="#9c9" />}</>}
            title="aktiviert"
        />

        <Table.Column
            dataIndex="currentTeam"
            title="Team"
            render={(value, record, index) => <>
                {value !== null &&
                    <EntityLoader entityId={value}>
                        <TeamColumn/>
                    </EntityLoader>
                }
            </>}
            sorter={!filters.q}
            width={220}
        />

        <Table.Column
            render={(value, record, index) => (
                <Button icon={<EditOutlined />} shape="circle" size="small" type="primary" onClick={() => onAddPanel({
                        type: PANEL_USER_EDIT,
                        uid: `users:${record['@id']}:edit`,
                        config: {
                            userId: record['@id']

                        }},
                    'users::list')}
                />
            )}
            title="Aktionen"
        />
    </Table>
</Panel>);

export default () => <TableContainer resourceUrl="users?groups[]=User:read&groups[]=UserTeam:read" pageSize={100}>
    <ListView/>
</TableContainer>
