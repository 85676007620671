import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import {
    AreaChartOutlined,
    ControlOutlined,
    FileProtectOutlined,
    FundViewOutlined,
    HourglassOutlined,
    LogoutOutlined,
    RobotOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';

import { Avatar, Drawer, Dropdown, Layout, Menu, Typography } from 'antd';
import MainMenu from "../components/menu/MainMenu";
import HistoryMenu from "../components/menu/HistoryMenu";
import {ConfigProvider} from 'antd';
import locale from '../locale/de_DE';
import SecurityContext from "../security/SecurityContext";
import TokenStorage from "../security/authentication/token/storage/TokenStorage";
import "../utils/directory/countryData";
import {addAction as addPanelAction} from '../actions/panel';
import useEventListener from "@use-it/event-listener";
import Pacman from 'pacman-react';
import createPanel, {
    PANEL_COOKIECONSENT_PROVIDER_LIST,
    PANEL_PROJECT_JOB_DAILY_REVIEW,
    PANEL_USER_LIST,
    PANEL_USER_TEAM_LIST,
} from "../factories/panel";
import {fetch} from "../api/rest";

const {Content, Header, Sider} = Layout;
const {Text} = Typography;

export default connect(
    state => ({
        panels: state.panelState
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(props => {
    const [shutdown, setShutdown] = useState(false);
    const [keyShutdown, setKeyShutdown] = useState(false);

    useEventListener('keydown', e => {if (e.code === 'ShiftLeft') setKeyShutdown(true)});
    useEventListener('keyup', e => {if (e.code === 'ShiftLeft') setKeyShutdown(false)});

    useEffect(() => {
        fetch('users?items_per_page=1000').catch(e => {});
    }, []);

    return (
        <ConfigProvider locale={locale}>
            <SecurityContext.Consumer>{({deauthenticate}) => (
            <Layout style={{height: '100vh'}}>
                <Drawer height="auto" bodyStyle={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}} width="auto" drawerStyle={{backgroundColor: 'black'}} closable={false} onClose={() => setShutdown(false)} placement="bottom" visible={shutdown}>
                    {shutdown && <Pacman key="pacman" gridSize={12} onEnd={() => setShutdown(false)} />}
                </Drawer>
                <Sider width={200}>
                    <Layout
                        style={{display: 'flex', flexDirection: 'column', maxHeight: '100%', height: '100%'}}>
                        <Header style={{backgroundColor: '#4A4A4A', display: 'flex', alignItems: 'center'}}>
                            <img src="android-chrome-48x48.png" alt="MANDARIN MEDIEN - Logo" style={{height: 24, marginLeft: -6, marginTop: -6}}/>
                            <Text strong style={{color: 'white', flexGrow: 1}}>
                                MM Zentrale
                            </Text>

                            <Dropdown overlay={<Menu>
                                <Menu.SubMenu title={<span><FileProtectOutlined /> CookieConsent</span>}>
                                    <Menu.Item onClick={() => props.onAddPanel({type: PANEL_COOKIECONSENT_PROVIDER_LIST, uid: 'cookieconsentproviders::list'})}>
                                        <span><RobotOutlined /> Anbieter</span>
                                    </Menu.Item>
                                </Menu.SubMenu>
                                <Menu.SubMenu title={<span><ControlOutlined /> Adminbereich</span>}>
                                    <Menu.Item onClick={() => props.onAddPanel({type: PANEL_USER_LIST, uid: 'users::list'})}>
                                        <span><UserOutlined /> Benutzer</span>
                                    </Menu.Item>
                                    <Menu.Item onClick={() => props.onAddPanel({type: PANEL_USER_TEAM_LIST, uid: 'teams::list'})}>
                                        <span><TeamOutlined /> Teams</span>
                                    </Menu.Item>
                                </Menu.SubMenu>
                                <Menu.SubMenu title={<span><AreaChartOutlined /> Management</span>}>
                                    <Menu.Item onClick={() => props.onAddPanel({type: PANEL_PROJECT_JOB_DAILY_REVIEW, uid: 'projects:job:daily:review'})}>
                                        <span><FundViewOutlined style={{color: '#F2C5C5'}} /> Tägliche Rückschau</span>
                                    </Menu.Item>
                                </Menu.SubMenu>
                                <Menu.Divider/>
                                {keyShutdown && <Menu.Item onClick={() => setShutdown(true)}>
                                    <span><HourglassOutlined /> Ruhezustand</span>
                                </Menu.Item>}
                                {!keyShutdown && <Menu.Item onClick={deauthenticate}>
                                    <span><LogoutOutlined /> Abmelden</span>
                                </Menu.Item>}
                            </Menu>}>
                                <Avatar size="small" icon={<UserOutlined />} src={TokenStorage.getToken().user.profiles.google.imageUrl} style={{boxShadow: '0 0 2px white'}}/>
                            </Dropdown>

                        </Header>
                        <div style={{borderRight: '1px solid #e9e9e9'}}>
                            <MainMenu/>
                        </div>
                        <div style={{
                            flexGrow: 1,
                            borderTop: '2px solid #e9e9e9',
                            borderRight: '1px solid #e9e9e9',
                            overflow: 'auto',
                            backgroundColor: 'white'
                        }}>
                            <HistoryMenu/>
                        </div>
                    </Layout>
                </Sider>
                <Content style={{overflowX: 'auto', background: 'white', display: 'flex', flexWrap: 'nowrap'}}>
                    {props.panels.positions.map((value) =>
                        createPanel(props.panels.items[value])
                    )}
                </Content>
            </Layout>
            )}</SecurityContext.Consumer>
        </ConfigProvider>
    );
});
