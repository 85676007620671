import React from "react";
import {connect} from "react-redux";
import { CloseCircleOutlined } from '@ant-design/icons';
import { Layout, Typography } from "antd";
import PropTypes from "prop-types";
import {removeAction} from "../../actions/panel";
import {isActive} from "../../selectors/panel";

const { Content, Header } = Layout;
const { Text } = Typography;

class Panel extends React.Component {
    static defaultProps = {
        backgroundColor: '#3d8adf',
        headerColor: 'white',
        width: 640,
    };

    static propTypes = {
        backgroundColor: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        headerColor:  PropTypes.string.isRequired,
        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node
        ]).isRequired,
    };

    constructor(props) {
        super(props);

        this.mainRef = React.createRef();
    }

    componentDidMount() {
        this.activate();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isActive} = this.props;
        if (isActive && (isActive !== prevProps.isActive)) {
            this.activate();
        }
    }

    activate() {
        this.mainRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }

    render() {
        const {id, onRemovePanel} = this.props;

        return (
            <div ref={this.mainRef} style={{display: 'flex', flexDirection: 'column', flex: '0 0 auto', maxWidth: '100%', width: this.props.width}}>
                <Header
                    style={{
                        alignItems: 'center',
                        backgroundColor: this.props.backgroundColor,
                        borderRight: '1px solid rgba(0,0,0,.2)',
                        boxShadow: 'inset 5px 0 7px -5px rgba(0,0,0,0.2)',
                        display: 'flex'
                    }}
                >
                    <Text
                        ellipsis
                        strong
                        style={{
                            flexGrow: 1,
                            color: this.props.headerColor
                        }}
                    >
                        {this.props.title}
                    </Text>
                    <CloseCircleOutlined
                        onClick={() => onRemovePanel(id)}
                        style={{
                            color: this.props.headerColor,
                            fontSize: '1.5em',
                            marginLeft: '1em'
                        }} />
                </Header>
                <Content
                    style={{
                        overflow: 'auto',
                        padding: '30px',
                        borderRight: '1px solid rgba(0,0,0,.2)',
                        boxShadow: 'inset 5px 0 7px -5px rgba(0,0,0,0.2)',
                    }}
                >
                    {this.props.children}
                </Content>
            </div>
        );
    }
}

export default connect(
    (state, {id}) => ({
        isActive: isActive(state, id)
    }),
    dispatch => ({
        onRemovePanel: (uniqueKey) => dispatch(removeAction(uniqueKey)),
    })
)(Panel);
