import React from "react";
import Panel from "../../panel/Panel";
import Form from "./forms/New";
import { InsuranceOutlined } from '@ant-design/icons';

export default () => <Panel
    backgroundColor="#882f9c"
    id="cookieconsentsites::new"
    title={<span><InsuranceOutlined /> Site erstellen</span>}
>
    <Form/>
</Panel>
