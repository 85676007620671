import {connect} from "react-redux";
import {addAction as addPanelAction} from "../../../../actions/panel";
import {Button} from "antd";
import {PANEL_CUSTOMER_SHOW} from "../../../../factories/panel";
import {getEntityById} from "../../../../selectors/entity";
import React from "react";

export default connect(
  (state, {customerId}) => ({
    customer: getEntityById(state, customerId),
  }),
  dispatch => ({
    onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
  })
)(({customer, customerId, onAddPanel}) => <>
  {customer && <Button
    onClick={() => onAddPanel(
      {
        type: PANEL_CUSTOMER_SHOW,
        uid: `customers:${customerId}:show`,
        config: {
          customerId: customerId
        }
      }
    )}
    style={{
      display: 'inline',
      height: 'initial',
      padding: '0',
      textAlign: 'inherit',
      whiteSpace: 'normal'
    }}
    type="link"
  >{customer.name}</Button>}
</>);
