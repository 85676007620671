const INITIAL_STATE = {};

function reducer(state = INITIAL_STATE, action) {
    const entityCollection = {...state};

    switch (action.type) {
        case 'ENTITIES_UPDATE' : {
            const {payload} = action;

            payload.forEach((item => {
                entityCollection[item['@id']] = item;
            }));

            return entityCollection;
        }

        case 'ENTITY_REMOVE' : {
            const {payload} = action;

            delete entityCollection[payload['@id']];

            return entityCollection;
        }

        default :
            return state;
    }
}

export default reducer;
