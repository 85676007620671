import React from "react";
import Panel from "../panel/Panel";
import { ClockCircleOutlined } from '@ant-design/icons';
import { Tabs } from "antd";
import TaskList from "./asana/TaskList";

export default () => <Panel
    backgroundColor="#5A6650"
    id="timetracking::main"
    title={<span><ClockCircleOutlined /> Zeiterfassung</span>}
    width="auto"
>
    <Tabs type="card">
        <Tabs.TabPane tab="Asana-Tasks" key="1">
            <TaskList/>
        </Tabs.TabPane>
    </Tabs>
</Panel>
