import React from "react";
import UserItem from "./UserItem";

export default ({userRecords, selectedTeamId}) => Object.keys(userRecords).map((userId) =>
    <UserItem
      key={userId}
      selectedTeamId={selectedTeamId}
      records={userRecords[userId]}
      userId={userId}
    />
);
