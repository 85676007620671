import React from "react";
import {Input} from "antd";

class NamedUrlInput extends React.Component {
    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return {
                ...(nextProps.value || {}),
            };
        }

        return null;
    }

    constructor(props) {
        super(props);

        this.state = props.value || {};
    }

    handleTitleChange = e => {
        const title = e.target.value;

        if (!('value' in this.props)) {
            this.setState({title});
        }

        this.triggerChange({title});
    };

    handleUriChange = e => {
        const uri = e.target.value;

        if (!('value' in this.props)) {
            this.setState({uri});
        }

        this.triggerChange({uri});
    };

    triggerChange = changedValue => {
        const {onChange} = this.props;
        if (onChange) {
            onChange({
                ...this.state,
                ...changedValue,
            }, this.props.index);
        }
    };

    render() {
        const {title, uri} = this.state;

        return <Input
            addonAfter={this.props.addonAfter}
            addonBefore={
                <Input
                    autoComplete="none"
                    onChange={this.handleTitleChange}
                    placeholder="Name"
                    required
                    style={{
                        width: '100px',
                        backgroundColor: 'inherit',
                        border: '0 none',
                        padding: 0,
                        height: 'inherit',
                    }}
                    value={title}
                />
            }
            autoComplete="none"
            onChange={this.handleUriChange}
            placeholder="URL"
            type="url"
            value={uri}
        />
    }
}

export default NamedUrlInput;
