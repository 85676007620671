export const languageSettings = {
    cookieSettings: "Cookie Settings",
    cookieSettingsDescription: "Cookie Settings Description",
    showServices: "Show Services",
    buttonAcceptAll: "Button Accept All",
    buttonSave: "Button Save",
    buttonAdvancedSettings: "Button Advanced Settings",
    buttonBack: "Button Back",
    buttonAbort: "Button Abort",
    serviceProvider: "Service Provider",
    servicePurpose: "Service Purpose",
    serviceSubServicesHint: "Service Sub Services Hint",
    serviceSubServicesButton: "Service Sub Services Button",
    serviceCookieName: "Service Cookie Name",
    serviceCookieHosts: "Service Cookie Hosts",
    serviceCookieLifetime: "Service Cookie Lifetime",
    servicePolicy: "Service Policy",
    legal: "Legal",
    policy: "Policy",
    contentBlockerMessage: "Content Blocker Message",
    contentBlockerWarningMessage: "Content Blocker Warning Message",
    contentBlockerEnableService: "Content Blocker Enable Service",
    contentBlockerShowSettings: "Content Blocker Show Settings"
};
