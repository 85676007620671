export const randomString = (length = 32) => {
    let str = '';
    let minChar = 48, maxChar = 90;
    let i;

    for (i=0; i<length; ++i) {
        str += String.fromCharCode(minChar + Math.floor(Math.random() * (maxChar - minChar)))
    }

    return str;
};
