import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Panel from "../../panel/Panel";
import {Divider, Progress, Spin} from "antd";
import {FundViewOutlined} from "@ant-design/icons";
import moment from "moment";
import {fetch} from "../../../api/rest";
import {stringify} from 'qs';
import {getEntitiesById, getEntityById} from "../../../selectors/entity";
import FilterForm from "./dailyreview/FilterForm";
import UserList from "./dailyreview/UserList";
import Summary from "./dailyreview/Summary";

const UserListHOC = connect(
  (state, {recordIds}) => ({
    records: getEntitiesById(state, recordIds),
    state: state
  })
)(({records, state, selectedTeamId}) => {
  const [userRecords, setUserRecords] = useState({});

  useEffect(() => {
    const executors = {};
    records.forEach(record => {
      const job = getEntityById(state, record.job);

      if (job) {
        if (!executors.hasOwnProperty(job.executor)) {
          executors[job.executor] = [];
        }

        executors[job.executor].push(record);
      }
    });

    setUserRecords(executors);
  }, [records])

  return <>
    <UserList selectedTeamId={selectedTeamId} userRecords={userRecords}/>
    <Divider style={{margin: 0}}/>
    {records.length>0 && <Summary records={records}/>}
  </>;
});

export default () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [recordIds, setRecordIds] = useState([]);
  const [selectedTeamId, setSelectedTeamId] = useState();
  const [filterParams, setFilterParams] = useState({
    groups: [
      'JobRecord:read',
      'Job:read',
      'Project:read',
      'UserTeam:read',
      'User:read',
      'Customer:read',
    ],
    startedAt: {
      after: moment().hours(0).minutes(0).seconds(0),
      before: moment().hours(0).minutes(0).seconds(0).add(1, 'day').subtract(1, 'second')
    }
  });

  useEffect(() => {
    setSelectedTeamId(filterParams['executor.assignedTeams']);
  }, [filterParams]);

  const load = (params, options = {}, results = []) => {
    let queryParams = {page: 1, ...params, items_per_page: 30};
    let nextPage = `/jobs/records?${stringify(queryParams)}`;

    fetch(nextPage, options)
      .then(json => {
        results = results.concat(json['hydra:member']);

        setProgress(Math.round(100 / json['hydra:totalItems'] * results.length));

        if (typeof json['hydra:view']['hydra:next'] !== 'undefined') {
          load({...queryParams, page: queryParams.page+1}, options, results);
        } else {
          setProgress(100);

          setTimeout(() => {
            setLoading(false);
            setProgress(0);

            setRecordIds(results.map(value => value['@id']));
          }, 250);
        }
      })
  }

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) {
      setRecordIds([]);
      setLoading(true);
      const controller = new AbortController();
      const {signal} = controller;

      load({
        ...filterParams,
        startedAt: {
          after: filterParams.startedAt.after.toLocaleString(),
          before: filterParams.startedAt.before.toLocaleString()
        }
      }, {signal});

      return controller.abort.bind(controller);
    }

    setInitialized(true);
  }, [filterParams]);

  const Spinner = <div style={{display: 'flex', left: 10, width: '100%', justifyContent: 'center'}}>
    <Progress percent={progress} showInfo={true} trailColor="#ccc" type='dashboard' size='small'/>
  </div>

  return <Panel
    backgroundColor="#f2c5c5"
    title={<span><FundViewOutlined/> Tägliche Rückschau</span>}
    id="projects:job:daily:review"
    width="auto"
  >
    <Spin spinning={loading} indicator={Spinner}>
      <FilterForm filterParams={filterParams} setFilterParams={setFilterParams}/>
      <UserListHOC selectedTeamId={selectedTeamId} recordIds={recordIds}/>
    </Spin>
  </Panel>
};
