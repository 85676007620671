import React from "react";
import { DeleteOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Select, Form } from "antd";
import {RULE_OPERATORS} from "../../directory/siteServiceRules";

const SiteServiceRule = ({rule, index, onRemove = () => {}}) => {

    return <>
        <Form.Item name={['theme', 'rules', index, 'variable', 'name']} initialValue={rule.variable.name} noStyle>
            <Input type="hidden"/>
        </Form.Item>
        <Form.Item name={['theme', 'rules', index, 'variable', 'type']} initialValue={rule.variable.type} noStyle>
            <Input type="hidden"/>
        </Form.Item>
        <Form.Item name={['theme', 'rules', index, 'variable', 'value']} initialValue={rule.variable.value} noStyle>
            <Input type="hidden"/>
        </Form.Item>
        <Form.Item name={['theme', 'rules', index, 'value']} rules={[{required: true, message: 'Dieser Wert sollte nicht leer sein.'}]}>
            <Input
                autoComplete="none"
                addonAfter={<Button size="small" icon={<DeleteOutlined />} type="link" onClick={() => onRemove(index, 'remove')}/>}
                addonBefore={<>
                    <span>{rule.variable.name}:&#160;</span>
                    <Form.Item name={['theme', 'rules', index, 'operator']} initialValue={'eq'} rules={[{required: true, message: 'Dieser Wert sollte nicht leer sein.'}]} noStyle>
                        <Select style={{width: 175}}>
                            {Object.keys(RULE_OPERATORS).map(op =>
                                <Select.Option key={op} value={op}>{RULE_OPERATORS[op]}</Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                </>}
            />
        </Form.Item>
    </>;
};

export default SiteServiceRule;
