import React, {forwardRef, useEffect, useState} from "react";
import {fetch} from "../../../api/rest";
import {Select, Spin} from "antd";
import {debounce} from "lodash";
import {stringify} from "qs";

export default forwardRef((props, ref) => {
    const {
        render = item => <Select.Option key={item.id} value={item['@id']}>{item[textParam]}</Select.Option>,
        queryParam = 'q',
        resourceUrl,
        textParam = 'name',
        value
    } = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (value) {
            load({id: String(value).replace(/(.*?(\d+))/, "$2")});
        } else {
            load([]);
        }
    }, [value]);

    const search = str => {
        let params = {};
        if (!!str) params[queryParam] = str;
        load(params);
    };

    const load = debounce(queryParams => {
        setLoading(true);
        setData([]);

        const query = stringify(queryParams);

        fetch(`${resourceUrl}${resourceUrl.indexOf('?') !== -1 ? '&' : '?'}${query}`)
            .then(json => {
                setData(json['hydra:member']);
            })
            .finally(() => {
                setLoading(false);
            })
    }, 200, {trailing: true});

    return <Select
        id={null}
        filterOption={false}
        loading={loading}
        notFoundContent={loading ? <Spin style={{width: "100%"}} size="small" /> : null}
        onSearch={search}
        showSearch
        style={{width: '100%'}}
        {...props}
    >
        {data.map(render)}
    </Select>
});
