import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from "redux-persist/integration/react";
import {IntlProvider} from "react-intl";
import moment from "moment";
import 'moment/locale/de';
import store, {persistor} from './store';
import './mercureWorker';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

moment.fn.toJSON = function() {
    return this.format();
};

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <IntlProvider locale={navigator.language}>
                <App />
            </IntlProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
