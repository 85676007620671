import React, {useState} from "react";
import {connect} from "react-redux";
import { SaveOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Divider, Input as AntdInput, Row, Spin, Switch, Typography, Form } from "antd";
import {
    addAction as addPanelAction,
    removeAction as removePanelAction
} from "../../../actions/panel";
import {fetch} from "../../../api/rest";
import {SubmissionError} from "redux-form";
import {generate as generatePassord} from "generate-password";
import {
    PANEL_USER_EDIT
} from "../../../factories/panel";
import RemoteSelect from "../../common/form/RemoteSelect";
import {submissionErrorToFormFields} from "../../../utils/submissionErrorToFormFields";

class Input extends React.Component {
    render() {
        return <AntdInput autoComplete="none" {...this.props}/>;
    }
}

export default (connect(
    null,
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({onAddPanel, onRemovePanel}) => {
    const [loading, setLoading] = useState(false);
    const [newPassword] = useState(generatePassord({length: 12, numbers: true, strict: true, symbols: true}));
    const [form] = Form.useForm();

    const handleSubmit = values => {

        const {asanaId, auconEmployeeNumber} = values;

        if (typeof asanaId === 'string' && asanaId.length === 0) values.asanaId = null;
        if (typeof asanaId === 'number') values.asanaId = String(asanaId);

        if (typeof auconEmployeeNumber === 'string') {
            if (auconEmployeeNumber.length === 0) {
                values.auconEmployeeNumber = null;
            } else {
                values.auconEmployeeNumber = Number(auconEmployeeNumber);
            }
        }

        setLoading(true);

        return fetch('/users', {method: 'POST', body: JSON.stringify(values)})
            .then(data => {
                setLoading(false);
                onAddPanel(
                    {
                        type: PANEL_USER_EDIT,
                        uid: `users:${data['@id']}:edit`,
                        config: {
                            userId: data['@id']
                        }},
                    'users::new'
                );
                onRemovePanel(`users::new`);
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <Spin spinning={loading} size="large">
            <Form layout="vertical" onFinish={handleSubmit} initialValues={{
                'plainPassword': newPassword,
                'plainPasswordConfirm': newPassword,
                'active': true,
                'manager': false,
            }}
                  form={form}>

                <Typography.Title level={4} type="secondary">Anmeldedaten</Typography.Title>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Benutzername" name={'username'} rules={[{type: 'string'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label="E-Mail" name={'email'}>
                            <Input type="email"/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Passwort" hasFeedback name={'plainPassword'} rules={[{
                            required: true,
                            message: 'Bitte tragen Sie ein Passwort ein.'
                        }]}>
                            <AntdInput.Password/>
                        </Form.Item>

                        <Form.Item label="Passwort bestätigen" dependencies={['plainPassword']} hasFeedback name={'plainPasswordConfirm'} rules={[
                            {
                                required: true,
                                message: 'Bitte bestätigen Sie das Passwort.'
                            },
                            ({getFieldValue}) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('plainPassword') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject('Die beiden Passwörter stimmen nicht überein.')
                                }
                            }),
                        ]}>
                            <AntdInput.Password/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider/>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Vorname" name="firstname">
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Nachname" name="lastname">
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider/>
                <Typography.Title level={4} type="secondary">Einstellungen</Typography.Title>
                <Row gutter={10} type="flex">
                    <Col span={4}>
                        <Form.Item label="aktiviert" name={'active'} valuePropName={'checked'}>
                            <Switch size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Manager" name={'manager'} valuePropName={'checked'}>
                            <Switch size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item label="Team" name={'currentTeam'} rules={[{required: true, message: 'Dieser Wert sollte nicht leer sein.'}]}>
                            <RemoteSelect resourceUrl="/users/teams?items_per_page=1000&order[name]=ASC"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider/>

                <Typography.Title level={4} type="secondary">Mitarbeiterdaten</Typography.Title>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Asana-ID" name={'asanaId'} rules={[
                            {pattern: /^\d+$/, message: 'Dieses Feld darf nur Ziffern enthalten'},
                            {max: 19, message: 'Diese Zeichenkette ist zu lang. Sie sollte höchstens 19 Zeichen haben.'}
                        ]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Personalnummer (Aucon)" name={'auconEmployeeNumber'} rules={[
                            {pattern: /^\d+$/, message: 'Dieses Feld darf nur Ziffern enthalten'}
                        ]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{textAlign: 'right'}}>
                    <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">Speichern</Button>
                </div>
            </Form>
        </Spin>
    );
}));