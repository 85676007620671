import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import {fetch} from "../../api/rest";
import { EditOutlined, FilterOutlined, PlusOutlined, ProjectOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Badge, Button, Col, Input, Row, Select, Switch, Table, Typography, Form } from "antd";
import {getEntitiesById} from '../../selectors/entity';
import {addAction as addPanelAction} from "../../actions/panel";
import Panel from "../panel/Panel";
import {
    PANEL_PROJECT_EDIT,
    PANEL_PROJECT_NEW
} from "../../factories/panel";
import TableContainer from "../common/table/Container";
import moment from "moment";
import {states as projectStates} from "../../utils/directory/projectData";
import RemoteSelect from "../common/form/RemoteSelect";
import User from "../common/table/column/User";
import EntityLoader from "../common/EntityLoader";
import CustomerColumn from "../common/table/column/Customer";

const TeamColumn = ({entity}) => <>{entity && entity.name}</>;

const ActionColumn = connect(
    null,
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({projectId, onAddPanel}) => <Button
    icon={<EditOutlined />}
    shape="circle"
    size="small"
    type="primary"
    onClick={() => onAddPanel(
        {
            type: PANEL_PROJECT_EDIT,
            uid: `projects:${projectId}:edit`,
            config: {
                projectId: projectId
            }
        },
        'projects::list'
    )}
/>);

const AsanaColumn = ({asanaId, loading}) => {
    const [completed, setCompleted] = useState(0);
    const [uncompleted, setUncompleted] = useState(0);

    useEffect(() => {
        if (asanaId && !loading) {
            const c1 = new AbortController();
            const sig1 = c1.signal;
            const c2 = new AbortController();
            const sig2 = c2.signal;

            fetch(`/asanabridge/projects/${asanaId}/tasks?items_per_page=0&completed=true`, {signal: sig1})
                .then(json => {
                    setCompleted(json['hydra:totalItems']);
                }).catch(err => {});

            fetch(`/asanabridge/projects/${asanaId}/tasks?items_per_page=0&completed=false`, {signal: sig2})
                .then(json => {
                    setUncompleted(json['hydra:totalItems']);
                }).catch(err => {});

            return () => {
                c1.abort();
                c2.abort();
            }
        }

    }, [asanaId, loading]);

    return <>
        <Badge count={completed} style={{backgroundColor: "green"}} title="Complete"/>
        <Badge count={uncompleted} style={{backgroundColor: "blue"}} title="Uncomplete"/>
    </>
};

const AbrechnungColumn = ({items}) => {
    return <table>
        <tbody>
            <tr style={{whiteSpace: 'nowrap'}}>
                <th>PM gestellt:</th><td>€ {Number(items.reduce((total, item) => total + item.extend, 0)).toFixed(2)}</td>
            </tr>
            <tr style={{whiteSpace: 'nowrap'}}>
                <th>PM bezahlt:</th><td>€ {Number(items.filter(v => v.paid).reduce((total, item) => total + item.extend, 0)).toFixed(2)}</td>
            </tr>
        </tbody>
    </table>
};

const ExpandedRow = ({resourceUrl}) => {
    const [subItems, setSubItems] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const controller = new AbortController();
        const {signal} = controller;

        if (!loaded) {
            setLoading(true);
            fetch(resourceUrl+'?items_per_page=1000', {signal})
                .then(json => {
                    setSubItems(json['hydra:member']);
                })
                .catch(err => {})
                .finally(() => {
                    setLoading(false);
                });
            setLoaded(true);
        }

        return controller.abort.bind(controller);
    }, []);

    return <Table
        dataSource={subItems}
        loading={loading}
        pagination={false}
        rowKey={record => record['@id']}
        showHeader={false}
        size="small"
    >
        <Table.Column
            dataIndex="id"
            title="ID"
        />

        <Table.Column
            dataIndex="name"
            title="Name"
            width={220}
        />

        <Table.Column
            dataIndex="customer"
            render={(value, record, index) => <CustomerColumn customerId={value}/>}
            title="Kunde"
            width={220}
        />

        <Table.Column
            align="center"
            dataIndex="manager"
            render={(v, record, index) => <>
                <User userId={v} TooltipTitle={
                    ({value}) => <>{value && `${value.username} (${value.email})`}</>
                }/>
                <br />
                <EntityLoader entityId={record.team}>
                    <TeamColumn/>
                </EntityLoader>
            </>}
            title="Projektmanagement"
            width={220}
        />

        <Table.Column
            dataIndex="asanaId"
            render={(value) => <AsanaColumn asanaId={value} loading={loading}/>}
            title="Asana-Tasks"
        />

        <Table.Column
            dataIndex="endAt"
            render={(value, record, index) => (
                <Typography.Text>{!!value && moment(value).format('L')}</Typography.Text>
            )}
            title="Deadline"
        />

        <Table.Column
            dataIndex="budget.time"
            render={(value, record, index) => {
                const duration = moment.duration(value * 1000);

                return <table>
                    <tbody>
                        <tr>
                            <th><span role="img" aria-label="Clock">⏱</span></th>
                            <td align="right">{Math.floor(duration.asHours()) + ':' + String(duration.minutes()).padStart(2, '0')}</td>
                        </tr>
                        <tr>
                            <th><span role="img" aria-label="Euro">💶</span></th>
                            <td align="right">{Number(record.budget.monetary).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            }}
            title="Budget"
        />

        <Table.Column
            dataIndex="payoffs"
            render={(value) => <AbrechnungColumn items={value}/>}
            title="Abrechnung"
        />

        <Table.Column
            dataIndex="state"
            render={(value, record, index) => {
                return projectStates[value].text;
            }}
            title="Status"
        />

        <Table.Column
            render={(value, record, index) => <ActionColumn projectId={record['@id']}/>}
        />
    </Table>
};

const FilterForm = (({onFilter, style}) => {
    const [form] = Form.useForm();

    const handleSubmit = values => {
        return onFilter(values);
    };

    return (
        <Form layout="horizontal" onFinish={handleSubmit} style={style} form={form}>
            <Form.Item name={'q'} form={form}>
                <Input.Search
                    allowClear
                    size="large"
                    placeholder="Suche nach Projekten"
                />
            </Form.Item>

            <Row gutter={10} type="flex">
                <Col span={18}>
                    <Row gutter={10} type="flex">
                        <Col span={8}>
                            <Form.Item name={'manager'}>
                                <RemoteSelect allowClear placeholder="Manager" resourceUrl="/users" textParam="email" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name={'state'}>
                                <Select allowClear placeholder="Status">
                                    {Object.keys(projectStates).map((value) => (
                                        <Select.Option key={value} value={value}>{projectStates[value].text}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name={'customer'}>
                                <RemoteSelect allowClear placeholder="Kunde" resourceUrl="customers" />
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item extra="nur Hauptprojekte" name={'exists[parent]'} valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col span={3} style={{marginLeft: 'auto'}}>
                    <Form.Item>
                        <Button icon={<FilterOutlined />} type="primary" htmlType="submit" style={{width: "100%"}}>Filtern</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

const ListView = connect(
    (state, {itemIds}) => ({
        items: getEntitiesById(state, itemIds),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({filters, items, loading, onAddPanel, onChange, onFilter, pagination}) => <Panel
    backgroundColor="#3ed115"
    title={<span><ProjectOutlined /> Projektliste</span>}
    id="projects::list"
    width="auto"
>
    <div style={{display: 'flex'}}>
        <FilterForm onFilter={onFilter} style={{flexGrow: 1}}/>
        <Button size="large" style={{marginLeft: '16px'}} type="primary" onClick={() => onAddPanel(
            {
                type: PANEL_PROJECT_NEW,
                uid: `projects::new`,
            },
            'projects::list')}>
            <PlusOutlined /> Projekt hinzufügen
        </Button>
    </div>

    <Table
        dataSource={items}
        loading={loading}
        onChange={onChange}
        pagination={pagination}
        expandedRowRender={(record) => <ExpandedRow resourceUrl={record['@id'] + '/childrens?groups[]=Customer:read&groups[]=Project:read&groups[]=ProjectPayoff:read&groups[]=UserTeam:read'}/>}
        defaultExpandAllRows={true}
        rowKey={record => record['@id']}
        size="small"
        style={{marginTop: '16px'}}
    >
        <Table.Column
            dataIndex="id"
            title="ID"
        />

        <Table.Column
            dataIndex="name"
            title="Name"
            sorter={!filters.q}
            width={220}
        />

        <Table.Column
            dataIndex="customer"
            render={(value, record, index) => <CustomerColumn customerId={value}/>}
            title="Kunde"
            width={220}
        />

        <Table.Column
            align="center"
            dataIndex="manager"
            render={(v, record, index) => <>
                <User userId={v} TooltipTitle={
                    ({value}) => <>{value && `${value.username} (${value.email})`}</>
                }/>
                <br />
                <EntityLoader entityId={record.team}>
                    <TeamColumn/>
                </EntityLoader>
            </>}
            title="Projektmanagement"
            width={220}
        />

        <Table.Column
            dataIndex="asanaId"
            render={(value) => <AsanaColumn asanaId={value} loading={loading}/>}
            title="Asana-Tasks"
        />

        <Table.Column
            dataIndex="endAt"
            render={(value, record, index) => (
                <Typography.Text>{!!value && moment(value).format('L')}</Typography.Text>
            )}
            title="Deadline"
        />

        <Table.Column
            dataIndex="budget.time"
            render={(value, record, index) => {

                const duration = moment.duration(record.budget.time * 1000);
                return <table>
                    <tbody>
                        <tr>
                            <th><span role="img" aria-label="Clock">⏱</span></th>
                            <td align="right">{Math.floor(duration.asHours()) + ':' + String(duration.minutes()).padStart(2, '0')}</td>
                        </tr>
                        <tr>
                            <th><span role="img" aria-label="Euro">💶</span></th>
                            <td align="right">{Number(record.budget.monetary).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            }}
            title="Budget"
        />

        <Table.Column
            dataIndex="payoffs"
            render={(value) => <AbrechnungColumn items={value}/>}
            title="Abrechnung"
        />

        <Table.Column
            dataIndex="state"
            render={(value, record, index) => {
                return projectStates[value].text;
            }}
            title="Status"
        />

        <Table.Column
            render={(value, record, index) => <ActionColumn projectId={record['@id']}/>}
        />
    </Table>
</Panel>);

export default () => <TableContainer resourceUrl="/projects?groups[]=Customer:read&groups[]=Project:read&groups[]=ProjectPayoff:read&groups[]=UserTeam:read">
    <ListView/>
</TableContainer>
