import React from "react";
import {Button, Col, DatePicker, Form, Input, Row, Select} from "antd";
import RemoteSelect from "../../../common/form/RemoteSelect";
import {states as projectStates} from "../../../../utils/directory/projectData";
import {CaretLeftOutlined, CaretRightOutlined, FilterOutlined} from "@ant-design/icons";

export default ({filterParams, setFilterParams}) => {
  const [form] = Form.useForm();

  const handleSubmit = values => {
    setFilterParams({
      ...filterParams,
      ...values,
      startedAt: {
        after: values.startedAt.after.hours(0).minutes(0).seconds(0).clone(),
        before: values.startedAt.after.clone().add(1, 'day').subtract(1, 'second')
      }
    });
  };

  return (
    <Form layout="horizontal" onFinish={handleSubmit} form={form} initialValues={{...filterParams}}>
      <Row gutter={10} type="flex">
        <Col>
          <Form.Item name={'executor.assignedTeams'} rules={[{required: true, message: 'Dieser Wert sollte nicht leer sein.'}]}>
            <RemoteSelect allowClear placeholder="Team" resourceUrl="/users/teams" queryParam="name" style={{minWidth: 200}}/>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={'job.project.state'}>
            <Select allowClear placeholder="Status" mode="multiple">
              {Object.keys(projectStates).map((value) => (
                <Select.Option key={value} value={value}>{projectStates[value].text}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Input.Group compact>
            <Button icon={<CaretLeftOutlined />} onClick={(event) => {
              form.setFieldsValue({
                startedAt: {
                  after: form.getFieldsValue().startedAt.after.clone().subtract(1, 'd')
                }
              });

              form.submit();
            }}/>
            <Form.Item name={['startedAt', 'after']} rules={[{required: true, message: 'Dieser Wert sollte nicht leer sein.'}]} valuePropName="value">
              <DatePicker allowClear={false} placeholder="Tag"/>
            </Form.Item>
            <Button icon={<CaretRightOutlined />} onClick={(event) => {
              form.setFieldsValue({
                startedAt: {
                  after: form.getFieldsValue().startedAt.after.clone().add(1, 'd')
                }
              });

              form.submit();
            }}/>
          </Input.Group>
        </Col>
        <Col>
          <Form.Item>
            <Button icon={<FilterOutlined />} type="primary" htmlType="submit" style={{width: "100%"}}>Filtern</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
