import React from "react";
import Panel from "../../panel/Panel";
import Form from "./forms/New";
import { TeamOutlined } from '@ant-design/icons';

export default () => <Panel
    backgroundColor="#ef7e06"
    id="teams::new"
    title={<span><TeamOutlined /> Team anlegen</span>}
>
    <Form/>
</Panel>
