import React, {useState} from "react";
import {connect} from "react-redux";
import Panel from "../../panel/Panel";
import Form from "./forms/Edit";
import {removeAction as removePanelAction} from "../../../actions/panel";
import { RobotOutlined } from '@ant-design/icons';
import { Button, Divider, Result, Spin } from "antd";
import ServiceList from "./service/List";
import EntityLoader from "../../common/EntityLoader";

const Title = ({entity}) => <span>
    <RobotOutlined /> {!!entity ? entity.name : '404 - Not Found'}
</span>;

const Content = connect(
    null,
    dispatch => ({
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({entity, entityId, onRemovePanel}) => <>
    {entity
        ? <>
            <Form providerId={entityId}/>
            <Divider/>
            <ServiceList providerId={entityId}/>
        </>
        : <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={() => onRemovePanel(`cookieconsentproviders:${entityId}:edit`)}>close</Button>}
        />
    }
</>);

export default ({providerId}) => {
    const [loading, setLoading] = useState(true);

    return <Panel
        backgroundColor="#660e7a"
        id={`cookieconsentproviders:${providerId}:edit`}
        title={
            <EntityLoader entityId={providerId}>
                <Title/>
            </EntityLoader>
        }
    >
        <Spin spinning={loading}>
            <EntityLoader entityId={providerId} onFinally={() => setLoading(false)}>
                <Content/>
            </EntityLoader>
        </Spin>
    </Panel>
};
