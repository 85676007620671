import React from "react";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Tooltip } from "antd";
import NamedUrlInput from "./NamedUrlInput";

class NamedUrlCollection extends React.Component {
    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return {
                value: {...(nextProps.value || [])},
            };
        }

        return null;
    }

    constructor(props) {
        super(props);

        const value = props.value || [];
        this.state = {
            value: value,
        };
    }

    toArray(obj) {
        return Object.keys(obj).map((key, index) => ( obj[key] ));
    }

    addItem = () => {
        const value = this.toArray(this.state.value).concat({
            title: String(Object.keys(this.state.value).length + 1)
        });

        if (!('value' in this.props)) {
            this.setState({value});
        }

        this.triggerChange(value);
    };

    removeItem = (key) => {
        let {value} = {...this.state};
        delete value[key];

        if (!('value' in this.props)) {
            this.setState({value});
        }

        this.triggerChange(this.toArray(value));
    };

    onChange = (val, key) => {
        let {value} = {...this.state};
        value[key] = val;

        if (!('value' in this.props)) {
            this.setState({value});
        }

        this.triggerChange(this.toArray(value));
    };

    triggerChange = changedValue => {
        const {onChange} = this.props;

        if (onChange) {
            onChange(changedValue);
        }
    };

    render() {
        const {value} = this.state;

        return <>
            {Object.keys(value).map(key => (
                <Form.Item key={key} style={{paddingBottom: 0}}>
                    <NamedUrlInput
                        index={key}
                        value={value[key]}
                        onChange={this.onChange.bind(this)}
                        addonAfter={<Tooltip title={`${this.props.addButtonText} entfernen`}><Button icon={<DeleteOutlined />} type="link" size="small" onClick={() => { this.removeItem(key); }} /></Tooltip>}
                    />
                </Form.Item>
            ))}

            <Tooltip title={`${this.props.addButtonText} hinzufügen`}>
                <Button type="link" icon={<PlusOutlined />} size="small" onClick={this.addItem}/>
            </Tooltip>
        </>;
    }
}

export default NamedUrlCollection;
