import React from "react";
import Panel from "../panel/Panel";
import { ProjectOutlined } from '@ant-design/icons';

export default () => <Panel
    backgroundColor="#3ed115"
    id="projects::new"
    title={<span><ProjectOutlined /> Projekt erstellen</span>}
>
    {/*<Form/>*/} new
</Panel>
