import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import isObject from "lodash/isObject";
import has from "lodash/has";
import get from "lodash/get";

export default class HydraNormalizer {
  #_loader = null;
  #_mapping = {};
  #_loaded = false;
  #_dispatcher;

  constructor(dispatcher) {
    this.#_dispatcher = dispatcher;
  }

  prefetch(callback) {
    if (this.#_loader === null) {
      this.#_loader = parseHydraDocumentation(process.env.REACT_APP_QMAI_API_ENTRYPOINT)
        .then(({api}) => {
          api.resources.forEach(resource => {
            this.#_mapping[resource.title] = resource.readableFields
              .filter(v => isObject(v.reference))
              .map(v => v.name);
          })
        })
        .then(() => {
          this.#_loaded = true;
        });
    }

    if (!this.#_loaded) {
      return this.#_loader.then(() => callback());
    }

    return callback();
  }

  normalize(data) {
    if (has(data, 'hydra:member')) {
      // Normalize items in collections
      data['hydra:member'] = data['hydra:member'].map(item => this.normalize(item));

      return data;
    }

    const type = get(data, '@type', null);

    if (type !== null) {
      this.#_mapping[type].forEach(fieldName => {
        let value = data[fieldName]

        if (Array.isArray(data[fieldName])) {
          data[fieldName] = value.map(v => this.normalize(v));
        } else if (isObject(value)) {
          let v = this.normalize(value)
          this.#_dispatcher.add([v]);

          data[fieldName] = get(v, '@id');
        }
      })
    }

    return data;
  }
}
