import React, {useEffect} from "react";
import {connect} from 'react-redux';
import { EditOutlined, InsuranceOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table, Typography } from "antd";
import {getEntitiesById} from '../../../selectors/entity';
import {addAction as addPanelAction} from "../../../actions/panel";
import Panel from "../../panel/Panel";
import SearchFilter from "../../common/table/filter/Search";
import {
    PANEL_COOKIECONSENT_SITE_EDIT,
    PANEL_COOKIECONSENT_SITE_NEW, PANEL_CUSTOMER_SHOW
} from "../../../factories/panel";
import TableContainer from "../../common/table/Container";
import {EventEmitter} from "../../../events/EventEmitter";
import {COOKIECONSENT__SITES_CREATE} from "../../../events/EventTypes";
import EntityLoader from "../../common/EntityLoader";

const CustomerColumn = connect(
    null,
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({entity, entityId, onAddPanel}) => <>
    {entity && <Button
        onClick={() => onAddPanel(
            {
                type: PANEL_CUSTOMER_SHOW,
                uid: `customers:${entityId}:show`,
                config: {
                    customerId: entityId
                }
            }
        )}
        style={{
            display: 'inline',
            height: 'initial',
            padding: '0',
            textAlign: 'inherit',
            whiteSpace: 'normal'
        }}
        type="link"
    >{entity.name}</Button>}
</>);

const ListView = connect(
    (state, {itemIds}) => ({
        items: getEntitiesById(state, itemIds),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({filters, items, loading, onAddPanel, onChange, onSearch, pagination}) => {
    useEffect(() => {
        const createHandler = data => onChange(pagination, filters);

        EventEmitter.subscribe(COOKIECONSENT__SITES_CREATE, createHandler);

        return () => {
            EventEmitter.unsubscribe(COOKIECONSENT__SITES_CREATE, createHandler);
        }
    }, []);

    return (
        <Panel
            backgroundColor="#882f9c"
            title={<span><InsuranceOutlined /> Site-Liste</span>}
            id="cookieconsentsites::list"
            width="auto"
        >
            <div style={{display: 'flex'}}>
                {/*<Input.Search
                    allowClear
                    size="large"
                    placeholder="Suche nach Anbietern"
                    onChange={e => onSearch(e.currentTarget.value)} onSearch={onSearch}
                />
                */}
                <Button size="large" style={{marginLeft: 'auto'}} type="primary" onClick={() => onAddPanel(
                    {
                        type: PANEL_COOKIECONSENT_SITE_NEW,
                        uid: `cookieconsentsites::new`,
                    },
                    'cookieconsentsites::list')}>
                    <PlusOutlined /> Site hinzufügen
                </Button>
            </div>

            <Table
                dataSource={items}
                loading={loading}
                onChange={onChange}
                pagination={pagination}
                rowKey={record => record['@id']}
                size="small"
                style={{marginTop: '16px'}}
            >
                <Table.Column
                    dataIndex="id"
                    filterDropdown={props => <SearchFilter {...props}/>}
                    render={value => <Typography.Text ellipsis copyable>{value}</Typography.Text>}
                    title="ID"
                    width={100}
                />

                <Table.Column
                    dataIndex="name"
                    defaultSortOrder="ascend"
                    filterDropdown={props => <SearchFilter {...props}/>}
                    title="Name"
                    sorter={!filters.q}
                />

                <Table.Column
                    dataIndex="customerId"
                    title="Kunde"
                    render={(value, record, index) => <EntityLoader entityId={`/customers/${value}`}>
                        <CustomerColumn/>
                    </EntityLoader>}
                    width={220}
                />

                <Table.Column
                    render={(value, record, index) => (
                        <Button icon={<EditOutlined />} shape="circle" size="small" type="primary" onClick={() => onAddPanel({
                                type: PANEL_COOKIECONSENT_SITE_EDIT,
                                uid: `cookieconsentsites:${record['@id']}:edit`,
                                config: {
                                    siteId: record['@id']

                                }},
                            'cookieconsentsites::list')}
                        />
                    )}
                    title="Aktionen"
                />
            </Table>
        </Panel>
    );
});

export default () => <TableContainer resourceUrl="cookieconsent/sites" initialSorters={{order: 'ascend', field: 'name'}}>
    <ListView/>
</TableContainer>
