import React, {useEffect} from "react";
import {connect} from "react-redux";
import {getEntityById} from "../../selectors/entity";
import {fetch} from "../../api/rest";
import {isArray} from "lodash";

export default connect(
    (state, {entityId}) => ({
        entity: getEntityById(state, entityId),
    })
)(({entity, entityId, children, onFinally = () => {}}) => {
    useEffect(() => {
        if (!entity) {
            const controller = new AbortController();
            const {signal} = controller;

            fetch(entityId, {signal})
                .catch(err => {})
                .finally(onFinally);

            return controller.abort.bind(controller);
        } else {
            onFinally();
        }
    }, [entity]);

    return <>
        {isArray(children)
            ? children.map(v => React.cloneElement(v, {entity, entityId}))
            : React.cloneElement(children, {entity, entityId})
        }
    </>
});
