export const submissionErrorToFormFields = (submissionError, form) => {
    const fieldMap = {};

    for (let field in submissionError.errors) {
        let path = field.replace(/]/g, '').split('[');

        if (!fieldMap.hasOwnProperty(field)) {
            fieldMap[field] = {
                name: path,
                errors: [],
                value:  form.getFieldValue(path)
            }
        }

        fieldMap[field].errors.push(submissionError.errors[field]);
    }

    return Object.keys(fieldMap).map(key => fieldMap[key]);
}
