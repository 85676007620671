import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { SaveOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Input as AntdInput,
    Result,
    Spin,
    Typography,
    Form
} from "antd";
import {fetch} from "../../../api/rest";
import {getEntityById} from "../../../selectors/entity";
import {SubmissionError} from "redux-form";
import TextArea from "antd/es/input/TextArea";
import RemoteSelect from "../../common/form/RemoteSelect";
import TokenStorage from "../../../security/authentication/token/storage/TokenStorage";
import {submissionErrorToFormFields} from "../../../utils/submissionErrorToFormFields";

class Input extends React.Component {
    render() {
        return <AntdInput autoComplete="none" {...this.props}/>;
    }
}

export default (connect(
    (state, {jobId, taskId}) => ({
        job: getEntityById(state, jobId),
        task: getEntityById(state, taskId),
        me: getEntityById(state, `/users/${TokenStorage.getToken().user.id}`),
    })
)(({job, jobId, task, taskId}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleSubmit = values => {

        setLoading(true);

        return fetch(jobId, {method: 'PUT', body: JSON.stringify(values)})
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        const jobValues = {...job};

        if (null === jobValues.executor) jobValues.executor = `/users/${TokenStorage.getToken().user.id}`;

        if (job) form.setFieldsValue(jobValues);
    }, [job]);

    useEffect(() => {
        if (jobId && !job) {
            setLoading(true);
            fetch(jobId)
                .then(json => {

                    if (null === json.executor) json.executor = `/users/${TokenStorage.getToken().user.id}`;

                    form.setFieldsValue(json);
                })
                .catch(e => {})
                .finally(() => {
                setLoading(false)
            });
        }
    }, [jobId]);

    return (
        <Spin spinning={loading} size="large">
            {job && <Form layout="vertical" onFinish={handleSubmit} form={form}>
                <Typography.Title level={4} type="secondary">Basisdaten</Typography.Title>
                <Form.Item name={'name'}>
                    <Input placeholder="Name"/>
                </Form.Item>
                <Form.Item name={'description'}>
                    <TextArea autoSize={{maxRows:10}} placeholder="Beschreibung"/>
                </Form.Item>
                <Form.Item name={'executor'}>
                    <RemoteSelect allowClear placeholder="Benutzer" resourceUrl="users" textParam="email"/>
                </Form.Item>
                <Form.Item name={'project'}>
                    <RemoteSelect allowClear placeholder="Project" resourceUrl="projects"/>
                </Form.Item>

                <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">Speichern</Button>
            </Form>}

            {!job && <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
            />}
        </Spin>
    );
}));
