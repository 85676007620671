import React, {useState} from "react";
import {connect} from "react-redux";
import { PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Input as AntdInput,
    Menu,
    Radio,
    Row,
    Select,
    Spin,
    Tabs,
    Tooltip,
    Typography,
    Form
} from "antd";
import {fetch} from "../../../../../api/rest";
import {SubmissionError} from "redux-form";
import {addAction as addPanelAction, removeAction as removePanelAction} from "../../../../../actions/panel";
import {
    PANEL_COOKIECONSENT_PROVIDER_EDIT,
} from "../../../../../factories/panel";
import {translationLanguages} from "../../../directory/translationLanguages";
import EntityLoader from "../../../../common/EntityLoader";
import {submissionErrorToFormFields} from "../../../../../utils/submissionErrorToFormFields";

class Input extends React.Component {
    render() {
        return <AntdInput autoComplete="none" {...this.props}/>;
    }
}

const ProviderTitle = ({entity}) => <small>{entity && entity.name}</small>;

export default (connect(
    null,
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({onAddPanel, onRemovePanel, providerId}) => {
    const [loading, setLoading] = useState(false);
    const [translations, setTranslations] = useState({});
    const [activeTranslation, setActiveTranslation] = useState();
    const [form] = Form.useForm();

    const handleSubmit = values => {

        values.provider = providerId;

        if (!!values.cookieLifetime && values.cookieLifetime.length === 0) {
            values.cookieLifetime = null; //make it nullable
        }

        values.translations = values.translations || {};
        Object.keys(values.translations).forEach((key) => {
            ['cookieLifetime', 'name', 'policyUrl', 'purpose'].forEach(prop => {
                if (String(values.translations[key][prop]).length === 0) {
                    values.translations[key][prop] = null;
                }
            });
        });

        setLoading(true);

        return fetch('/cookieconsent/providers/services', {method: 'POST', body: JSON.stringify(values)})
            .then(data => {
                setLoading(false);
                onAddPanel(
                    {
                        type: PANEL_COOKIECONSENT_PROVIDER_EDIT,
                        uid: `cookieconsentproviders:${providerId}:edit`,
                        config: {
                            providerId: providerId
                        }
                    },
                    `cookieconsentproviderservices:${providerId}:new`
                );
                onRemovePanel(`cookieconsentproviderservices:${providerId}:new`);
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => setLoading(false));
    };

    const onTranslationTabsEdit = (targetKey, action) => {
        const t = {...translations};

        switch (action) {
            default:
                break;

            case 'add':
                t[targetKey] = {};
                setTranslations(t);
                setActiveTranslation(targetKey);
                break;

            case 'remove':
                delete t[targetKey];
                setTranslations(t);
                break;
        }
    };

    return (
        <Spin spinning={loading} size="large">
            <Typography.Title level={3}>
                Neuer Dienst
                <br />
                <EntityLoader entityId={providerId}>
                    <ProviderTitle/>
                </EntityLoader>
            </Typography.Title>

            <Form layout="vertical" onFinish={handleSubmit} form={form}>

                <Typography.Title level={4} type="secondary">Basisdaten</Typography.Title>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Name" name={'name'} rules={[{type: 'string'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label="Kennung" extra="Dieser Wert muss eineindeutig sein." name={'identifier'} rules={[
                            {
                                pattern: /^[a-z0-9_-]*$/,
                                message: <span>Erlaubte Zeichen sind
                                        <Typography.Text> a-z</Typography.Text>,
                                        <Typography.Text> 0-9</Typography.Text>,
                                        <Typography.Text> -</Typography.Text> und
                                        <Typography.Text> _</Typography.Text>
                                        .</span>
                            }
                        ]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label="URL zu Vertragsbedingungen" name={'policyUrl'} rules={[{type: 'url'}]}>
                            <Input type="url"/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Zweck" name={'purpose'} rules={[{type: 'string'}]}>
                            <AntdInput.TextArea autoSize={{maxRows:10}}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider/>
                <Typography.Title level={4} type="secondary">
                    Übersetzungen
                    <Tooltip title="Übersetzung hinzufügen">
                        <Dropdown overlay={<Menu onClick={({key}) => onTranslationTabsEdit(key, 'add')}>
                            {Object.keys(translationLanguages).map(lang => <Menu.Item key={lang}>{translationLanguages[lang]}</Menu.Item>)}
                        </Menu>}>
                            <Button icon={<PlusCircleOutlined />} shape="circle" type="link"/>
                        </Dropdown>
                    </Tooltip>
                </Typography.Title>
                <Tabs
                    activeKey={activeTranslation}
                    animated
                    hideAdd
                    onChange={setActiveTranslation}
                    onEdit={onTranslationTabsEdit}
                    type="editable-card"
                >
                    {Object.keys(translations).map(key => (
                        <Tabs.TabPane key={key} tab={translationLanguages[key]}>
                            <Form.Item name={['translations', key, 'locale']} initialValue={key} rules={[{type: 'string'}]} noStyle>
                                <Input type="hidden"/>
                            </Form.Item>

                            <Row gutter={10} type="flex" justify="space-between">
                                <Col span={5}>
                                    <Form.Item label="Name" name={['translations', key, 'name']} rules={[{type: 'string'}]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item label="Lebensdauer" name={['translations', key, 'cookieLifetime']} rules={[{type: 'string'}]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="URL zu Vertragsbedingungen" name={['translations', key, 'policyUrl']} rules={[{type: 'url'}]}>
                                        <Input type="url"/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item label="Zweck" name={['translations', key, 'purpose']} rules={[{type: 'string'}]}>
                                <AntdInput.TextArea autoSize={{maxRows:10}}/>
                            </Form.Item>
                        </Tabs.TabPane>
                    ))}
                </Tabs>

                <Divider/>
                <Typography.Title level={4} type="secondary">Cookie-Werte</Typography.Title>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Typ" name={'cookieType'}>
                            <Radio.Group buttonStyle="solid">
                                    <Radio.Button value={null}>Ohne</Radio.Button>
                                    <Radio.Button value="session">Temporär</Radio.Button>
                                    <Radio.Button value="persistent">Permanent</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item label="Namen" name={'cookieNames'}>
                            <Select mode="tags" dropdownStyle={{display: 'none'}} tokenSeparators={[',']}/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Lebensdauer" name={'cookieLifetime'} rules={[{type: 'string'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label="Hosts" name={'cookieHosts'}>
                            <Select mode="tags" dropdownStyle={{display: 'none'}} tokenSeparators={[',']}/>
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{textAlign: 'right'}}>
                    <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">Speichern</Button>
                </div>
            </Form>

        </Spin>
    );
}));
