import React, {useState} from "react";
import {connect} from "react-redux";
import { InboxOutlined, SaveOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Input as AntdInput,
    Row,
    Select,
    Spin,
    Typography,
    Upload,
    Form
} from "antd";
import {fetch} from "../../../api/rest";
import {SubmissionError} from "redux-form";
import NamedUrlCollection from "../../common/form/NamedUrlCollection";
import {addAction as addPanelAction, removeAction as removePanelAction} from "../../../actions/panel";
import {PANEL_CUSTOMER_SHOW} from "../../../factories/panel";
import EntityLoader from "../../common/EntityLoader";
import {submissionErrorToFormFields} from "../../../utils/submissionErrorToFormFields";

class Input extends React.Component {
    render() {
        return <AntdInput autoComplete="none" {...this.props}/>;
    }
}

const CustomerTitle = ({entity}) => <small>{entity && entity.name}</small>;

export default (connect(
    null,
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({customerId, onAddPanel, onRemovePanel}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleSubmit = values => {

        values.onlineProfiles = values.onlineProfiles.filter(value => value.uri); // cleanup when empty uri
        values.customer = customerId;

        setLoading(true);

        return fetch('customers/contacts', {method: 'POST', body: JSON.stringify(values)})
            .then(data => {
                form.resetFields();
                onAddPanel(
                    {
                        type: PANEL_CUSTOMER_SHOW,
                        uid: `customers:${customerId}:show`,
                        config: {customerId}
                    },
                    `customercontacts:${customerId}:new`
                );
                onRemovePanel(`customercontacts:${customerId}:new`);
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
        .finally(() => setLoading(false));
    };

    return (
        <Spin spinning={loading} size="large">
            <Typography.Title level={3}>
                Neuer Kundenkontakt
                <br />
                <EntityLoader entityId={customerId}>
                    <CustomerTitle/>
                </EntityLoader>
            </Typography.Title>

            <Form layout="vertical" onFinish={handleSubmit} initialValues={{'onlineProfiles': [{title: 'Website'},{title: 'XING'}, {title: 'LinkedIn'}]}} form={form}>
                <Typography.Title level={4} type="secondary">Zentrale Kontaktinformationen</Typography.Title>

                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Titel" name={'title'}>
                            <Input placeholder="Titel"/>
                        </Form.Item>

                        <Row gutter={10}>
                            <Col span={8}>
                                <Form.Item label="Anrede" name={'salutation'}>
                                    <Select placeholder="Anrede" allowClear dropdownMatchSelectWidth={false}>
                                        <Select.Option value="Frau">Frau</Select.Option>
                                        <Select.Option value="Herr">Herr</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item label="Nachname" name={'lastname'}>
                                    <Input placeholder="Nachname"/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item label="Vorname" name={'firstname'}>
                            <Input placeholder="Vorname"/>
                        </Form.Item>

                        <Form.Item label="Position im Unternehmen" name={'position'}>
                            <Input placeholder="Position"/>
                        </Form.Item>

                        <Form.Item label="Geburtstag" name={'birthday'}>
                            <DatePicker format="L" style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item label="Telefonnummer" name={'phone'}>
                            <Input type="tel" placeholder="+XX XXX XXXXXXX" pattern="^\+?[0-9\s]+\d$"/>
                        </Form.Item>

                        <Form.Item label="Mobilnummer" name={'mobile'}>
                            <Input type="tel" placeholder="XXXX XXXXXXX" pattern="^\+?[0-9\s]+\d$"/>
                        </Form.Item>

                        <Form.Item label="Faxnummer" name={'fax'}>
                            <Input type="tel" placeholder="+XX XXX XXXXXXX" pattern="^\+?[0-9\s]+\d$"/>
                        </Form.Item>

                        <Form.Item label="E-Mail-Adresse" name={'email'}>
                            <Input type="email" placeholder="E-Mail-Adresse"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider style={{marginBottom: 30, marginTop: 20}}/>

                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Typography.Title level={4} type="secondary">Profilbild</Typography.Title>
                        <Upload.Dragger beforeUpload={(file, fileList) => false}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text"><small>Klicken oder eine Datei in diesen Bereich ziehen, um sie hochzuladen</small></p>
                            <p className="ant-upload-hint">…noch nicht implementiert…</p>
                        </Upload.Dragger>
                    </Col>

                    <Col span={11}>
                        <Typography.Title level={4} type="secondary">Online-Profile</Typography.Title>
                        <Form.Item name={'onlineProfiles'}>
                            <NamedUrlCollection addButtonText="Online-Profil"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item style={{textAlign: 'right'}}>
                    <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">Speichern</Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}));
