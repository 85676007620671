import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Button, Progress, Select} from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import {fetch} from "../../../../api/rest";
import {CSVLink} from "react-csv";
import {stringify} from "qs";
import {getEntityById} from "../../../../selectors/entity";

const DownloadDropdown = connect(
  (state, {siteId}) => ({
    site: getEntityById(state, siteId),
    state: state,
  })
)(({state, site, siteId}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState(null);
  const [progress, setProgress] = useState(100);

  const load = (page = 1, results = []) => {
    const queryParams = {
      page: page,
      site: siteId,
      aggregate: period,
      range: {
        start: site.createdAt
      },
      groups: [
        'CookieConsentSiteUsageService:read',
        'CookieConsentSiteService:read'
      ]
    }

    fetch(`/cookieconsent/sites/usages/servicetrackings/reports?${stringify(queryParams)}`)
      .then(json => {
        results = results.concat(json['hydra:member']);

        setProgress(Math.round(100 / json['hydra:totalItems'] * results.length));

        if (typeof json['hydra:view']['hydra:next'] !== 'undefined') {
          load(page+1, results)
        } else {

          setData(results.map((value) => {
            value.serviceObject = getEntityById(state, value.service);

            return value;
          }));

          setTimeout(() => setLoading(false), 400);
        }
      });
  }

  useEffect(() => {
    if (period !== null) {
      setLoading(true);
      setData([]);
      setProgress(0);
      load();
    }
  }, [period])

  return <Button.Group style={{alignItems:"center"}}>
    <div>Statistiken&nbsp;</div>
    <Select
      disabled={loading}
      dropdownMatchSelectWidth={false}
      onChange={setPeriod}
      placeholder="auswählen"
      style={{minWidth:100}}
    >
      <Select.Option value="monthly">monatlich</Select.Option>
      <Select.Option value="weekly">wöchentlich</Select.Option>
      <Select.Option value="daily">täglich</Select.Option>
    </Select>
    <div>&nbsp;als CSV&nbsp;</div>
    <Button
      disabled={data.length === 0 || loading === true}
      loading={loading}
      style={(data.length !== 0 && loading === false) ? { background: "#52c41a", borderColor: "#52c41a" } : {}}
      type="primary"
    >
      <CSVLink
        data={data}
        filename={`${site.name} - ${period} - ${(new Date()).toLocaleString()}.csv`}
        headers={[
          {label: 'service', key: 'service.name'},
          {label: 'year', key: 'year'},
          {label: 'period', key: 'period'},
          {label: 'amount', key: 'amount'},
          {label: 'direction', key: 'direction'},
        ]}
      >
        {loading
          ? <Progress percent={progress} showInfo={true} style={{width: 100}} trailColor="#ccc"/>
          : <>
            <DownloadOutlined/> herunterladen
          </>
        }
      </CSVLink>
    </Button>
  </Button.Group>
});

export default ({siteId}) => <DownloadDropdown siteId={siteId}/>;
