import User from "../../user/User";

export default class Token {
    #_rawToken;
    #_roles = [];
    #_user;

    constructor(user, roles, rawToken) {
        this.#_user = user;
        this.#_roles = roles;
        this.#_rawToken = rawToken;
    }

    toString() {
        return JSON.stringify({
            rawToken: this.rawToken,
            roles: this.roles,
            user: this.user instanceof User ? this.user.toArray() : this.user,
        });
    }

    get user() {
        return this.#_user;
    }

    set user(user) {
        this.#_user = user;
    }

    get roles() {
        return this.#_roles;
    }

    set roles(roles) {
        this.#_roles = roles;
    }

    get rawToken() {
        return this.#_rawToken;
    }

    set rawToken(rawToken) {
        this.#_rawToken = rawToken;
    }
}
