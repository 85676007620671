import User from "./User";

export default class AppUser extends User {
    #_email;
    #_id;

    get email() {
        return this.#_email;
    }

    set email(value) {
        this.#_email = value;
    }

    get id() {
        return this.#_id;
    }

    set id(value) {
        this.#_id = value;
    }

    toArray() {
        return {
            ...super.toArray(),
            email: this.email,
            id: this.id,
        };
    }
}
