export const formatDuration = (totalSeconds, format = '%hh:%mm:%ss') => {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds - hours * 3600 - minutes * 60

  return format
    .replace(/%hh/, hours < 10 ? `0${hours}` : hours)
    .replace(/%mm/, minutes < 10 ? `0${minutes}` : minutes)
    .replace(/%ss/, seconds < 10 ? `0${seconds}` : seconds)
    .replace(/%h/, hours)
    .replace(/%m/, minutes)
    .replace(/%s/, seconds);
}
