import React from "react";
import {connect} from "react-redux";
import {Mentions} from "antd";
import {getEntitiesByType} from "../../../selectors/entity";

export default connect(
    (state) => ({
        users: getEntitiesByType(state, 'User', ['email']),
    }),
)((props) => <Mentions
    filterOption={(input, option) => option.email.toLowerCase().indexOf(input.toLowerCase()) === 0}
    placeholder="Schreib etwas Nettes… 😉"
    rows={5}
    {...props}
>
    {props.users.map(user =>
        <Mentions.Option key={user['@id']} value={user['@id']} email={user.email}>
            <img
                alt={user.email}
                height={24}
                src={`//loremflickr.com/128/128/kitten?lock=${user.id}`}
                width={24}
                style={{borderRadius: '50%', marginRight: '.25em'}}
            />
            <span>{user.email}</span>
        </Mentions.Option>
    )}
</Mentions>);
