import React from "react";
import {addAction} from "../actions/panel";
import {Menu} from "antd";
import store from "../store";

import {
    PANEL_CUSTOMER_SHOW
} from "./panel";
import CustomerShowPanel from "../components/menu/historyitem/CustomerShowPanel";

export default function create(payload) {
    switch (payload.type) {
        case PANEL_CUSTOMER_SHOW :
            return <Menu.Item key={payload.uid} onClick={() => store.dispatch(addAction(payload))}>
                <CustomerShowPanel customerId={payload.config.customerId}/>
            </Menu.Item>;

        default :
            break;
    }
};
