import React from "react";
import {connect} from "react-redux";
import {getEntityById} from "../../../../selectors/entity";
import { UserOutlined } from '@ant-design/icons';
import {Avatar, Tooltip} from "antd";

export default connect(
    (state, {userId}) => ({
        user: getEntityById(state, userId),
    })
)(({user, TooltipTitle = ({value}) => {
    return <>{value && value.email}</>
}}) => <>
        {user && <Tooltip title={<TooltipTitle value={user}/>}>
            <Avatar icon={<UserOutlined />} src={`//loremflickr.com/64/64/kitten?lock=${user.id}`} style={{boxShadow: '0 0 2px black', margin: '.125em'}}/>
        </Tooltip>}
    </>
);
