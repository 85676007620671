import React, {useState} from "react";
import {connect} from 'react-redux';
import { ClockCircleOutlined, FilterOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Drawer, Row, Select, Switch, Table, Typography, Form } from "antd";
import moment from 'moment';
import {getEntitiesById} from '../../../selectors/entity';
import TableContainer from "../../common/table/Container";
import EntityLoader from "../../common/EntityLoader";
import User from "../../common/table/column/User";
import RemoteSelect from "../../common/form/RemoteSelect";
import {transform} from "lodash";
import TokenStorage from "../../../security/authentication/token/storage/TokenStorage";
import JobForm from "./JobForm";

const InternalUser = ({entity, entityId}) => <>{entity && <User userId={entityId} TooltipTitle={
    ({value}) => <>{value && `${value.username} (${value.email})`}</>
}/>}</>;

const AsanaBridgeUser = ({entity}) => <>{entity && <EntityLoader entityId={entity.internalUser}>
    <InternalUser/>
</EntityLoader>}</>;

const FilterForm = (({onFilter, style}) => {
    const [form] = Form.useForm();
    const handleSubmit = values => {

        values = transform(values, (result, value, key) => {
            const newKey = String(key).split(',').join('.');
            result[newKey] = value;
            return result;
        });

        if (values.currentUser) {
            values['assignedUser.internalUser'] = `/users/${TokenStorage.getToken().user.id}`;
        }

        return onFilter(values);
    };

    return (
        <Form layout="horizontal" onFinish={handleSubmit} style={style} initialValues={{'currentUser': true}} form={form}>
            <Row gutter={10} type="flex">
                <Col span={18}>
                    <Row gutter={10} type="flex">
                        <Col span={8}>
                            <Form.Item name={['projects', 'internalProject', 'customer']}>
                                <RemoteSelect allowClear placeholder="Kunde" resourceUrl="customers"/>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name={['projects', 'internalProject']}>
                                <RemoteSelect allowClear placeholder="Projekt" resourceUrl="projects"/>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name={['assignedUser', 'internalUser']}>
                                <RemoteSelect allowClear placeholder="Benutzer" resourceUrl="users" textParam="email"/>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item extra="nur meine Tasks" name={'currentUser'} valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item extra="in Asana abgeschlossen" name={'completed'}>
                                <Select allowClear>
                                    <Select.Option value="true">Ja</Select.Option>
                                    <Select.Option value="false">Nein</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item extra="in Zentrale abgeschlossen" name={['internalJob', 'done']}>
                                <Select allowClear>
                                    <Select.Option value="true">Ja</Select.Option>
                                    <Select.Option value="false">Nein</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col style={{marginLeft: 'auto'}}>
                    <Form.Item>
                        <Button icon={<FilterOutlined />} type="primary" htmlType="submit" style={{width: "100%"}}>Filtern</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

const ListView = connect(
    (state, {itemIds}) => ({
        items: getEntitiesById(state, itemIds),
    })
)(({items, loading, onChange, onFilter, pagination}) => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [currentTask, setCurrentTask] = useState();

    return <>
        <Drawer
            headerStyle={{backgroundColor: "#5A6650"}}
            getContainer={false}
            width="50%"
            onClose={() => { setDrawerVisible(false) }}
            placement="right"
            title={currentTask && <span style={{color: "white"}}><ClockCircleOutlined /> {currentTask.name}</span>}
            visible={drawerVisible}
        >
            <JobForm jobId={currentTask && currentTask.internalJob} taskId={currentTask && currentTask['@id']}/>
        </Drawer>
        <FilterForm onFilter={onFilter} style={{flexGrow: 1}}/>
        <Table
            dataSource={items}
            loading={loading}
            onChange={onChange}
            pagination={pagination}
            rowKey={record => record['@id']}
            size="small"
            style={{marginTop: '16px'}}
        >
            <Table.Column
                dataIndex="id"
                render={(value) => <a href={`//app.asana.com/0/0/${value}`} target="_blank" rel="noreferrer noopener" alt="Asana-Link">{value}</a>}
                title="Asana-ID"
            />

            <Table.Column
                dataIndex="name"
                title="Name"
                width={220}
            />

            <Table.Column
                dataIndex="createdAt"
                render={(value, record, index) => (
                    <Typography.Text>{!!value ? moment(value).format('L') : ''}</Typography.Text>
                )}
                sorter={true}
                title="Erstellt"
            />

            <Table.Column
                dataIndex="assignedUser"
                render={(v) => <>{v && <EntityLoader entityId={v}>
                    <AsanaBridgeUser/>
                </EntityLoader>}</>}
                title="Zugewiesen"
            />

            <Table.Column
                dataIndex="completedAt"
                render={(value, record, index) => (
                    <Typography.Text>{!!value ? moment(value).format('L') : ''}</Typography.Text>
                )}
                sorter={true}
                title="Fertiggestellt"
            />

            <Table.Column
                render={(value, record, index) => (
                    <Button type="link" icon={<ClockCircleOutlined />} onClick={() => {
                        setCurrentTask(record);
                        setDrawerVisible(true)
                    }}/>
                )}
                title="Aktionen"
            >
            </Table.Column>
        </Table>
    </>;
});

export default () => <TableContainer resourceUrl="asanabridge/tasks" initialFilters={{'assignedUser.internalUser': `/users/${TokenStorage.getToken().user.id}`}}>
    <ListView/>
</TableContainer>
