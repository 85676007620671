export const states = {
    akquise: {
        text: 'Akquise'
    },
    open: {
        text: 'Umsetzung'
    },
    requested: {
        text: 'Anforderung'
    },
    iced: {
        text: 'eingefroren'
    },
    closed_nocharge: {
        text: 'fertig, nicht abrechenbar'
    },
    closed: {
        text: 'fertig, wartet auf Rechnungsstellung'
    },
    charged: {
        text: 'fertig, Rechnung gestellt'
    },
    korb: {
        text: 'Akquise abgelehnt'
    }
};
