import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from "redux-persist/lib/storage";
import entityReducer from './entity';
import panelHistoryReducer from './panelHistory';
import panelReducer from './panel';

const rootReducer = combineReducers({
    entityCollectionState: entityReducer,
    panelHistoryState: panelHistoryReducer,
    panelState: panelReducer,
});

export default persistReducer({
    key: 'root1',
    storage: storage,
    version: 1,
    whitelist: ['panelHistoryState', 'panelState']
}, rootReducer);
