import React from "react";
import {connect} from 'react-redux';
import {getEntitiesById} from "../../../../selectors/entity";
import {addAction as addPanelAction} from "../../../../actions/panel";
import {Table, Typography} from "antd";
import TableContainer from "../../../common/table/Container";
import EntityLoader from "../../../common/EntityLoader";
import Statistics from "./Statistics";

const ServiceGroupColumn = ({entity, entityId}) => entity ? entity.name : '';

const ListView = connect(
    (state, {itemIds}) => ({
        items: getEntitiesById(state, itemIds),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({filters, items, loading, onAddPanel, onChange, pagination, siteId}) =>
    <>
        <Typography.Title level={4} type="secondary">Dienste</Typography.Title>

        <Table
            dataSource={items}
            footer={() => (!loading && <Statistics siteId={siteId}/>)}
            loading={loading}
            onChange={onChange}
            pagination={pagination}
            rowKey={record => record['@id']}
            size="small"
            style={{marginTop: '16px'}}
        >
            <Table.Column
                dataIndex="id"
                title="#"
                render={(value, record, index) => index + 1 + (pagination.current - 1) * pagination.pageSize}
            />

            <Table.Column
                dataIndex="name"
                title="Name"
                width="100%"
            />

            <Table.Column
                dataIndex="identifier"
                title="Schlüssel"
            />

            <Table.Column
                dataIndex="group"
                title="Gruppe"
                render={(value, record, index) => <EntityLoader entityId={value}>
                    <ServiceGroupColumn/>
                </EntityLoader>}
            />
        </Table>
    </>
);

const LoaderWrapper = ({entity, entityId}) => <TableContainer resourceUrl={`${entityId}/services`} reloadDeps={[entity]}>
    <ListView siteId={entityId}/>
</TableContainer>;

export default ({siteId}) => <EntityLoader entityId={siteId}>
    <LoaderWrapper/>
</EntityLoader>
