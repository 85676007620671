import {filter, sortBy} from "lodash";

const getEntitiesById = ({entityCollectionState}, itemIds) => {
    const items = [];

    itemIds.forEach(itemId => {
        const item = getEntityById({entityCollectionState}, itemId);
        if (!!item) {
            items.push(item);
        }
    });

    return items;
};

const getEntitiesByType = ({entityCollectionState}, type, sort = ['@id']) => sortBy(filter(entityCollectionState, {'@type': type}), sort);

const getEntityById = ({entityCollectionState}, itemId) => entityCollectionState[itemId];

export {
    getEntitiesById,
    getEntitiesByType,
    getEntityById,
}
