const INITIAL_STATE = {
    items: {},
    positions: [],
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'APP_PANEL_ADD' : {
            const {items, positions} = {...state};
            const {component} = action.payload;
            const {uid} = component;

            items[uid] = component;

            const position = positions.indexOf(uid);
            if (position !== -1) positions.splice(position, 1);

            return {items, positions}
        }

        case 'APP_PANEL_REMOVE' : {
            const {items, positions} = {...state};
            const uid = action.payload;

            positions.unshift(uid);

            return {items, positions}
        }

        case 'APP_HISTORY_PANEL_REMOVE' : {
            const {items, positions} = {...state};
            const uid = action.payload;

            const position = positions.indexOf(uid);
            if (position !== -1) positions.splice(position, 1);

            return {items, positions}
        }

        default :
            return state;
    }
}

export default reducer;
