import React from "react";
import Panel from "../panel/Panel";
import Form from "./forms/New";
import { UserOutlined } from '@ant-design/icons';

export default () => <Panel
    backgroundColor="#ef7e06"
    id="users::new"
    title={<span><UserOutlined /> Benutzer anlegen</span>}
>
    <Form/>
</Panel>
