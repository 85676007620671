import GoogleProfile from "./GoogleProfile";

export default class Profiles {
    #_google;

    get google() {
        return this.#_google;
    }

    set google(value) {
        this.#_google = value;
    }

    toArray() {
        return {
            google: this.google instanceof GoogleProfile ? this.google.toArray() : this.google,
        };
    }
}
