export class EventEmitter {
    static #events = {};

    static dispatch = (event) => {
        if (!this.#events[event.type]) return;
        this.#events[event.type].forEach(callback => callback(event.detail));
    };

    static subscribe = (eventType, eventHandler) => {
        if (!this.#events[eventType]) this.#events[eventType] = [];
        this.#events[eventType].push(eventHandler);
    };

    static unsubscribe = (eventType, eventHandler) => {
        if (!this.#events[eventType]) return;
        this.#events[eventType] = this.#events[eventType].filter(callback => callback !== eventHandler);
    }
}
