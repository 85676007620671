import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { CloseOutlined, DeleteOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Dropdown,
    Input as AntdInput,
    Menu,
    notification,
    Popconfirm,
    Row,
    Select,
    Spin,
    Tabs,
    Tooltip,
    Typography,
    Form,
    Radio
} from "antd";
import {
    removeAction as removePanelAction
} from "../../../../actions/panel";
import {fetch} from "../../../../api/rest";
import {SubmissionError} from "redux-form";
import RemoteSelect from "../../../common/form/RemoteSelect";
import {themeSettings as availableThemeSettings} from "../../directory/themeSettings";
import {languageSettings as availableLanguageSettings} from "../../directory/languageSettings";
import {getEntityById} from "../../../../selectors/entity";
import {defer} from "lodash";
import {translationLanguages} from "../../directory/translationLanguages";
import {randomString} from "../../../../utils/randomString"
import { RULES} from "../../directory/siteRules";
import SiteRule from "../../common/form/SiteRule";
import {submissionErrorToFormFields} from "../../../../utils/submissionErrorToFormFields";

class Input extends React.Component {
    render() {
        return <AntdInput autoComplete="none" {...this.props}/>;
    }
}

const LanguageParametersForm = ({values, lang}) => {
    const [languageSettings, setLanguageSettings] = useState(values);
    const onLanguageSettings = (targetKey, action) => {
        const t = {...languageSettings};

        switch (action) {
            default:
                break;

            case 'add':
                t[targetKey] = {};
                setLanguageSettings(t);
                break;

            case 'remove':
                delete t[targetKey];
                setLanguageSettings(t);
                break;
        }
    };

    return <>
        <Dropdown overlay={<Menu onClick={({key}) => onLanguageSettings(key, 'add')}>
            {Object.keys(availableLanguageSettings)
                .filter(setting => !languageSettings[setting])
                .map(setting => <Menu.Item key={setting}>{availableLanguageSettings[setting]}</Menu.Item>)}
        </Menu>}>
            <Button icon={<PlusCircleOutlined />} shape="circle" type="link"/>
        </Dropdown>
        {Object.keys(languageSettings).map(key =>
            <Form.Item key={key} name={['theme', 'languages', lang, key]} rules={[{required: true, message: 'Dieser Wert sollte nicht leer sein.'}]}>
                <Input
                    addonAfter={<Button size="small" icon={<DeleteOutlined />} type="link" onClick={() => onLanguageSettings(key, 'remove')}/>}
                    addonBefore={<div style={{textAlign: 'left'}}>{availableLanguageSettings[key]}</div>}
                />
            </Form.Item>
        )}
    </>;
};

export default (connect(
    (state, {siteId}) => ({
        site: getEntityById(state, siteId),
    }),
    dispatch => ({
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({site, siteId, onRemovePanel}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        setThemeSettings(site.theme.settings);
        setThemeLanguages(site.theme.languages || {});
        setActiveThemeLanguage(
            Object.keys(site.theme.languages).length > 0
                ? Object.keys(site.theme.languages)[0]
                : undefined
        );
        site.theme.requiredLinks = site.theme.requiredLinks || {};
        setThemeRequiredLinks(site.theme.requiredLinks);
        setActiveThemeRequiredLink(
            Object.keys(site.theme.requiredLinks).length > 0
                ? Object.keys(site.theme.requiredLinks)[0]
                : undefined
        );
        setThemeRules(site.theme.rules || []);
        defer(form.setFieldsValue, {
            ...site,
            theme: {
                settings: {...site.theme.settings},
                languages: {...site.theme.languages},
                requiredLinks: {...site.theme.requiredLinks},
                rules: {...site.theme.rules},
                behaviours: {...site.theme.behaviours}
            }
        });
    }, []);

    const handleSubmit = values => {

        values.theme = values.theme || {};
        values.theme.settings = values.theme.settings || {};
        values.theme.languages = values.theme.languages || {};
        values.theme.requiredLinks = values.theme.requiredLinks || {};
        values.theme.rules = values.theme.rules || [];

        setLoading(true);

        return fetch(siteId, {method: 'PUT', body: JSON.stringify(values)})
            .then(data => {
                setLoading(false);
                notification.success({message: `"${site.name}" wurde erfolgreich gespeichert.`});

                fetch(`${siteId}/publish`, {method: 'POST'})
                  .then(() => {
                      notification.info({message: `"${site.name}" wird veröffentlicht.`});
                  })
                  .catch(e => {
                      notification.warning({message: `"${site.name}" konnte nicht veröffentlicht werden.`});
                  });
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => setLoading(false));
    };

    const [themeSettings, setThemeSettings] = useState({});
    const onThemeSettings = (targetKey, action) => {
        const t = {...themeSettings};

        switch (action) {
            default:
                break;

            case 'add':
                t[targetKey] = {};
                setThemeSettings(t);
                break;

            case 'remove':
                delete t[targetKey];
                setThemeSettings(t);
                break;
        }
    };

    const [themeLanguages, setThemeLanguages] = useState({});
    const [activeThemeLanguage, setActiveThemeLanguage] = useState();
    const onThemeLanguagesTabsEdit = (targetKey, action) => {
        const t = {...themeLanguages};

        switch (action) {
            default:
                break;

            case 'add':
                t[targetKey] = {};
                setThemeLanguages(t);
                setActiveThemeLanguage(targetKey);
                break;

            case 'remove':
                delete t[targetKey];
                setThemeLanguages(t);
                break;
        }
    };

    const [themeRequiredLinks, setThemeRequiredLinks] = useState({});
    const [activeThemeRequiredLink, setActiveThemeRequiredLink] = useState();
    const onThemeRequiredLinksTabsEdit = (targetKey, action) => {
        const t = {...themeRequiredLinks};

        switch (action) {
            default:
                break;

            case 'add':
                t[targetKey] = {};
                setThemeRequiredLinks({...t});
                setActiveThemeRequiredLink(targetKey);
                break;

            case 'remove':
                delete t[targetKey];
                setThemeRequiredLinks({...t});
                break;
        }
    };

    const [themeRules, setThemeRules] = useState({});
    const onThemeRules = (targetKey, action) => {
        const t = {...themeRules};

        switch (action) {
            default:
                break;

            case 'add':
                t[randomString()] = RULES[targetKey];
                setThemeRules(t);
                break;

            case 'remove':
                delete t[targetKey];
                setThemeRules(t);
                break;
        }
    };

    const doDelete = () => {
        setLoading(true);

        fetch(siteId, {method: 'DELETE'})
            .then(e => onRemovePanel(`cookieconsentsites:${siteId}:edit`))
            .catch(e => {
                setLoading(false);
            })
    };

    return (
        <Spin spinning={loading} size="large">
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography.Title ellipsis level={3}>
                    {site.name}
                </Typography.Title>
                <Popconfirm  title={`"${site.name}" wirklich löschen?`} onConfirm={doDelete}>
                    <Button icon={<DeleteOutlined />} shape="circle" style={{marginLeft: '.5em'}}/>
                </Popconfirm>
            </div>

            <Form layout="vertical" onFinish={handleSubmit} initialValues={{ 'theme.behaviours.usedLanguages': [] }} form={form}>
                <Typography.Title level={4} type="secondary">Basisdaten</Typography.Title>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Name" name={'name'} rules={[{type: 'string'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label="Kunde" name={'customerId'} rules={[{required: true, message: 'Dieser Wert sollte nicht leer sein.'}]}>
                            <RemoteSelect
                                render={item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>}
                                resourceUrl="customers"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Erlaubte Hosts/Domains" name={'allowedHosts'}>
                            <Select mode="tags" dropdownStyle={{display: 'none'}} tokenSeparators={[',']}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider/>
                <Typography.Title level={4} type="secondary">Benutztes Template</Typography.Title>
                <Form.Item name="template">
                    <Radio.Group initialValue={1} style={{display: 'grid'}}>
                        <Radio value={1}>Standard</Radio>
                        <Radio value={2}>Kategorien ohne Checkboxen</Radio>
                        <Radio value={3}>"Alle Cookies akzeptieren"-Button über die gesamte Breite</Radio>
                        <Radio value={4}>"Alle ablehnen" Button</Radio>
                    </Radio.Group>
                </Form.Item>

                <Divider/>
                <Typography.Title level={4} type="secondary">
                    Notwendige Links
                    <Tooltip title="Links hinzufügen">
                        <Dropdown overlay={<Menu onClick={({key}) => onThemeRequiredLinksTabsEdit(key, 'add')}>
                            {Object.keys(translationLanguages).map(lang => <Menu.Item key={lang}>{translationLanguages[lang]}</Menu.Item>)}
                        </Menu>}>
                            <Button icon={<PlusCircleOutlined />} shape="circle" type="link"/>
                        </Dropdown>
                    </Tooltip>
                </Typography.Title>
                <Form.Item name={['theme', 'requiredLinks']} rules={[{required: true, message: 'Bitte geben Sie notwendige Links an.'}]}>
                <Tabs
                    activeKey={activeThemeRequiredLink}
                    animated
                    hideAdd
                    onChange={setActiveThemeRequiredLink}
                    onEdit={onThemeRequiredLinksTabsEdit}
                    type="editable-card"
                >
                    {Object.keys(themeRequiredLinks).map(key => (
                        <Tabs.TabPane forceRender={true} key={key} tab={translationLanguages[key]}>
                            {[
                                {key: 'imprint', label: 'Impressum'},
                                {key: 'privacy', label: 'Datenschutz'},
                            ].map((value, index) =>
                                <Row gutter={0} type="flex" key={index}>
                                    <Col span={12}>
                                        <Form.Item name={['theme', 'requiredLinks', key, value.key, 'text']} rules={[{required: true, message: 'Dieser Wert sollte nicht leer sein.'}]}>
                                            <Input
                                                addonBefore={<div style={{textAlign: 'left'}}>{value.label}</div>}
                                                placeholder="Text"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={false} name={['theme', 'requiredLinks', key, value.key, 'url']} rules={[
                                            {required: true, message: 'Dieser Wert sollte nicht leer sein.'},
                                            {pattern: /^\/(?!\/).*/, message: 'Dieser Wert muss dem Muster "/irgend/etwas" entsprechen.'}
                                        ]}>
                                            <Input
                                                placeholder="URL"
                                                style={{
                                                    borderRadius: '0 4px 4px 0',
                                                    marginLeft: -1,
                                                    top: -1,
                                                    width: 'calc(100% + 1px)'
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
                </Form.Item>

                <Divider/>

                <Typography.Title level={4} type="secondary">Benutzte Sprachen</Typography.Title>
                <Form.Item name={['theme', 'behaviours', 'usedLanguages']} rules={[{required: true, message: 'Bitte geben Sie eine benutzte Sprache an.'}]}>
                    <Checkbox.Group style={{width: '100%'}}>
                        <Row gutter={10} type="flex">
                            {Object.keys(translationLanguages).map(lang => <Col span={6}>
                                    <Checkbox key={lang} value={lang}>{translationLanguages[lang]}</Checkbox>
                                </Col>
                            )}
                        </Row>
                    </Checkbox.Group>
                </Form.Item>

                <Typography.Title level={4} type="secondary">
                    Sprach-Parameter
                    <Dropdown overlay={<Menu onClick={({key}) => onThemeLanguagesTabsEdit(key, 'add')}>
                        {Object.keys(translationLanguages).map(lang => <Menu.Item key={lang}>{translationLanguages[lang]}</Menu.Item>)}
                    </Menu>}>
                        <Button icon={<PlusCircleOutlined />} shape="circle" type="link"/>
                    </Dropdown>
                </Typography.Title>
                <Tabs
                    activeKey={activeThemeLanguage}
                    animated
                    hideAdd
                    onChange={setActiveThemeLanguage}
                    onEdit={onThemeLanguagesTabsEdit}
                    type="editable-card"
                >
                    {Object.keys(themeLanguages).map(key => (
                        <Tabs.TabPane forceRender={true} key={key} tab={translationLanguages[key]}>
                            <LanguageParametersForm values={themeLanguages[key]} lang={key}/>
                        </Tabs.TabPane>
                    ))}
                </Tabs>

                <Divider/>

                <Typography.Title level={4} type="secondary">
                    Theme-Parameter
                    <Dropdown overlay={<Menu onClick={({key}) => onThemeSettings(key, 'add')}>
                        {Object.keys(availableThemeSettings)
                            .filter(setting => !themeSettings[setting])
                            .map(setting => <Menu.Item key={setting}>{availableThemeSettings[setting]}</Menu.Item>)}
                    </Menu>}>
                        <Button icon={<PlusCircleOutlined />} shape="circle" type="link"/>
                    </Dropdown>
                </Typography.Title>
                {Object.keys(themeSettings).map(key => <Form.Item key={key} name={['theme', 'settings', key]} rules={[{required: true, message: 'Dieser Wert sollte nicht leer sein.'}]}>
                    <Input
                        addonAfter={<Button size="small" icon={<DeleteOutlined />} type="link" onClick={() => onThemeSettings(key, 'remove')}/>}
                        addonBefore={<div style={{textAlign: 'left', textTransform: 'capitalize'}}>{availableThemeSettings[key]}</div>}
                    />
                    </Form.Item>
                )}

                <Divider/>
                <Typography.Title level={4} type="secondary">
                    Regeln
                    <Dropdown overlay={<Menu onClick={({key}) => onThemeRules(key, 'add')}>
                        {RULES.map((rule, index) => <Menu.Item key={index}>{rule.variable.name}</Menu.Item>)}
                    </Menu>}>
                        <Button icon={<PlusCircleOutlined />} shape="circle" type="link"/>
                    </Dropdown>
                </Typography.Title>
                {Object.keys(themeRules).map((key, index) =>
                    <SiteRule key={key} index={key} onRemove={onThemeRules} rule={themeRules[key]}/>
                )}

                <div style={{textAlign: 'right'}}>
                    <Button icon={<CloseOutlined />} size="large" style={{marginRight: 10}} onClick={() => onRemovePanel(`cookieconsentsites:${siteId}:edit`)}>Abbrechen</Button>
                    <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">Speichern & Veröffentlichen</Button>
                </div>
            </Form>
        </Spin>
    );
}));
