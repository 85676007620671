const RULE_OPERATOR_EQUALS = 'eq';
const RULE_OPERATOR_EQUALS_NOT = 'neq';
const RULE_OPERATOR_GREATER_THAN = 'gt';
const RULE_OPERATOR_GREATER_THAN_OR_EQUAL = 'gte';
const RULE_OPERATOR_LOWER_THAN = 'lt';
const RULE_OPERATOR_LOWER_THAN_OR_EQUAL = 'lte';
const RULE_OPERATOR_CONTAINS = 'in';
const RULE_OPERATOR_CONTAINS_NOT = 'nin';
const RULE_OPERATOR_REGEX_MATCH = 'regex_match';
const RULE_OPERATOR_REGEX_NO_MATCH = 'regex_no_match';

export const RULE_OPERATORS = {};
RULE_OPERATORS[RULE_OPERATOR_EQUALS] = 'ist gleich';
RULE_OPERATORS[RULE_OPERATOR_EQUALS_NOT] = 'ist ungleich';
RULE_OPERATORS[RULE_OPERATOR_GREATER_THAN] = 'ist größer';
RULE_OPERATORS[RULE_OPERATOR_GREATER_THAN_OR_EQUAL] = 'ist größer oder gleich';
RULE_OPERATORS[RULE_OPERATOR_LOWER_THAN] = 'ist kleiner';
RULE_OPERATORS[RULE_OPERATOR_LOWER_THAN_OR_EQUAL] = 'ist kleiner oder gleich';
RULE_OPERATORS[RULE_OPERATOR_CONTAINS] = 'enthält';
RULE_OPERATORS[RULE_OPERATOR_CONTAINS_NOT] = 'enthält nicht';
RULE_OPERATORS[RULE_OPERATOR_REGEX_MATCH] = 'entspricht';
RULE_OPERATORS[RULE_OPERATOR_REGEX_NO_MATCH] = 'entspricht nicht';



const RULE_TYPE_FUNCTION = 'function';
const RULE_TYPE_VARIABLE = 'variable';

export const RULES = [
    {
        variable: {
            name: 'Page Url',
            type: RULE_TYPE_VARIABLE,
            value: 'window.location.pathname'
        }
    }
];
