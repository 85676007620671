import React, {useState} from "react";
import {connect} from "react-redux";
import { PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Input as AntdInput,
    InputNumber,
    Menu,
    Row,
    Spin,
    Tabs,
    Tooltip,
    Typography,
    Form
} from "antd";
import {fetch} from "../../../../../api/rest";
import {SubmissionError} from "redux-form";
import {addAction as addPanelAction, removeAction as removePanelAction} from "../../../../../actions/panel";
import {
    PANEL_COOKIECONSENT_SITE_EDIT
} from "../../../../../factories/panel";
import {translationLanguages} from "../../../directory/translationLanguages";
import EntityLoader from "../../../../common/EntityLoader";
import {submissionErrorToFormFields} from "../../../../../utils/submissionErrorToFormFields";

class Input extends React.Component {
    render() {
        return <AntdInput autoComplete="none" {...this.props}/>;
    }
}

const SiteTitle = ({entity}) => <small>{entity && entity.name}</small>;

export default (connect(
    null,
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({onAddPanel, onRemovePanel, siteId}) => {
    const [loading, setLoading] = useState(false);
    const [translations, setTranslations] = useState({});
    const [activeTranslation, setActiveTranslation] = useState();
    const [form] = Form.useForm();

    const handleSubmit = values => {
        values.site = siteId;

        if (values.cookieLifetime === 0) {
            values.cookieLifetime = null; //make it nullable
        }

        values.translations = values.translations || {};
        Object.keys(values.translations).forEach((key) => {
            ['name', 'description'].forEach(prop => {
                if (String(values.translations[key][prop]).length === 0) {
                    values.translations[key][prop] = null;
                }
            });
        });

        setLoading(true);

        return fetch('/cookieconsent/sites/services/groups', {method: 'POST', body: JSON.stringify(values)})
            .then(data => {
                setLoading(false);
                onAddPanel(
                    {
                        type: PANEL_COOKIECONSENT_SITE_EDIT,
                        uid: `cookieconsentsites:${siteId}:edit`,
                        config: {
                            siteId: siteId
                        }
                    },
                    `cookieconsentsitegroups:${siteId}:new`
                );
                onRemovePanel(`cookieconsentsitegroups:${siteId}:new`);
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => setLoading(false));
    };

    const onTranslationTabsEdit = (targetKey, action) => {
        const t = {...translations};

        switch (action) {
            default:
                break;

            case 'add':
                t[targetKey] = {};
                setTranslations(t);
                setActiveTranslation(targetKey);
                break;

            case 'remove':
                delete t[targetKey];
                setTranslations(t);
                break;
        }
    };

    return (
        <Spin spinning={loading} size="large">
            <Typography.Title level={3}>
                Neue Gruppe
                <br />
                <EntityLoader entityId={siteId}>
                    <SiteTitle/>
                </EntityLoader>
            </Typography.Title>

            <Form layout="vertical" onFinish={handleSubmit} form={form}>

                <Typography.Title level={4} type="secondary">Basisdaten</Typography.Title>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Name" name={'name'} rules={[{type: 'string'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label="Position" name={'position'}>
                            <InputNumber/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Beschreibung" name={'description'} rules={[{type: 'string'}]}>
                            <AntdInput.TextArea autoSize={{maxRows:10}}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider/>
                <Typography.Title level={4} type="secondary">
                    Übersetzungen
                    <Tooltip title="Übersetzung hinzufügen">
                        <Dropdown overlay={<Menu onClick={({key}) => onTranslationTabsEdit(key, 'add')}>
                            {Object.keys(translationLanguages).map(lang => <Menu.Item key={lang}>{translationLanguages[lang]}</Menu.Item>)}
                        </Menu>}>
                            <Button icon={<PlusCircleOutlined />} shape="circle" type="link"/>
                        </Dropdown>
                    </Tooltip>
                </Typography.Title>
                <Tabs
                    activeKey={activeTranslation}
                    animated
                    hideAdd
                    onChange={setActiveTranslation}
                    onEdit={onTranslationTabsEdit}
                    type="editable-card"
                >
                    {Object.keys(translations).map(key => (
                        <Tabs.TabPane key={key} tab={translationLanguages[key]}>
                            <Form.Item name={['translations', key, 'locale']} initialValue={key} rules={[{type: 'string'}]} noStyle>
                                <Input type="hidden"/>
                            </Form.Item>

                            <Row gutter={10} type="flex" justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Name" name={['translations', key, 'name']} rules={[{type: 'string'}]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item label="Zweck" name={['translations', key, 'description']} rules={[{type: 'string'}]}>
                                <AntdInput.TextArea autoSize={{maxRows:10}}/>
                            </Form.Item>
                        </Tabs.TabPane>
                    ))}
                </Tabs>

                <div style={{textAlign: 'right'}}>
                    <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">Speichern</Button>
                </div>
            </Form>

        </Spin>
    );
}));
