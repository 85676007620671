import React from "react";
import {connect} from 'react-redux';
import {Menu} from "antd";
import create from "../../factories/panelHistory";

export default connect(
    (state) => ({
        history: state.panelHistoryState,
    }),
)(({history}) =>
    <Menu style={{border: '0 none'}} selectable={false}>
        {history.positions.map((position, index) => create(history.items[position]))}
    </Menu>
);
