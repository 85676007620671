import React, {useState} from "react";
import {connect} from "react-redux";
import Panel from "../../../panel/Panel";
import Form from "./forms/Edit";
import {removeAction as removePanelAction} from "../../../../actions/panel";
import { CloudServerOutlined, RobotOutlined } from '@ant-design/icons';
import { Button, Result, Spin } from "antd";
import EntityLoader from "../../../common/EntityLoader";

const Title = ({entity}) => <span>
    <RobotOutlined /><CloudServerOutlined /> {!!entity ? entity.name : '404 - Not Found'}
</span>;

const Content = connect(
    null,
    dispatch => ({
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({entity, entityId, providerId, onRemovePanel}) => <>
    {entity
        ? <Form providerId={providerId} serviceId={entityId}/>
        : <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={() => onRemovePanel(`cookieconsentproviderservices:${entityId}:edit`)}>close</Button>}
        />
    }
</>);

export default ({providerId, serviceId}) => {
    const [loading, setLoading] = useState(true);

    return <Panel
        backgroundColor="#660e7a"
        id={`cookieconsentproviderservices:${serviceId}:edit`}
        title={
            <EntityLoader entityId={serviceId}>
                <Title/>
            </EntityLoader>
        }
    >
        <Spin spinning={loading}>
            <EntityLoader entityId={serviceId} onFinally={() => setLoading(false)}>
                <Content providerId={providerId}/>
            </EntityLoader>
        </Spin>
    </Panel>
}
