import React from "react";
import {connect} from 'react-redux';
import {getEntitiesById} from "../../../../../selectors/entity";
import {addAction as addPanelAction} from "../../../../../actions/panel";
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {Button, Dropdown, Menu, Table, Tooltip, Typography} from "antd";
import {
    PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_EDIT_DEFAULT,
    PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_EDIT_PRIVATE,
    PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_NEW_DEFAULT,
    PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_NEW_PRIVATE,
} from "../../../../../factories/panel";
import TableContainer from "../../../../common/table/Container";
import EntityLoader from "../../../../common/EntityLoader";

const ListView = connect(
    (state, {itemIds}) => ({
        items: getEntitiesById(state, itemIds),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({filters, items, loading, onAddPanel, onChange, pagination, groupId}) => {
    const SITE_GROUP_SERVICE_NEW_PANELTYPES = {
        'default': PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_NEW_DEFAULT,
        'private': PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_NEW_PRIVATE
    };

    const SITE_GROUP_SERVICE_EDIT_PANELTYPES = {
        'default': PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_EDIT_DEFAULT,
        'private': PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_EDIT_PRIVATE
    };

    return <>
        <Typography.Title level={4} type="secondary">
            Dienste
            <Tooltip title="Dienst hinzufügen">
                <Dropdown overlay={<Menu onClick={({key}) => onAddPanel(
                    {
                        type: SITE_GROUP_SERVICE_NEW_PANELTYPES[key],
                        uid: `cookieconsentsitegroupservices:${groupId}:new:${key}`,
                        config: {
                            groupId: groupId,
                        }
                    },
                    `cookieconsentsitegroups:${groupId}:edit`
                )}>
                    <Menu.Item key="private">Privat</Menu.Item>
                    <Menu.Item key="default">Standard</Menu.Item>
                </Menu>}>
                    <Button icon={<PlusCircleOutlined />} shape="circle" type="link"/>
                </Dropdown>
            </Tooltip>
        </Typography.Title>

        <Table
            dataSource={items}
            loading={loading}
            onChange={onChange}
            pagination={pagination}
            rowKey={record => record['@id']}
            size="small"
            style={{marginTop: '16px'}}
        >
            <Table.Column
                dataIndex="id"
                title="#"
                render={(value, record, index) => index + 1 + (pagination.current - 1) * pagination.pageSize}
            />

            <Table.Column
                dataIndex="name"
                title="Name"
                width="100%"
            />

            <Table.Column
                dataIndex="position"
                sorter
                title="Pos."
            />

            <Table.Column
                render={(value, record, index) => (
                    <Button icon={<EditOutlined />} shape="circle" size="small" type="primary" onClick={() => {
                        let serviceType = 'default';

                        switch (record['@type']) {
                            default:
                                break;
                            case 'CookieConsentSiteDefaultservice':
                                serviceType = 'default';
                                break;
                            case 'CookieConsentSitePrivateservice':
                                serviceType = 'private';
                                break;
                        }

                        onAddPanel({
                            type: SITE_GROUP_SERVICE_EDIT_PANELTYPES[serviceType],
                            uid: `cookieconsentsitegroupservices:${record['@id']}:edit:${serviceType}`,
                            config: {
                                groupId: groupId,
                                serviceId: record['@id']
                            }},
                        `cookieconsentsitegroups:${groupId}:edit`)
                    }}/>
                )}
            />
        </Table>
    </>;
});

const LoaderWrapper = ({entity, entityId}) => <TableContainer resourceUrl={`${entityId}/services`} reloadDeps={[entity]}>
    <ListView group={entity} groupId={entityId}/>
</TableContainer>;

export default ({groupId}) => <EntityLoader entityId={groupId}>
    <LoaderWrapper/>
</EntityLoader>
