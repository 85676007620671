import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {addAction as addPanelAction} from "../../actions/panel";
import {
    DeleteOutlined,
    EditOutlined,
    InfoOutlined,
    LinkOutlined,
    MailOutlined,
    MobileOutlined,
    PhoneOutlined,
    PrinterOutlined,
    StarFilled,
    UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Card, Popconfirm, Popover, Row, Spin, Tooltip, Typography } from "antd";
import {fetch} from "../../api/rest";
import moment from "moment";
import {getEntitiesById} from "../../selectors/entity";
import DescriptionColumn from "../common/DescriptionColumn";
import {PANEL_CUSTOMER_CONTACT_EDIT} from "../../factories/panel";

const ContactList = connect(
    (state, {contactIds}) => ({
        contacts: getEntitiesById(state, contactIds),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({contactIds, contacts, customerId, onAddPanel, updateHash}) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (contactIds.length > 0) {
            setLoading(true);
            fetch(customerId + '/contacts?items_per_page=1000')
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [customerId, updateHash]);

    return (
        <Spin spinning={loading}>
            <div style={{overflowX: 'auto', display: 'flex', flexWrap: "nowrap"}}>
                {contacts.map((contact, index) => <Card
                    bodyStyle={{padding: '12px 0'}}
                    cover={<div style={{textAlign: "center", paddingTop: 7}}><Avatar size={98} icon={<UserOutlined />} src={`//loremflickr.com/200/200/kitten?lock=${contact.id}`} style={{boxShadow: '0 0 2px black'}}/></div>}
                    key={index}
                    style={{maxWidth: 180, minWidth: 180, padding: 5, margin: 5, textAlign: "center"}}
                >
                    <Card.Meta
                        description={contact.position || '…'}
                        style={{paddingBottom: "1em"}}
                        title={`${!contact.salutation ? '' : contact.salutation} ${!contact.title ? '' : contact.title} ${!contact.firstname ? '' : contact.firstname} ${!contact.lastname ? '' : contact.lastname}`}
                    />

                    <div style={{marginBottom: "2em"}}>
                        {contact.phone && <>
                            <Tooltip title="Festnetznummer anrufen"><Button size="small" icon={<PhoneOutlined />} type="link" href={`tel:${contact.phone}`}/></Tooltip>
                            <Typography.Text copyable ellipsis style={{maxWidth: 'calc(100% - 32px)'}} size="small">{contact.phone}</Typography.Text>
                        </>}

                        {contact.mobile && <div>
                            <Tooltip title="Mobilfunknummer anrufen"><Button size="small" icon={<MobileOutlined />} type="link" href={`tel:${contact.mobile}`}/></Tooltip>
                            <Typography.Text copyable ellipsis style={{maxWidth: 'calc(100% - 32px)'}} size="small">{contact.mobile}</Typography.Text>
                        </div>}

                        {contact.fax && <div>
                            <Tooltip title="Fax"><Button size="small" icon={<PrinterOutlined />} type="link" disabled/></Tooltip>
                            <Typography.Text copyable ellipsis style={{maxWidth: 'calc(100% - 32px)'}} size="small">{contact.fax}</Typography.Text>
                        </div>}

                        {contact.email && <div>
                            <Tooltip title="E-Mail senden"><Button size="small" icon={<MailOutlined />} type="link" href={`mailto:${contact.email}`}/></Tooltip>
                            <Typography.Text copyable ellipsis style={{maxWidth: 'calc(100% - 32px)'}} size="small">{contact.email}</Typography.Text>
                        </div>}
                    </div>

                    <div style={{bottom: 10, position: 'absolute', textAlign: "center", width: "100%"}}>
                        <Popconfirm title={`Are you sure delete "${!contact.salutation ? '' : contact.salutation} ${!contact.title ? '' : contact.title} ${!contact.firstname ? '' : contact.firstname} ${!contact.lastname ? '' : contact.lastname}" ?`} onConfirm={() => {
                            fetch(contact['@id'], {method: 'DELETE'}).catch(e => {})
                        }}>
                            <Button icon={<DeleteOutlined />} size="small" shape="circle" style={{marginLeft: '.25em', marginRight: '.25em'}}/>
                        </Popconfirm>
                        <Button icon={<EditOutlined />} size="small" shape="circle" style={{marginLeft: '.25em', marginRight: '.25em'}} onClick={e => {
                            e.stopPropagation();
                            onAddPanel(
                                {
                                    type: PANEL_CUSTOMER_CONTACT_EDIT,
                                    uid: `customercontacts:${contact['@id']}:edit`,
                                    config: {
                                        customerContactId: contact['@id'],
                                        customerId: contact.customer,
                                    }
                                },
                                `customers:${contact.customer}:show`
                            );
                        }}/>
                        <Popover
                            title={contact.birthday ? <Tooltip title="Geburtstag"><StarFilled /> {moment(contact.birthday).format('L')}</Tooltip> : ''}
                            content={
                                <Row gutter={10} type="flex" justify="space-between" style={{width: 300}}>
                                    <DescriptionColumn
                                        title="Onlineprofile"
                                        description={contact.onlineProfiles.map((value, index) =>
                                            <Button
                                                key={index}
                                                icon={<LinkOutlined />}
                                                type="link"
                                                href={value.uri}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                block
                                                size="small"
                                                style={{textAlign: 'left', padding: 0}}>{value.title}</Button>
                                        )}
                                    />
                                </Row>
                            }
                        >
                            <Button icon={<InfoOutlined />} size="small" shape="circle" style={{marginLeft: '.25em', marginRight: '.25em'}}/>
                        </Popover>
                    </div>
                </Card>)}
            </div>
        </Spin>
    );
});

export {
    ContactList,
}
