import Token from "../Token";

const storageKey = 'token1';

export default class TokenStorage {
    static setToken = (token) => {
        localStorage.setItem(storageKey, token);
    };

    static getToken() {
        let token;
        try {
            token = JSON.parse(localStorage.getItem(storageKey));
        } catch (e) {
            return null;
        }

        if (token !== null) {
            return new Token(token.user, token.roles, token.rawToken);
        }

        return null;
    }

    static reset() {
        localStorage.removeItem(storageKey);
    }
}
