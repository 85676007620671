import React from "react";
import Panel from "../../panel/Panel";
import Form from "./forms/New";
import { RobotOutlined } from '@ant-design/icons';

export default () => <Panel
    backgroundColor="#660e7a"
    id="cookieconsentproviders::new"
    title={<span><RobotOutlined /> Anbieter erstellen</span>}
>
    <Form/>
</Panel>
