import React, {useEffect, useState} from "react";
import {debounce, isEmpty} from "lodash";
import {fetch} from "../../../api/rest";
import {stringify} from "qs";

export default ({children, resourceUrl, pageSize = 25, reloadDeps = [], initialFilters = {}, initialSorters = {}}) => {
    const [itemIds, setItemIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: pageSize,
        pageSizeOptions: [
            '25',
            '50',
            '100',
            '250',
            '500',
        ],
        showSizeChanger: true,
        size: 'middle',
    });
    const [filters, setFilters] = useState(initialFilters);
    const [sorters, setSorters] = useState(initialSorters);

    const load = (signal) => {
        const params = {
            items_per_page: pagination.pageSize,
            page: pagination.current,
        };

        Object.keys(filters).forEach(f => {
            if (!isEmpty(filters[f])) params[f] = filters[f];
        });

        if (sorters.order !== undefined) {
            params['order['+sorters.field+']'] = sorters.order === 'ascend' ? 'asc' : 'desc';
        }

        const query = stringify(params, {arrayFormat: 'brackets', skipNulls: true, encodeValuesOnly: true});

        return fetch(`${resourceUrl}${resourceUrl.indexOf('?') !== -1 ? '&' : '?'}${query}`, {signal})
    };

    const onChange = (pagina, filter = {}, sorter = {}) => {
        setPagination(pagina);
        setFilters(filter);
        setSorters(sorter);
    }

    const onSearch = debounce(str => {
        setPagination({...pagination, current: 1});
        setFilters({...filters, q:str});
    }, 200, {trailing: true});

    const onFilter = params => {
        setPagination({...pagination, current: 1});
        setFilters({...filters, ...params});
    };

    useEffect(() => {
        setLoading(true);

        const controller = new AbortController();
        const {signal} = controller;

        load(signal)
            .then(json => {
                setItemIds(json['hydra:member'].map(item => item['@id']));
                setPagination({...pagination, total: json['hydra:totalItems']});
                setLoading(false)
            })

        return controller.abort.bind(controller);
    }, reloadDeps.concat([pagination.current, pagination.pageSize, filters, sorters]));

    return React.cloneElement(children, {itemIds, loading, filters, onChange, onFilter, onSearch, pagination});
};
