import React, {useState} from "react";
import {connect} from "react-redux";
import Panel from "../../../panel/Panel";
import Form from "./forms/New";
import {removeAction as removePanelAction} from "../../../../actions/panel";
import { CloudServerOutlined, RobotOutlined } from '@ant-design/icons';
import { Button, Result, Spin } from "antd";
import EntityLoader from "../../../common/EntityLoader";

const Title = ({entity}) => <span>
    <RobotOutlined /><CloudServerOutlined /> {!!entity ? 'Dienst hinzufügen' : '404 - Not Found'}
</span>;

const Content = connect(
    null,
    dispatch => ({
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({entity, entityId, onRemovePanel}) => <>
    {entity
        ? <Form providerId={entityId}/>
        : <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={() => onRemovePanel(`cookieconsentproviderservices:${entityId}:new`)}>close</Button>}
        />
    }
</>);

export default ({providerId}) => {
    const [loading, setLoading] = useState(true);

    return <Panel
        backgroundColor="#660e7a"
        id={`cookieconsentproviderservices:${providerId}:new`}
        title={
            <EntityLoader entityId={providerId}>
                <Title/>
            </EntityLoader>
        }
    >
        <Spin spinning={loading}>
            <EntityLoader entityId={providerId} onFinally={() => setLoading(false)}>
                <Content/>
            </EntityLoader>
        </Spin>
    </Panel>
};
