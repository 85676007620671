import Profiles from "./profile/Profiles";

export default class User {
    #_profiles;
    #_username;

    constructor(username) {
        this.#_profiles = new Profiles();
        this.#_username = username;
    }

    get profiles() {
        return this.#_profiles;
    }

    get username() {
        return this.#_username;
    }

    set username(value) {
        this.#_username = value;
    }

    toArray() {
        return {
            username: this.username,
            profiles: this.profiles.toArray(),
        };
    }
}
