import React, {useState} from "react";
import { connect } from 'react-redux';
import Panel from "../panel/Panel";
import {addAction as addPanelAction, removeAction as removePanelAction} from "../../actions/panel";
import {FormattedNumber, IntlContext} from "react-intl";
import { CrownOutlined, DeleteOutlined, EditOutlined, LinkOutlined, UserAddOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Divider,
    Popconfirm,
    Result,
    Row,
    Spin,
    Statistic,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import moment from "moment";
import CountryData from "i18n-iso-countries";
import Label from "../common/Label";
import {significanceOptions} from "../../utils/directory/customerData";
import {fetch} from "../../api/rest";
import {ContactList} from "./ContactList";
import ManagerList from "./ManagerList";
import {CommentList, CommentNew} from "./Comment";
import DescriptionColumn from "../common/DescriptionColumn";
import {values} from "lodash";
import {PANEL_CUSTOMER_CONTACT_NEW, PANEL_CUSTOMER_EDIT} from "../../factories/panel";
import EntityLoader from "../common/EntityLoader";

const Title = ({entity}) => <span>
    <CrownOutlined /> {entity ? entity.name : '404 - Not Found'}
</span>;

const AssigneeTag = ({entity, datetime = null, prefix = null, suffix = null}) => <>
    {entity && <Tooltip title={datetime ? moment(datetime).calendar() : null}>
        <Tag color="blue">{prefix} {entity.email} {suffix}</Tag>
    </Tooltip>}
</>;

const Content = connect(
    null,
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({entity, entityId, onAddPanel, onRemovePanel}) => {
    const doDelete = () => {
        fetch(entityId, {method: 'DELETE'})
            .then(e => onRemovePanel(`customers:${entityId}:show`))
            .catch(err => {})
    };

    return (
        <IntlContext.Consumer>{({locale}) =>
            <>
                {entity
                    ? <>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography.Title ellipsis level={3}>
                                {entity.name}
                            </Typography.Title>
                            <div style={{display: 'flex'}}>
                                <Popconfirm title={`Are you sure delete "${entity.name}" ?`} onConfirm={doDelete}>
                                    <Button icon={<DeleteOutlined />} shape="circle" style={{marginLeft: '.5em'}}/>
                                </Popconfirm>
                                <Button icon={<EditOutlined />} type="primary" shape="circle" style={{marginLeft: '.5em'}} onClick={() => {
                                    onAddPanel(
                                        {
                                            type: PANEL_CUSTOMER_EDIT,
                                            uid: `customers:${entityId}:edit`,
                                            config: {
                                                customerId: entityId

                                            }
                                        },
                                        `customers:${entityId}:show`
                                    );

                                    onRemovePanel(`customers:${entityId}:show`);
                                }}/>
                            </div>
                        </div>

                        <div>
                            <Tag color="blue">Kd.-Nr.: {entity.id}</Tag>

                            <Tag visible={entity.shortName} color="blue">Kürzel: {entity.shortName}</Tag>

                            <Tooltip title="über ein Jahr">
                                <Tag
                                  color={entity.empiricalHourlyRate < 0
                                    ? 'red'
                                    : entity.empiricalHourlyRate === 0
                                      ? 'lightgray'
                                      : 'green'}
                                >Stundensatz &#216;: {entity.empiricalHourlyRate.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</Tag>
                            </Tooltip>

                            <EntityLoader entityId={entity.createdBy}><AssigneeTag datetime={entity.createdAt} prefix="Ersteller:"/></EntityLoader>
                            <EntityLoader entityId={entity.updatedBy}><AssigneeTag datetime={entity.updatedAt} prefix="Bearbeiter:"/></EntityLoader>
                        </div>

                        <Row gutter={10} type="flex" justify="space-between" style={{marginTop: 12}}>
                            <Col span={11}>
                                <Typography.Text>
                                    <address>
                                        {entity.street}<br/>
                                        {entity.postcode} {entity.city}<br/>
                                        {CountryData.getName(entity.country, locale.substr(0, 2))}
                                    </address>
                                    {entity.email && <a href={`mailto:${entity.email}`}>{entity.email}</a>}
                                </Typography.Text>
                            </Col>

                            <Col span={11}>
                                <Row gutter={10} type="flex">
                                    <DescriptionColumn
                                        description={<a href={`tel:${entity.phoneNumber}`}>{entity.phoneNumber}</a>}
                                        title="Telefon"/>
                                    <DescriptionColumn
                                        description={<a href={`tel:${entity.mobileNumber}`}>{entity.mobileNumber}</a>}
                                        title="Mobil"/>
                                    <DescriptionColumn description={entity.faxNumber} title="Fax"/>
                                </Row>
                            </Col>
                        </Row>

                        <Divider style={{marginBottom: 30, marginTop: 20}}/>

                        <Row gutter={10} type="flex" justify="space-between">
                            <Col span={11}>
                                <Typography.Title level={4} type="secondary">Interne Informationen</Typography.Title>
                                <Row gutter={10} type="flex">
                                    <DescriptionColumn title="Status" description=
                                        {entity.significance &&
                                        <Label color={significanceOptions[entity.significance].color}>
                                            {significanceOptions[entity.significance].text}
                                        </Label>
                                        }/>
                                    <DescriptionColumn title="Stundensatz" description={entity.charge !== null &&
                                    <FormattedNumber value={entity.charge} style="currency" currency="EUR"/>
                                    }/>
                                    <DescriptionColumn title="Vertraulichkeit"
                                                       description={entity.confidentials && entity.confidentials}/>
                                    <DescriptionColumn title="ADV" description={entity.advUpdatedAt &&
                                    <Label color="#ececec" border={true}>
                                        {entity.advUpdatedAt ? moment(entity.advUpdatedAt).format('L') : 'nicht vorhanden'}
                                    </Label>
                                    }/>
                                    <DescriptionColumn title="Debitoren-Nr." description={entity.aucon.debitorId}/>
                                    <DescriptionColumn title="Interne Links"
                                                       description={entity.internalLinks.map((value, index) => (
                                                           <Button size="small" key={index} icon={<LinkOutlined />} type="link"
                                                                   href={value.uri} target="_blank"
                                                                   rel="noopener noreferrer" block style={{
                                                               textAlign: 'left',
                                                               padding: 0
                                                           }}>{value.title}</Button>
                                                       ))}/>
                                </Row>
                            </Col>

                            <Col span={11}>
                                <Typography.Title level={4} type="secondary">Onlineprofile</Typography.Title>
                                <Row gutter={10} type="flex">
                                    {entity.homepage &&
                                    <Col span={12}>
                                        <Button size="small" icon={<LinkOutlined />} type="link" href={entity.homepage} target="_blank" rel="noopener noreferrer" block style={{textAlign: 'left', padding: 0}}>Website</Button>
                                    </Col>
                                    }
                                    {entity.onlineProfiles.map((value, index) => (
                                        <Col span={12} key={index}>
                                            <Button size="small" icon={<LinkOutlined />} type="link" href={value.uri} target="_blank" rel="noopener noreferrer" block style={{textAlign: 'left', padding: 0}}>{value.title}</Button>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>

                        <Divider style={{marginBottom: 30, marginTop: 20}}/>

                        <Row gutter={10} type="flex" justify="space-between">
                            <Col span={11}>
                                <Typography.Title level={4} type="secondary">Kundenbetreuer</Typography.Title>
                                <ManagerList customerId={entityId} editable={true} managerIds={values(entity.managers)} updateHash={values(entity.managers).join()}/>
                            </Col>

                            <Col span={11}>
                                <Typography.Title level={4} type="secondary">Projekte</Typography.Title>
                                <Row gutter={10} type="flex">
                                    <Col span={12}><Statistic title="aktiv" value={Math.round(Math.random() * 100)}/></Col>
                                    <Col span={12}><Statistic title="abgeschlossen" value={Math.round(Math.random() * 100)}/></Col>
                                </Row>
                                <Button type="link" style={{padding: 0}}>alle Projekte anzeigen</Button>
                            </Col>
                        </Row>

                        <Divider style={{marginBottom: 30, marginTop: 20}}/>

                        <Typography.Title level={4} type="secondary">
                            Kundenkontakte
                            <Tooltip title="Kundenkontakt hinzufügen">
                                <Button icon={<UserAddOutlined />} shape="circle" type="link" onClick={() => onAddPanel(
                                    {
                                        type: PANEL_CUSTOMER_CONTACT_NEW,
                                        uid: `customercontacts:${entityId}:new`,
                                        config: {
                                            customerId: entityId

                                        }
                                    },
                                    `customers:${entityId}:show`
                                )}/>
                            </Tooltip>
                        </Typography.Title>
                        <ContactList customerId={entityId} contactIds={values(entity.contacts)} updateHash={values(entity.contacts).join()}/>

                        <Divider style={{marginBottom: 30, marginTop: 20}}/>

                        <Typography.Title level={4} type="secondary">Kommunikation</Typography.Title>
                        <CommentNew customerId={entityId}/>
                        <CommentList customerId={entityId} commentIds={values(entity.comments)} updateHash={values(entity.comments).join()}/>
                    </>
                    : <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, the page you visited does not exist."
                        extra={<Button type="primary" onClick={() => onRemovePanel(`customers:${entityId}:show`)}>close</Button>}
                    />
                }
            </>
        }</IntlContext.Consumer>
    );
});

export default ({customerId}) => {
    const [loading, setLoading] = useState(true);

    return <Panel
        id={`customers:${customerId}:show`}
        title={
            <EntityLoader entityId={customerId}>
                <Title/>
            </EntityLoader>
        }
    >
        <Spin spinning={loading}>
            <EntityLoader entityId={customerId} onFinally={() => setLoading(false)}>
                <Content/>
            </EntityLoader>
        </Spin>
    </Panel>
};
