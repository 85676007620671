import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { CloseOutlined, DeleteOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Input as AntdInput,
    InputNumber,
    Menu,
    Popconfirm,
    Row,
    Select,
    Spin,
    Switch,
    Tabs,
    Tooltip,
    Typography,
    Form
} from "antd";
import MonacoEditor from "react-monaco-editor";
import {
    removeAction as removePanelAction
} from "../../../../../../actions/panel";
import {fetch} from "../../../../../../api/rest";
import {SubmissionError} from "redux-form";
import {defer} from "lodash";
import {randomString} from "../../../../../../utils/randomString"
import {getEntityById} from "../../../../../../selectors/entity";
import {translationLanguages} from "../../../../directory/translationLanguages";
import { RULES} from "../../../../directory/siteServiceRules";
import RemoteSelect from "../../../../../common/form/RemoteSelect";
import SiteServiceRule from "../../../../common/form/SiteServiceRule";
import EntityLoader from "../../../../../common/EntityLoader";
import {submissionErrorToFormFields} from "../../../../../../utils/submissionErrorToFormFields";

class Input extends React.Component {
    render() {
        return <AntdInput autoComplete="none" {...this.props}/>;
    }
}

const EntityName = ({entity}) => <small> {entity && entity.name}</small>;

export default (connect(
    (state, {serviceId, groupId}) => ({
        group: getEntityById(state, groupId),
        service: getEntityById(state, serviceId),
    }),
    dispatch => ({
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({group, groupId, service, serviceId, onRemovePanel}) => {
    const [loading, setLoading] = useState(false);
    const [translations, setTranslations] = useState({});
    const [activeTranslation, setActiveTranslation] = useState();
    const [isSubService, setIsSubService] = useState();
    const [form] = Form.useForm();

    useEffect(() => {
        setTranslations(service.translations);
        setActiveTranslation(
            Object.keys(service.translations).length > 0
                ? Object.keys(service.translations)[0]
                : undefined
        );
        setThemeRules(service.theme.rules || []);
        setIsSubService(service.parent !== null);
        defer(form.setFieldsValue, {
            ...service,
            translations: {...service.translations},
            theme: {
                rules: {...service.theme.rules}
            }
        });
    }, []);

    const handleSubmit = values => {

        ['markup', 'name', 'policyUrl', 'purpose'].forEach(prop => {
            if (String(values[prop]).length === 0) {
                values[prop] = null;
            }
        });

        values.translations = values.translations || {};
        Object.keys(values.translations).forEach((key) => {
            ['name', 'policyUrl', 'purpose'].forEach(prop => {
                if (String(values.translations[key][prop]).length === 0) {
                    values.translations[key][prop] = null;
                }
            });
        });

        if (!isSubService) values['parent'] = null; //cleanup by service mode

        values.theme = values.theme || {};
        values.theme.rules = values.theme.rules || [];

        setLoading(true);

        return fetch(serviceId, {method: 'PUT', body: JSON.stringify(values)})
            .then(data => {
                setLoading(false);
                onRemovePanel(`cookieconsentsitegroupservices:${serviceId}:edit:default`);
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => setLoading(false));
    };

    const onTranslationTabsEdit = (targetKey, action) => {
        const t = {...translations};

        switch (action) {
            default:
                break;

            case 'add':
                t[targetKey] = {};
                setTranslations(t);
                setActiveTranslation(targetKey);
                break;

            case 'remove':
                delete t[targetKey];
                setTranslations(t);
                break;
        }
    };

    const [themeRules, setThemeRules] = useState({});
    const onThemeRules = (targetKey, action) => {
        const t = {...themeRules};

        switch (action) {
            default:
                break;

            case 'add':
                t[randomString()] = RULES[targetKey];
                setThemeRules(t);
                break;

            case 'remove':
                delete t[targetKey];
                setThemeRules(t);
                break;
        }
    };

    const doDelete = () => {
        setLoading(true);

        fetch(serviceId, {method: 'DELETE'})
            .then(e => onRemovePanel(`cookieconsentsitegroupservices:${serviceId}:edit:default`))
            .catch(e => {
                setLoading(false);
            })
    };

    return (
        <Spin spinning={loading} size="large">
            <Typography.Title ellipsis level={3}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {service.name}
                    <Popconfirm  title={`"${service.name}" wirklich löschen?`} onConfirm={doDelete}>
                        <Button icon={<DeleteOutlined />} shape="circle" style={{marginLeft: '.5em'}}/>
                    </Popconfirm>
                </div>
                <EntityLoader entityId={groupId}>
                    <EntityName/>
                </EntityLoader>
            </Typography.Title>

            <Form layout="vertical" onFinish={handleSubmit} form={form}>

                <Typography.Title level={4} type="secondary">Basisdaten</Typography.Title>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Basisdienst" name={'service'} rules={[{required: true, message: 'Dieser Wert sollte nicht leer sein.'}]}>
                            <RemoteSelect
                                allowClear
                                queryParam="name"
                                render={item => <Select.Option key={item.id} value={item['@id']}>
                                    <>
                                        {item.name}
                                        <EntityLoader entityId={item.provider}>
                                            <EntityName/>
                                        </EntityLoader>
                                    </>
                                </Select.Option>}
                                resourceUrl="cookieconsent/providers/services"
                            />
                        </Form.Item>

                        <Row gutter={10}>
                            <Col span={12}>
                                <Form.Item label="Unveränderlich" name={'immutable'} valuePropName={'checked'}>
                                    <Switch/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Sub-Dienst">
                                    <Switch onChange={setIsSubService} checked={isSubService}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={11}>
                        <Row gutter={10}>
                            <Col span={18}>
                                <Form.Item label="Dienst-Gruppe" name={'group'}>
                                    {group && <RemoteSelect allowClear queryParam="name" resourceUrl={`${group.site}/groups`}/>}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Position" name={'position'}>
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        {isSubService && <Form.Item key="parent" label="Übergeordneter Dienst" name={'parent'}>
                            <RemoteSelect allowClear resourceUrl={`${groupId}/services`}/>
                        </Form.Item>}
                    </Col>
                </Row>

                <Form.Item label="Markup" name={'markup'} rules={[{type: 'string'}]}>
                    <MonacoEditor height={200} width="100%" language="javascript" theme="vs-dark"/>
                </Form.Item>

                <Divider/>
                <Typography.Title level={4} type="secondary">Modifikationen</Typography.Title>
                <small>Mit dem Basisdienst identische Werte werden nicht überschrieben.</small>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Name" name={'name'} rules={[{type: 'string'}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="URL zu Vertragsbedingungen" name={'policyUrl'} rules={[{type: 'url'}]}>
                            <Input type="url"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item label="Zweck" name={'purpose'} rules={[{type: 'string'}]}>
                    <AntdInput.TextArea autoSize={{maxRows:10}}/>
                </Form.Item>

                <Divider/>
                <Typography.Title level={4} type="secondary">
                    Übersetzungen
                    <Tooltip title="Übersetzung hinzufügen">
                        <Dropdown overlay={<Menu onClick={({key}) => onTranslationTabsEdit(key, 'add')}>
                            {Object.keys(translationLanguages).map(lang => <Menu.Item key={lang}>{translationLanguages[lang]}</Menu.Item>)}
                        </Menu>}>
                            <Button icon={<PlusCircleOutlined />} shape="circle" type="link"/>
                        </Dropdown>
                    </Tooltip>
                </Typography.Title>
                <Tabs
                    activeKey={activeTranslation}
                    animated
                    hideAdd
                    onChange={setActiveTranslation}
                    onEdit={onTranslationTabsEdit}
                    type="editable-card"
                >
                    {Object.keys(translations).map(key => (
                        <Tabs.TabPane forceRender={true} key={key} tab={translationLanguages[key]}>
                            <Form.Item name={['translations', key, '@id']} noStyle>
                                <Input type="hidden"/>
                            </Form.Item>
                            <Form.Item name={['translations', key, 'locale']} initialValue={key} rules={[{type: 'string'}]} noStyle>
                                <Input type="hidden"/>
                            </Form.Item>

                            <Row gutter={10} type="flex" justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Name" name={['translations', key, 'name']} rules={[{type: 'string'}]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="URL zu Vertragsbedingungen" name={['translations', key, 'policyUrl']} rules={[{type: 'url'}]}>
                                        <Input type="url"/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item label="Zweck" name={['translations', key, 'purpose']} rules={[{type: 'string'}]}>
                                <AntdInput.TextArea autoSize={{maxRows:10}}/>
                            </Form.Item>
                        </Tabs.TabPane>
                    ))}
                </Tabs>

                <Divider/>
                <Typography.Title level={4} type="secondary">
                    Regeln
                    <Dropdown overlay={<Menu onClick={({key}) => onThemeRules(key, 'add')}>
                        {RULES.map((rule, index) => <Menu.Item key={index}>{rule.variable.name}</Menu.Item>)}
                    </Menu>}>
                        <Button icon={<PlusCircleOutlined />} shape="circle" type="link"/>
                    </Dropdown>
                </Typography.Title>
                {Object.keys(themeRules).map((key, index) =>
                    <SiteServiceRule key={key} index={key} onRemove={onThemeRules} rule={themeRules[key]}/>
                )}

                <div style={{textAlign: 'right'}}>
                    <Button icon={<CloseOutlined />} size="large" style={{marginRight: 10}} onClick={() => onRemovePanel(`cookieconsentsitegroupservices:${serviceId}:edit:default`)}>Abbrechen</Button>
                    <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">Speichern</Button>
                </div>
            </Form>
        </Spin>
    );
}));
