import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    CloseOutlined,
    DeleteOutlined,
    InboxOutlined,
    PlusCircleOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Input as AntdInput,
    Menu,
    Popconfirm,
    Row,
    Spin,
    Tabs,
    Tooltip,
    Typography,
    Upload,
    Form
} from "antd";
import {
    removeAction as removePanelAction
} from "../../../../actions/panel";
import {fetch} from "../../../../api/rest";
import {SubmissionError} from "redux-form";
import {defer} from "lodash";
import {getEntityById} from "../../../../selectors/entity";
import {translationLanguages} from "../../directory/translationLanguages";
import {submissionErrorToFormFields} from "../../../../utils/submissionErrorToFormFields";

class Input extends React.Component {
    render() {
        return <AntdInput autoComplete="none" {...this.props}/>;
    }
}

export default (connect(
    (state, {providerId}) => ({
        provider: getEntityById(state, providerId),
    }),
    dispatch => ({
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({provider, providerId, onRemovePanel}) => {
    const [loading, setLoading] = useState(false);
    const [iconBase64, setIconBase64] = useState(provider.icon);
    const [translations, setTranslations] = useState({});
    const [activeTranslation, setActiveTranslation] = useState();
    const [canDelete, setCanDelete] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        setTranslations(provider.translations);
        setActiveTranslation(
            Object.keys(provider.translations).length > 0
                ? Object.keys(provider.translations)[0]
                : undefined
        );
        defer(form.setFieldsValue, {
            ...provider,
            translations: {...provider.translations}
        });
    }, []);

    useEffect(() => {
        setCanDelete(provider.services.length === 0);
    }, [provider]);

    const handleSubmit = values => {

        values.translations = values.translations || {};
        Object.keys(values.translations).forEach((key) => {
            ['name', 'policyUrl'].forEach(prop => {
                if (String(values.translations[key][prop]).length === 0) {
                    values.translations[key][prop] = null;
                }
            });
        });

        setLoading(true);

        return fetch(providerId, {method: 'PUT', body: JSON.stringify(values)})
            .then(data => {
                setLoading(false);
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => setLoading(false));
    };

    const onTranslationTabsEdit = (targetKey, action) => {
        const t = {...translations};

        switch (action) {
            default:
                break;

            case 'add':
                t[targetKey] = {};
                setTranslations(t);
                setActiveTranslation(targetKey);
                break;

            case 'remove':
                delete t[targetKey];
                setTranslations(t);
                break;
        }
    };

    const doDelete = () => {
        setLoading(true);

        fetch(providerId, {method: 'DELETE'})
            .then(e => onRemovePanel(`cookieconsentproviders:${providerId}:edit`))
            .catch(e => {
                setLoading(false);
            })
    };

    return (
        <Spin spinning={loading} size="large">
            <Form layout="vertical" onFinish={handleSubmit} form={form}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography.Title ellipsis level={3}>
                        {provider.name}
                    </Typography.Title>
                    <Popconfirm  title={`"${provider.name}" wirklich löschen?`} onConfirm={doDelete} disabled={!canDelete}>
                        <Button icon={<DeleteOutlined />} shape="circle" style={{marginLeft: '.5em'}} disabled={!canDelete}/>
                    </Popconfirm>
                </div>

                <Typography.Title level={4} type="secondary">Anbieterdaten</Typography.Title>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Name" name={'name'} rules={[{type: 'string'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label="Kennung" name={'identifier'} rules={[{type: 'string'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label="URL zu Vertragsbedingungen" name={'policyUrl'} rules={[{type: 'url'}]}>
                            <Input type="url"/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Symbolbild" name={'icon'} rules={[]} valuePropName={'file'}>
                            <Upload.Dragger
                                listType="picture-card"
                                showUploadList={false}
                                beforeUpload={file => false}
                                onChange={info => {
                                    const reader = new FileReader();
                                    reader.addEventListener('load', () => {
                                        form.setFieldsValue({icon: reader.result});
                                        setIconBase64(reader.result);
                                    });
                                    reader.readAsDataURL(info.file);
                                }}
                            >
                                {iconBase64
                                    ? <img src={iconBase64} alt="Icon" style={{ width: '100%' }} />
                                    : <>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text"><small>Klicken oder eine Datei in diesen Bereich ziehen, um sie hochzuladen</small></p>
                                    </>
                                }
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider/>
                <Typography.Title level={4} type="secondary">
                    Übersetzungen
                    <Tooltip title="Übersetzung hinzufügen">
                        <Dropdown overlay={<Menu onClick={({key}) => onTranslationTabsEdit(key, 'add')}>
                            {Object.keys(translationLanguages).map(lang => <Menu.Item key={lang}>{translationLanguages[lang]}</Menu.Item>)}
                        </Menu>}>
                            <Button icon={<PlusCircleOutlined />} shape="circle" type="link"/>
                        </Dropdown>
                    </Tooltip>
                </Typography.Title>
                <Tabs
                    activeKey={activeTranslation}
                    animated
                    hideAdd
                    onChange={setActiveTranslation}
                    onEdit={onTranslationTabsEdit}
                    type="editable-card"
                >
                    {Object.keys(translations).map(key => (
                        <Tabs.TabPane forceRender={true} key={key} tab={translationLanguages[key]}>
                            <Form.Item name={['translations', key, '@id']} noStyle>
                                <Input type="hidden"/>
                            </Form.Item>
                            <Form.Item name={['translations', key, 'locale']} initialValue={key} rules={[{type: 'string'}]} noStyle>
                                <Input type="hidden"/>
                            </Form.Item>

                            <Row gutter={10} type="flex" justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Name" name={['translations', key, 'name']} rules={[{type: 'string'}]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="URL zu Vertragsbedingungen" name={['translations', key, 'policyUrl']} rules={[{type: 'url'}]}>
                                        <Input type="url"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    ))}
                </Tabs>

                <div style={{textAlign: 'right'}}>
                    <Button icon={<CloseOutlined />} size="large" style={{marginRight: 10}} onClick={() => onRemovePanel(`cookieconsentproviders:${providerId}:edit`)}>Abbrechen</Button>
                    <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">Speichern</Button>
                </div>
            </Form>
        </Spin>
    );
}));
