import {Input} from "antd";
import React from "react";

const Search = ({setSelectedKeys, confirm, clearFilters}) => {
    return <Input.Search
        allowClear
        onSearch={(value) => {
            setSelectedKeys([value]);

            if (value === '') {
              clearFilters();
            } else {
              confirm();
            }
          }
        }
        style={{minWidth: 150}}
    />
};

export default Search;
