import store from "../../store";

class StoreDispatcher {
  #_itemCollection = {};

  add(items) {
    items.forEach(item => {
      this.#_itemCollection[item['@id']] = item;
    });

    return this;
  }

  dispatch() {
    store.dispatch({
      type: 'ENTITIES_UPDATE',
      payload: Object.keys(this.#_itemCollection).map(v => this.#_itemCollection[v])
    });

    return this;
  }

  reset() {
    this.#_itemCollection = {};

    return this;
  }
}

export default StoreDispatcher;
