import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { CheckOutlined, CloseOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Avatar, Button, List, Spin, Tooltip, Form } from "antd";
import UserMentions from "../common/form/UserMentions";
import moment from "moment";
import {getEntitiesById, getEntitiesByType, getEntityById} from "../../selectors/entity";
import TokenStorage from "../../security/authentication/token/storage/TokenStorage";
import {fetch} from "../../api/rest";
import {SubmissionError} from "redux-form";
import {submissionErrorToFormFields} from "../../utils/submissionErrorToFormFields";

const CommentListItem = (connect(
    (state, {comment}) => ({
        creator: getEntityById(state, comment.createdBy),
        users: getEntitiesByType(state, 'User', ['email']),
    })
)(({comment, creator, users}) => {
    const [editing, setEditing] = useState(false);
    const [updating, setUpdating] = useState(false);
    const canEdit = !!creator && creator.email === TokenStorage.getToken().user.email && !editing;
    const [form] = Form.useForm();

    const doUpdate = values => {
        setUpdating(true);

        return fetch(comment['@id'], {method: 'PUT', body: JSON.stringify(values)})
            .then(json => {
                setEditing(false);
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => {
                setUpdating(false);
            })
    };

    return (
        <List.Item
            actions={[
                <Tooltip title="Kommentar bearbeiten">
                    <Button disabled={!canEdit} size="small" type="link" icon={<EditOutlined />} onClick={() => setEditing(true)}/>
                </Tooltip>,
                <Tooltip title={moment(comment.createdAt).format('LLLL')}>{moment(comment.createdAt).calendar()}</Tooltip>,
            ]}
        >
            <Spin spinning={updating}>
                <List.Item.Meta
                    avatar={creator && <Avatar size={64} icon={<UserOutlined />} src={`//loremflickr.com/200/200/kitten?lock=${creator.id}`} style={{boxShadow: '0 0 2px black'}}/>}
                    description={
                        <>
                            {!editing && comment.text}

                            {editing &&
                                <Form onFinish={doUpdate} initialValues={{'text': comment.text}} form={form}>
                                    <Form.Item name={'text'}>
                                    <UserMentions/>
                                    </Form.Item>
                                <Button.Group size="small">
                                    <Tooltip title="Speichern"><Button type="link" icon={<CheckOutlined />} htmlType="submit"/></Tooltip>
                                    <Tooltip title="Abbrechen"><Button type="link" icon={<CloseOutlined />} onClick={() => setEditing(false)}/></Tooltip>
                                </Button.Group>
                                </Form>
                            }
                        </>
                    }
                    title={creator && creator.email}
                />
            </Spin>
        </List.Item>
    );
}));

const CommentList = connect(
    (state, {commentIds}) => ({
        comments: getEntitiesById(state, commentIds),
    })
)(({commentIds, comments, customerId, updateHash}) => {
    const [limit, setLimit] = useState(3);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (commentIds.length > 0) {
            setLoading(true);
            fetch(customerId + '/comments?items_per_page=1000')
                .finally(() => {
                    setLoading(false);
            });
        }
    }, [customerId, updateHash]);

    return <List
        dataSource={comments.slice(0, limit)}
        itemLayout="vertical"
        loading={loading}
        loadMore={limit < comments.length ? <div style={{textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px'}}><Button type="dashed" onClick={() => setLimit(limit + 3)}>mehr laden</Button></div> : null}
        renderItem={item => <CommentListItem comment={item}/>}
    />
});

const CommentNew = (connect(
    (state) => ({
        users: getEntitiesByType(state, 'User', ['email']),
    })
)(({customerId, users}) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();

    const doSubmit = values => {
        setSubmitting(true);

        return fetch('/customers/comments', {method: 'POST', body: JSON.stringify({
                ...values,
                customer: customerId,
            })})
            .then(() => {
                form.setFieldsValue({text: ''});
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => {
                setSubmitting(false)
            })
    };

    return (
        <Spin spinning={submitting}>
            <Form onFinish={doSubmit} form={form}>
                <Form.Item name={'text'}>
                    <UserMentions/>
                </Form.Item>
                <Button.Group size="small">
                    <Tooltip title="Speichern"><Button type="link" icon={<CheckOutlined />} htmlType="submit"/></Tooltip>
                </Button.Group>
            </Form>
        </Spin>
    );
}));

export {
    CommentList,
    CommentNew,
};