import React, {useState} from "react";
import { PlusOutlined } from '@ant-design/icons';
import {Button, Spin, Tooltip} from "antd";
import UserListColumn from "../common/table/column/UserList";
import UserSelect from "../common/form/UserSelect";
import {fetch} from "../../api/rest";

export default ({editable, customerId, managerIds}) => {
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selection, setSelection] = useState(managerIds);
    const [changed, setChanged] = useState(false);

    const doApply = () => {
        if (!changed) {
            setEditing(false);
            return;
        }

        setLoading(true);

        fetch(customerId, {method: 'PUT', body: JSON.stringify({managers: selection})})
            .then(() => {
                setChanged(false);
                setEditing(false);
            })
            .catch(e => {})
            .finally(() => {
                setLoading(false);
            });
    };

    return <>
        {editing === false && <div style={{display: "flex", flexWrap: "wrap"}}>
            <UserListColumn userIds={managerIds}/>

            {editable &&
            <Tooltip title="Kundenbetreuer hinzufügen">
                <Button icon={<PlusOutlined />} shape="circle" type="dashed" onClick={() => setEditing(true)} style={{margin: '.125em'}}/>
            </Tooltip>
            }
        </div>}

        {editing && <Spin spinning={loading} style={{display: 'flex'}}>
            <UserSelect
                autoFocus
                mode="multiple"
                onBlur={doApply}
                onChange={(value, option) => {
                    setSelection(value);
                    setChanged(true);
                }}
                placeholder="Kundenbetreuer auswählen"
                value={selection}
            />
        </Spin>}
    </>;
};
