import {createStore, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';
import {persistStore} from 'redux-persist';
import rootReducer from '../reducers';

const logger = createLogger();

const store = createStore(
    rootReducer,
    undefined,
    applyMiddleware(logger)
);

export const persistor = persistStore(store);

export default store;
