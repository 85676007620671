import React from "react";
import {connect} from 'react-redux';
import { EditOutlined, PlusOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Input, Table } from "antd";
import {getEntitiesById} from '../../../selectors/entity';
import {addAction as addPanelAction} from "../../../actions/panel";
import Panel from "../../panel/Panel";
import SearchFilter from "../../common/table/filter/Search";
import {
    PANEL_USER_TEAM_EDIT,
    PANEL_USER_TEAM_NEW
} from "../../../factories/panel";
import UserColumn from "../../common/table/column/User"
import TableContainer from "../../common/table/Container";

const ListView = connect(
    (state, {itemIds}) => ({
        items: getEntitiesById(state, itemIds),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({filters, items, loading, onAddPanel, onChange, onSearch, pagination}) =>
<Panel
    backgroundColor="#ef7e06"
    title={<span><TeamOutlined /> Teamliste</span>}
    id="teams::list"
    width="auto"
>
    <div style={{display: 'flex'}}>
        <Input.Search
            allowClear
            size="large"
            placeholder="Suche nach Teams"
            onChange={e => onSearch(e.currentTarget.value)} onSearch={onSearch}
        />
        <Button size="large" style={{marginLeft: '16px'}} type="primary" onClick={() => onAddPanel(
            {
                type: PANEL_USER_TEAM_NEW,
                uid: `teams::new`,
            },
            'teams::list')}>
            <PlusOutlined /> Team hinzufügen
        </Button>
    </div>

    <Table
        dataSource={items}
        loading={loading}
        onChange={onChange}
        pagination={pagination}
        rowKey={record => record['@id']}
        size="small"
        style={{marginTop: '16px'}}
    >
        <Table.Column
            dataIndex="id"
            filterDropdown={props => <SearchFilter {...props}/>}
            title="ID"
        />

        <Table.Column
            dataIndex="name"
            title="Name"
            sorter={!filters.q}
            width={220}
        />

        <Table.Column
            dataIndex="leader"
            render={value => <UserColumn userId={value}/>}
            title="Leiter"
        />

        <Table.Column
            dataIndex="projectPrefix"
            title="Projekt-Prefix"
        />

        <Table.Column
            dataIndex="asanaId"
            render={(value) => <a href={`//app.asana.com/0/${value}`} target="_blank" rel="noreferrer noopener" alt="Asana-Link">{value}</a>}
            title="Asana-ID"
        />

        <Table.Column
            render={(value, record, index) => (
                <Button icon={<EditOutlined />} shape="circle" size="small" type="primary" onClick={() => onAddPanel({
                        type: PANEL_USER_TEAM_EDIT,
                        uid: `teams:${record['@id']}:edit`,
                        config: {
                            teamId: record['@id']

                        }},
                    'teams::list')}
                />
            )}
        />
    </Table>
</Panel>);

export default () => <TableContainer resourceUrl="users/teams">
    <ListView/>
</TableContainer>
