import React, {useEffect, useState} from 'react';

import TokenStorage from "./security/authentication/token/storage/TokenStorage";
import SecurityContext from "./security/SecurityContext";
import LoginPage from "./components/Login";
import Main from "./components/Main";

export default () => {
    const [security, setSecurity] = useState({
        isAuthenticated: false,
        authenticate: (token) => {
            TokenStorage.setToken(token);

            setSecurity({
                ...security,
                isAuthenticated: true,
                token: token
            });
        },
        deauthenticate: () => {
            TokenStorage.reset();

            setSecurity({
                ...security,
                isAuthenticated: false,
                token: null
            });
        }
    });

    useEffect(() => {
        const token = TokenStorage.getToken();

        if (token !== null) {
            security.authenticate(token);
        }
    }, []);

    return <SecurityContext.Provider value={security}>
        <SecurityContext.Consumer>{({isAuthenticated}) =>
            isAuthenticated
                ? <Main/>
                : <LoginPage/>
        }</SecurityContext.Consumer>
    </SecurityContext.Provider>
};
