import React from "react";

export default ({color = '#ececec', border = false, children}) => <span
    style={!!border ? {
        backgroundColor: color,
        border: '1px solid rgba(128, 128, 128, .5)',
        borderRadius: '3px',
        padding: '.5em 1em',
    } : {
        backgroundColor: color,
        borderRadius: '3px',
        padding: '.5em 1em',
    }}
>
    {children}
</span>
