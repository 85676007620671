import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getEntityById} from "../../../../../selectors/entity";
import {Col, Row, Typography} from "antd";
import {formatDuration} from "../../../../../utils/time";

export default connect(
  (state) => ({
    state: state
  })
)(({records, state}) => {
  const [hoursInternalWithBudget, setHoursInternalWithBudget] = useState(0);
  const [hoursInternal, setHoursInternal] = useState(0);
  const [hoursPaid, setHoursPaid] = useState(0);
  const [hoursSummary, setHoursSummary] = useState(0);

  useEffect(() => {
    let hoursInternalWithBudget = 0;
    let hoursInternal = 0;
    let hoursPaid = 0;
    let hoursSummary = 0;

    records.forEach(record => {
      const job = getEntityById(state, record.job);

      if (job) {
        const project = getEntityById(state, job.project);

        if (project) {
          if ((project.customer === '/customers/3' || project.customer === '/customers/900') && project.summary.monetaryBudgeted > 0) {
            hoursInternalWithBudget += record.duration;
          } else if ((project.customer === '/customers/3' || project.customer === '/customers/900') && project.summary.monetaryBudgeted === 0) {
            hoursInternal += record.duration;
          } else {
            hoursPaid += record.duration;
          }
        }
      }

      hoursSummary += record.duration;
    });

    setHoursInternalWithBudget(hoursInternalWithBudget);
    setHoursInternal(hoursInternal);
    setHoursPaid(hoursPaid);
    setHoursSummary(hoursSummary);
  }, [records]);

  return <Row gutter={10} type="flex" style={{
    flexWrap: 'nowrap',
    paddingBottom: 5,
    paddingTop: 5,
  }}>
    <Col style={{flexGrow: 1}}><strong>Gesamt</strong></Col>
    <Col span={2}>{formatDuration(hoursSummary, '%h:%mm')}</Col>
    <Col span={7}>
      <Typography.Text>
        {Math.round((hoursInternalWithBudget * 100 / hoursSummary) * 10) / 10} % Intern mit Budget
      </Typography.Text><br/>
      <Typography.Text style={{color: 'orange'}}>
        {Math.round((hoursInternal * 100 / hoursSummary) * 10) / 10} % Intern
      </Typography.Text><br/>
      <Typography.Text style={{color: 'green'}}>
        {Math.round((hoursPaid * 100 / hoursSummary) * 10) / 10} % Bezahlt
      </Typography.Text><br/>
    </Col>
  </Row>
});
