const addAction = (component, parentKey = null) => ({
    type: 'APP_PANEL_ADD',
    payload: {component, parentKey},
});

const removeAction = (uniqueKey) => ({
    type: 'APP_PANEL_REMOVE',
    payload: uniqueKey,
});

export {
    addAction,
    removeAction,
}
