import React, {useState} from "react";
import {connect} from "react-redux";
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Divider, Input as AntdInput, Spin, Row, Typography, Form } from "antd";
import UserSelect from "../../../common/form/UserSelect";
import {
    addAction as addPanelAction,
    removeAction as removePanelAction
} from "../../../../actions/panel";
import {fetch} from "../../../../api/rest";
import {SubmissionError} from "redux-form";
import {PANEL_USER_TEAM_EDIT} from "../../../../factories/panel";
import {submissionErrorToFormFields} from "../../../../utils/submissionErrorToFormFields";

class Input extends React.Component {
    render() {
        return <AntdInput autoComplete="none" {...this.props}/>;
    }
}

export default (connect(
    null,
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({onAddPanel, onRemovePanel}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleSubmit = values => {

        const {asanaId} = values;

        if (typeof asanaId === 'string' && asanaId.length === 0) values.asanaId = null;
        if (typeof asanaId === 'number') values.asanaId = String(asanaId);

        setLoading(true);

        return fetch('/users/teams', {method: 'POST', body: JSON.stringify(values)})
            .then(data => {
                setLoading(false);
                onAddPanel(
                    {
                        type: PANEL_USER_TEAM_EDIT,
                        uid: `teams:${data['@id']}:edit`,
                        config: {
                            teamId: data['@id']

                        }},
                    'teams::new'
                );
                onRemovePanel(`teams::new`);
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <Spin spinning={loading} size="large">
            <Form layout="vertical" onFinish={handleSubmit} form={form}>
                <Form.Item name={'name'} rules={[{type: 'string'}]}>
                    <Input placeholder="Name des Teams" size="large"/>
                </Form.Item>

                <Divider/>

                <Typography.Title level={4} type="secondary">Einstellungen</Typography.Title>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Leiter" name={'leader'}>
                            <UserSelect/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Asana-ID" name={'asanaId'} rules={[
                            {pattern: /^\d+$/, message: 'Dieses Feld darf nur Ziffern enthalten'}
                        ]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{textAlign: 'right'}}>
                    <Button icon={<CloseOutlined />} size="large" style={{marginRight: 10}} onClick={() => onRemovePanel('teams::new')}>Abbrechen</Button>
                    <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">Speichern</Button>
                </div>
            </Form>
        </Spin>
    );
}));
