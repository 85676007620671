import React, {useState} from "react";
import {connect} from "react-redux";
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Input as AntdInput,
    InputNumber,
    Row,
    Select,
    Spin,
    Tag,
    Tooltip,
    Typography,
    Form
} from "antd";
import {fetch} from "../../../api/rest";
import {SubmissionError} from "redux-form";
import NamedUrlCollection from "../../common/form/NamedUrlCollection";
import {significanceOptions} from "../../../utils/directory/customerData";
import CountryData from "i18n-iso-countries";
import moment from "moment";
import TokenStorage from "../../../security/authentication/token/storage/TokenStorage";
import {IntlContext} from "react-intl";
import {addAction as addPanelAction, removeAction as removePanelAction} from "../../../actions/panel";
import {PANEL_CUSTOMER_SHOW} from "../../../factories/panel";
import {submissionErrorToFormFields} from "../../../utils/submissionErrorToFormFields";

class Input extends React.Component {
    render() {
        return <AntdInput autoComplete="none" {...this.props}/>;
    }
}

export default (connect(
    null,
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({onAddPanel, onRemovePanel}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleSubmit = values => {
        const {shortName} = values;
        if (typeof shortName === 'string' && shortName.length === 0) {
            values.shortName = null; // null ignored by unique constraint in API
        }

        // cleanup when empty uri
        values.onlineProfiles = values.onlineProfiles.filter(value => value.uri);
        values.internalLinks = values.internalLinks.filter(value => value.uri);

        setLoading(true);

        return fetch('customers', {method: 'POST', body: JSON.stringify(values)})
            .then(data => {
                onAddPanel(
                    {
                        type: PANEL_CUSTOMER_SHOW,
                        uid: `customers:${data['@id']}:show`,
                        config: {
                            customerId: data['@id']

                        }},
                    'customers::new'
                );

                onRemovePanel(`customers::new`);
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <IntlContext.Consumer>{({locale}) => (
            <Spin spinning={loading} size="large">
                <Form layout="vertical" onFinish={handleSubmit} initialValues={{
                    'significance': 'standard',
                    'onlineProfiles': [{title: 'XING'}, {title: 'LinkedIn'}],
                    'internalLinks': [{title: 'Kunden-Ordner in G-Drive'}]
                    }}
                form={form}>
                    <Form.Item name={'name'} rules={[
                        {required: true, message: 'Diese Feld darf nicht leer sein.'}
                    ]}>
                        <Input placeholder="Kundenname" size="large"/>
                    </Form.Item>

                    <Tooltip title={moment().calendar() + ' erstellen'}>
                        <Tag color="blue">Ersteller: {TokenStorage.getToken().user.email}</Tag>
                    </Tooltip>


                    <Typography.Title level={4} type="secondary" style={{marginTop: 20}}>Zentrale Kontaktinformationen</Typography.Title>

                    <Row gutter={10} type="flex" justify="space-between" style={{marginTop: 12}}>
                        <Col span={11}>
                            <Form.Item label="Straße" name={'street'}>
                                <Input placeholder="Straße"/>
                            </Form.Item>

                            <Form.Item label="Postleitzahl" name={'postcode'}>
                                <Input placeholder="Postleitzahl"/>
                            </Form.Item>

                            <Form.Item label="Ort" name={'city'}>
                                <Input placeholder="Ort"/>
                            </Form.Item>

                            <Form.Item label="Land" name={'country'}>
                                    <Select placeholder="Land" showSearch filterOption={(input, option) => (option.props.children.toLowerCase().indexOf(input.toLowerCase()) !== -1)}>
                                        {Object.keys(CountryData.getNames(locale.substr(0,2))).map(value => (
                                            <Select.Option
                                                key={value}
                                                value={value}
                                            >
                                                {CountryData.getName(value, locale.substr(0,2))}
                                            </Select.Option>
                                        ))}
                                    </Select>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item label="Telefonnummer" name={'phoneNumber'}>
                                <Input type="tel" placeholder="+XX XXX XXXXXXX" pattern="^\+?[0-9\s]+\d$"/>
                            </Form.Item>

                            <Form.Item label="Mobilnummer" name={'mobileNumber'}>
                                <Input type="tel" placeholder="XXXX XXXXXXX" pattern="^\+?[0-9\s]+\d$"/>
                            </Form.Item>

                            <Form.Item label="Faxnummer" name={'faxNumber'}>
                                <Input type="tel" placeholder="+XX XXX XXXXXXX" pattern="^\+?[0-9\s]+\d$"/>
                            </Form.Item>

                            <Form.Item label="E-Mail-Adresse" name={'email'}>
                                <Input type="email" placeholder="E-Mail-Adresse"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider style={{marginBottom: 30, marginTop: 20}}/>

                    <Row gutter={10} type="flex" justify="space-between">
                        <Col span={11}>
                            <Typography.Title level={4} type="secondary">Interne Informationen</Typography.Title>

                            <Form.Item label="Kurzname" name={'shortName'}>
                                <Input placeholder="Internes Kürzel"/>
                            </Form.Item>

                            <Row gutter={10}>
                                <Col span={12}>
                                    <Form.Item label="Allg. Stundensatz" name={'charge'}>
                                        <InputNumber
                                            precision={2}
                                            decimalSeparator=","
                                            formatter={value => `€ ${value}`}
                                            parser={value => value.replace(/^€ /, '')}
                                            style={{width: '100%'}}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Status" name={'significance'}>
                                        <Select>
                                            {Object.keys(significanceOptions).map((value) => (
                                                <Select.Option key={value} value={value} style={{backgroundColor: significanceOptions[value].color}}>{significanceOptions[value].text}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item label="Tags" name={'tags'}>
                                <Select mode="tags" dropdownStyle={{display: 'none'}} tokenSeparators={[',']}/>
                            </Form.Item>

                            <Form.Item label="Vertraulichkeit" name={'confidentials'}>
                                <AntdInput.TextArea autoSize={{maxRows:10}} placeholder="Kunden vertraulich behandeln?"/>
                            </Form.Item>

                            <Form.Item label="Debitor-ID (Aucon)" name={['aucon', 'debitorId']} rules={[
                                {pattern: /^\d+$/, message: 'Dieses Feld darf nur Ziffern enthalten'}
                            ]}>
                                <Input/>
                            </Form.Item>

                            <Row gutter={10}>
                                <Col span={12}>
                                    <Form.Item label="ADV aktualisiert am" name={'advUpdatedAt'}>
                                        <DatePicker format="L"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={11}>
                            <Typography.Title level={4} type="secondary">Online-Profile</Typography.Title>
                            <Form.Item style={{paddingBottom: 0}} name={'homepage'}>
                                <Input
                                    addonBefore={<Tooltip title="Homepage">
                                        <div style={{width: 100}}>Homepage</div>
                                    </Tooltip>}
                                    placeholder="http://example.com"
                                    type="url"
                                />
                            </Form.Item>
                            <Form.Item name={'onlineProfiles'}>
                                <NamedUrlCollection addButtonText="Profil"/>
                            </Form.Item>

                            <Divider style={{marginBottom: 30, marginTop: 20}}/>

                            <Typography.Title level={4} type="secondary">Interne Links</Typography.Title>
                            <Form.Item name={'internalLinks'}>
                                <NamedUrlCollection addButtonText="Link"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item style={{textAlign: 'right'}}>
                        <Button size="large" type="primary" htmlType="submit">Hinzufügen</Button>
                    </Form.Item>
                </Form>
            </Spin>
        )}</IntlContext.Consumer>
    );
}));
