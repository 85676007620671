export const themeSettings = {
    width: 'Width',
    padding: 'Padding',
    border_radius: 'Border Radius',
    font_size: 'Font Size',
    background_color: 'Background Color',
    primary_color: 'Primary Color',
    secondary_color: 'Secondary Color',
    primary_text_color: 'Primary Text Color',
    secondary_text_color: 'Secondary Text Color',
    primary_font_family: 'Primary Font Family',
    primary_font_size: 'Primary Font Size',
    secondary_font_family: 'Secondary Font Family',
    primary_button_text_color: 'Primary Button Text Color',
    secondary_button_text_color: 'Secondary Button Text Color',
};
