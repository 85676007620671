import React, {useState} from "react";
import {connect} from 'react-redux';
import { CloseCircleFilled, CrownOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Table, Typography } from "antd";
import UserSelect from "../common/form/UserSelect";
import moment from 'moment';
import {getEntitiesById} from '../../selectors/entity';
import {addAction as addPanelAction} from "../../actions/panel";
import Panel from "../panel/Panel";
import {significanceOptions} from "../../utils/directory/customerData";
import {values} from 'lodash';
import UserListColumn from "../common/table/column/UserList";
import SearchFilter from "../common/table/filter/Search";
import {PANEL_CUSTOMER_EDIT, PANEL_CUSTOMER_NEW, PANEL_CUSTOMER_SHOW} from "../../factories/panel";
import TableContainer from "../common/table/Container";

const UserFilter = ({setSelectedKeys, confirm, clearFilters}) => {
    const [value, setValue] = useState();

    return (
        <UserSelect
            allowClear
            clearIcon={<CloseCircleFilled
                onClick={() => {
                    setValue();
                    clearFilters();
                }} />}
            dropdownMatchSelectWidth={false}
            onSelect={value => {
                setValue(value);
                setSelectedKeys(value);
                confirm();
            }}
            value={value}
        />
    );
};

const ListView = connect(
    (state, {itemIds}) => ({
        items: getEntitiesById(state, itemIds),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(props => <Panel title={<span><CrownOutlined /> Kundenliste</span>} id="customers::list" width="auto">
            <div style={{display: 'flex'}}>
                <Input.Search allowClear size="large" placeholder="Suche nach Kundeninformationen" onChange={e => props.onSearch(e.currentTarget.value)} onSearch={props.onSearch}/>
                <Button size="large" style={{marginLeft: '16px'}} type="primary" onClick={() => props.onAddPanel(
                    {
                        type: PANEL_CUSTOMER_NEW,
                        uid: `customers::new`,
                    },
                    'customers::list')}>
                    <PlusOutlined /> Kunde hinzufügen
                </Button>
            </div>

        <Table
            dataSource={props.items}
            footer={() => (
                <div style={{display: "flex", justifyContent: 'space-between'}}>
                    <Button type="primary" onClick={() => props.onAddPanel(
                        {
                            type: PANEL_CUSTOMER_NEW,
                            uid: `customers::new`,
                        },
                        'customers::list')}>
                        <PlusOutlined /> Kunde hinzufügen
                    </Button>
                </div>
            )}
            loading={props.loading}
            onChange={props.onChange}
            pagination={props.pagination}
            rowKey={record => record['@id']}
            size="small"
            style={{marginTop: '16px'}}
        >
            <Table.Column
                dataIndex="id"
                filterDropdown={props => <SearchFilter {...props}/>}
                title="Kd.-Nr."
            />

            <Table.Column
                dataIndex="name"
                render={(value, record, index) => (
                    <Button
                        onClick={() => props.onAddPanel({
                            type: PANEL_CUSTOMER_SHOW,
                            uid: `customers:${record['@id']}:show`,
                            config: {
                                customerId: record['@id']

                            }},
                            'customers::list'
                        )}
                        style={{
                            display: 'inline',
                            height: 'initial',
                            padding: '0',
                            textAlign: 'inherit',
                            whiteSpace: 'normal'
                        }}
                        type="link"
                    >{value}</Button>
                )}
                title="Kundenname"
                width={220}
            />

            <Table.Column
                dataIndex="significance"
                filters={Object.keys(significanceOptions).map(value => ({text: <><span style={{
                    backgroundColor: significanceOptions[value].color,
                    borderRadius: '50%',
                    boxShadow: '0 0 2px black',
                    display: 'inline-block',
                    height: '10px',
                    marginRight: '5px',
                    width: '10px',
                }}/>{significanceOptions[value].text}</>, value: value}))}
                render={(value, record, index) => <>
                    {!!value &&
                        <>
                            <span style={{
                                backgroundColor: significanceOptions[value].color,
                                borderRadius: '50%',
                                boxShadow: '0 0 2px black',
                                display: 'inline-block',
                                height: '10px',
                                marginRight: '5px',
                                width: '10px',
                            }}/>
                            {significanceOptions[value].text}
                        </>
                    }
                </>}
                title="Status"
            />

            <Table.Column
                dataIndex="advUpdatedAt"
                render={(value, record, index) => (
                    <Typography.Text>{!!value ? moment(value).format('L') : 'nicht vorhanden'}</Typography.Text>
                )}
                sorter={!props.filters.q}
                title="ADV"
            />

            <Table.Column
                dataIndex="managers"
                filterDropdown={props => <UserFilter {...props}/>}
                render={value => <div style={{display: "flex", flexWrap: "wrap"}}>
                    <UserListColumn autoLoad={false} userIds={values(value)}/>
                </div>}
                title="Kundenbetreuer"
                width={200}
            />

            <Table.Column
                render={(value, record, index) => (
                    <Button icon={<EditOutlined />} shape="circle" size="small" type="primary" onClick={() => props.onAddPanel({
                        type: PANEL_CUSTOMER_EDIT,
                        uid: `customers:${record['@id']}:edit`,
                        config: {
                            customerId: record['@id']

                        }},
                        'customers::list')}
                    />
                )}
                title="Aktionen"
            />
        </Table>
    </Panel>);

export default () => <TableContainer resourceUrl="customers">
    <ListView/>
</TableContainer>
