import React from "react";
import {connect} from "react-redux";
import Panel from "./Panel";
import {removeAction as removePanelAction} from "../../actions/panel";
import {Avatar, Button, Result} from "antd";

const NotificationsPanel = ({onRemovePanel}) => <Panel
    backgroundColor="red"
    id="notifications::list"
    title="Notifications"
    width="auto"
>
    <Result
        status="info"
        title="418"
        icon={<Avatar src="//svgsilh.com/svg/36089.svg" size={250}/>}
        subTitle="I’m a teapot"
        extra={<Button type="primary" onClick={() => onRemovePanel('notifications::list')}>close</Button>}
    />
</Panel>;

export default connect(
    null,
    dispatch => ({
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(NotificationsPanel);
