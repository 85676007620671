import React from "react";
import CustomerEditPanel from "../components/customer/Edit";
import CustomerListPanel from "../components/customer/List";
import CustomerNewPanel from "../components/customer/New";
import CustomerShowPanel from "../components/customer/Show";

import CustomerContactEditPanel from "../components/customercontact/Edit";
import CustomerContactNewPanel from "../components/customercontact/New";

import NotificationListPanel from "../components/panel/NotificationsPanel";

import ProjectEditPanel from "../components/project/Edit";
import ProjectListPanel from "../components/project/List";
import ProjectNewPanel from "../components/project/New";

import ProjectJobDailyReviewPanel from "../components/project/job/DailyReview";

import TimetrackingMainPanel from "../components/timetracking/Main";

import UserEditPanel from "../components/user/Edit";
import UserListPanel from "../components/user/List";
import UserNewPanel from "../components/user/New";

import UserTeamEditPanel from "../components/user/team/Edit";
import UserTeamListPanel from "../components/user/team/List";
import UserTeamNewPanel from "../components/user/team/New";

import CookieConsentProviderEditPanel from "../components/CookieConsent/provider/Edit";
import CookieConsentProviderListPanel from "../components/CookieConsent/provider/List";
import CookieConsentProviderNewPanel from "../components/CookieConsent/provider/New";

import CookieConsentProviderServiceEditPanel from "../components/CookieConsent/provider/service/Edit";
import CookieConsentProviderServiceNewPanel from "../components/CookieConsent/provider/service/New";

import CookieConsentSiteEditPanel from "../components/CookieConsent/site/Edit";
import CookieConsentSiteListPanel from "../components/CookieConsent/site/List";
import CookieConsentSiteNewPanel from "../components/CookieConsent/site/New";

import CookieConsentSiteGroupEditPanel from "../components/CookieConsent/site/group/Edit";
import CookieConsentSiteGroupNewPanel from "../components/CookieConsent/site/group/New";

import CookieConsentSiteGroupServiceEditDefaultPanel from "../components/CookieConsent/site/group/service/DefaultEdit";
import CookieConsentSiteGroupServiceNewDefaultPanel from "../components/CookieConsent/site/group/service/DefaultNew";

import CookieConsentSiteGroupServiceEditPrivatePanel from "../components/CookieConsent/site/group/service/PrivateEdit";
import CookieConsentSiteGroupServiceNewPrivatePanel from "../components/CookieConsent/site/group/service/PrivateNew";

export const PANEL_CUSTOMER_EDIT = 'CA1DBC9B-5037-42E4-ABBF-C0EB085CF8F1';
export const PANEL_CUSTOMER_LIST = '8E1BEE7E-5791-4A36-B230-A77091AC1AA0';
export const PANEL_CUSTOMER_NEW = '9F97E718-52FD-4A45-A2CA-D96F21C2BEA8';
export const PANEL_CUSTOMER_SHOW = 'E656188E-627D-455D-A506-FA7E891C03F4';

export const PANEL_CUSTOMER_CONTACT_EDIT = '67C33373-83C6-4DDB-9433-4D5A3081F8FD';
export const PANEL_CUSTOMER_CONTACT_NEW = '152943E1-5AA2-46D2-BB6A-3A06E474B8C9';

export const PANEL_NOTIFICATION_LIST = '46978B0A-FE7C-4D0E-8E55-F8592FB861B9';

export const PANEL_PROJECT_EDIT = '618760D9-8925-40B7-812C-DCA5C2DAC02C';
export const PANEL_PROJECT_LIST = '81C8F736-5340-4CF0-BAD3-9A68E8D84CE2';
export const PANEL_PROJECT_NEW = '40EE15FB-7E3E-45DB-B446-9018F37AED5B';

export const PANEL_PROJECT_JOB_DAILY_REVIEW = '8EE8F246-7275-4F7D-9567-86995B62D6A5';

export const PANEL_TIMETRACKING_MAIN = '0FB0D4B4-E630-47F2-A339-22B0F1E8DF05';

export const PANEL_USER_EDIT = 'D71427AB-AA84-4969-B5C1-3C9EAEADA815';
export const PANEL_USER_LIST = '391382A8-2FCC-4874-B9B3-F9B64A987DF2';
export const PANEL_USER_NEW = 'AD9D1565-F51D-499A-99C5-5AAEBA17E887';

export const PANEL_USER_TEAM_EDIT = '1D97BAA4-DF3C-47C8-A642-9689620D6593';
export const PANEL_USER_TEAM_LIST = '671B8FC4-CB58-4793-99C3-A4D0AD86F524';
export const PANEL_USER_TEAM_NEW = '6D2CCF59-E2A1-40E1-A9BA-8545045DBF4A';

export const PANEL_COOKIECONSENT_PROVIDER_EDIT = '9CABFCF0-CBA6-49DC-B21F-6F4DFC74A931';
export const PANEL_COOKIECONSENT_PROVIDER_LIST = '2A88E3E8-F38A-4C7C-B82D-E7106F8641DA';
export const PANEL_COOKIECONSENT_PROVIDER_NEW = '531B9E1F-2B76-4D2E-8C78-02A90F85CDB3';

export const PANEL_COOKIECONSENT_PROVIDER_SERVICE_EDIT = 'F5C5D387-C806-40FB-B589-BD2FE8774FA2';
export const PANEL_COOKIECONSENT_PROVIDER_SERVICE_NEW = '37FC0D2E-9D21-4935-B06A-8949A238DE38';

export const PANEL_COOKIECONSENT_SITE_EDIT = '8D734CB6-5A34-4D14-B67F-A9F4E58C5897';
export const PANEL_COOKIECONSENT_SITE_LIST = '97FDA1B9-11CD-42E8-96EB-B3DB4843317F';
export const PANEL_COOKIECONSENT_SITE_NEW = 'F4E22528-CE6D-4623-9948-F583A10F8347';

export const PANEL_COOKIECONSENT_SITE_GROUP_EDIT = '594642E7-C89D-483F-9949-598BF741406C';
export const PANEL_COOKIECONSENT_SITE_GROUP_NEW = '653C478B-224A-4811-BADC-73116CB3FC96';

export const PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_EDIT_DEFAULT = '4A38C861-1A76-4C4C-8EE5-47D8D1133319';
export const PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_NEW_DEFAULT = 'EB39B04A-A5C9-4AB5-AD11-2A4830F0A9AF';

export const PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_EDIT_PRIVATE = '618E21C7-F936-4ECB-B234-889B6B1CA90A';
export const PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_NEW_PRIVATE = '0DA4CE04-6557-4C24-9E5F-6B6A91FB6268';

export default function create({type, uid, config = {}}) {
    switch (type) {
        case PANEL_CUSTOMER_EDIT :
            return <CustomerEditPanel key={uid} customerId={config.customerId}/>;

        case PANEL_CUSTOMER_LIST :
            return <CustomerListPanel key={uid}/>;

        case PANEL_CUSTOMER_NEW :
            return <CustomerNewPanel key={uid}/>;

        case PANEL_CUSTOMER_SHOW :
            return <CustomerShowPanel key={uid} customerId={config.customerId}/>;

        case PANEL_CUSTOMER_CONTACT_EDIT :
            return <CustomerContactEditPanel key={uid} customerId={config.customerId} customerContactId={config.customerContactId}/>;

        case PANEL_CUSTOMER_CONTACT_NEW :
            return <CustomerContactNewPanel key={uid} customerId={config.customerId}/>;

        case PANEL_NOTIFICATION_LIST :
            return <NotificationListPanel key={uid}/>;

        case PANEL_PROJECT_EDIT :
            return <ProjectEditPanel key={uid} projectId={config.projectId}/>;

        case PANEL_PROJECT_LIST :
            return <ProjectListPanel key={uid}/>;

        case PANEL_PROJECT_NEW :
            return <ProjectNewPanel key={uid}/>;

        case PANEL_PROJECT_JOB_DAILY_REVIEW :
            return <ProjectJobDailyReviewPanel key={uid}/>;

        case PANEL_TIMETRACKING_MAIN :
            return <TimetrackingMainPanel key={uid}/>;

        case PANEL_USER_EDIT :
            return <UserEditPanel key={uid} userId={config.userId}/>;

        case PANEL_USER_LIST :
            return <UserListPanel key={uid}/>;

        case PANEL_USER_NEW :
            return <UserNewPanel key={uid}/>;

        case PANEL_USER_TEAM_EDIT :
            return <UserTeamEditPanel key={uid} teamId={config.teamId}/>;

        case PANEL_USER_TEAM_LIST :
            return <UserTeamListPanel key={uid}/>;

        case PANEL_USER_TEAM_NEW :
            return <UserTeamNewPanel key={uid}/>;

        case PANEL_COOKIECONSENT_PROVIDER_EDIT :
            return <CookieConsentProviderEditPanel key={uid} providerId={config.providerId}/>;

        case PANEL_COOKIECONSENT_PROVIDER_LIST :
            return <CookieConsentProviderListPanel key={uid}/>;

        case PANEL_COOKIECONSENT_PROVIDER_NEW:
            return <CookieConsentProviderNewPanel key={uid}/>;

        case PANEL_COOKIECONSENT_PROVIDER_SERVICE_EDIT :
            return <CookieConsentProviderServiceEditPanel key={uid} providerId={config.providerId} serviceId={config.serviceId}/>;

        case PANEL_COOKIECONSENT_PROVIDER_SERVICE_NEW:
            return <CookieConsentProviderServiceNewPanel key={uid} providerId={config.providerId}/>;

        case PANEL_COOKIECONSENT_SITE_EDIT :
            return <CookieConsentSiteEditPanel key={uid} siteId={config.siteId}/>;

        case PANEL_COOKIECONSENT_SITE_LIST :
            return <CookieConsentSiteListPanel key={uid}/>;

        case PANEL_COOKIECONSENT_SITE_NEW:
            return <CookieConsentSiteNewPanel key={uid}/>;

        case PANEL_COOKIECONSENT_SITE_GROUP_EDIT :
            return <CookieConsentSiteGroupEditPanel key={uid} siteId={config.siteId} groupId={config.groupId}/>;

        case PANEL_COOKIECONSENT_SITE_GROUP_NEW:
            return <CookieConsentSiteGroupNewPanel key={uid} siteId={config.siteId}/>;

        case PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_EDIT_DEFAULT :
            return <CookieConsentSiteGroupServiceEditDefaultPanel key={uid} groupId={config.groupId} serviceId={config.serviceId}/>;

        case PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_NEW_DEFAULT:
            return <CookieConsentSiteGroupServiceNewDefaultPanel key={uid} groupId={config.groupId}/>;

        case PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_EDIT_PRIVATE :
            return <CookieConsentSiteGroupServiceEditPrivatePanel key={uid} groupId={config.groupId} serviceId={config.serviceId}/>;

        case PANEL_COOKIECONSENT_SITE_GROUP_SERVICE_NEW_PRIVATE:
            return <CookieConsentSiteGroupServiceNewPrivatePanel key={uid} groupId={config.groupId}/>;

        default:
            break;
    }
};
