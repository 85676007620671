class HttpError extends Error {
    #message;
    #code;
    #parent;

    constructor(message = 'Http Error', code, parent) {
        super(message);

        this.#message = message;
        this.#code = code;
        this.#parent = parent;
    }

    get code() {
        return this.#code;
    }

    get parent() {
        return this.#parent;
    }

    get message() {
        return this.#message;
    }
}

export class ClientError extends HttpError {
    constructor(message = 'Client Error', code, parent) {
        super(...arguments);
    }
}

export class ForbiddenError extends HttpError {
    constructor(message = 'Forbidden', code=401, parent) {
        super(...arguments);
    }
}

export class NotFoundError extends ClientError {
    constructor(message = 'Not Found', code=404, parent) {
        super(...arguments);
    }
}

export class ServerError extends HttpError {
    constructor(message = 'Server Error', code, parent) {
        super(...arguments);
    }
}

export class UnauthorizedError extends ClientError {
    constructor(message = 'Unauthorized', code=403, parent) {
        super(...arguments);
    }
}
