export default class GoogleProfile {
    #_email;
    #_firstname;
    #_fullname;
    #_googleId;
    #_imageUrl;
    #_lastname;

    get email() {
        return this.#_email;
    }

    set email(value) {
        this.#_email = value;
    }

    get firstname() {
        return this.#_firstname;
    }

    set firstname(value) {
        this.#_firstname = value;
    }

    get fullname() {
        return this.#_fullname;
    }

    set fullname(value) {
        this.#_fullname = value;
    }

    get googleId() {
        return this.#_googleId;
    }

    set googleId(value) {
        this.#_googleId = value;
    }

    get imageUrl() {
        return this.#_imageUrl;
    }

    set imageUrl(value) {
        this.#_imageUrl = value;
    }

    get lastname() {
        return this.#_lastname;
    }

    set lastname(value) {
        this.#_lastname = value;
    }

    toArray() {
        return {
            email: this.email,
            firstname: this.firstname,
            fullname: this.fullname,
            googleId: this.googleId,
            imageUrl: this.imageUrl,
            lastname: this.lastname,
        };
    }
}
