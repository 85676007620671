import React from "react";
import {connect} from 'react-redux';
import {getEntitiesById} from "../../../../selectors/entity";
import {addAction as addPanelAction} from "../../../../actions/panel";
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {Button, Table, Tooltip, Typography} from "antd";
import {
    PANEL_COOKIECONSENT_SITE_GROUP_EDIT,
    PANEL_COOKIECONSENT_SITE_GROUP_NEW,
} from "../../../../factories/panel";
import TableContainer from "../../../common/table/Container";
import EntityLoader from "../../../common/EntityLoader";

const ListView = connect(
    (state, {itemIds}) => ({
        items: getEntitiesById(state, itemIds),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({filters, items, loading, onAddPanel, onChange, pagination, siteId}) =>
    <>
        <Typography.Title level={4} type="secondary">
            Gruppen
            <Tooltip title="Gruppe hinzufügen">
                <Button icon={<PlusCircleOutlined />} shape="circle" type="link" onClick={() => onAddPanel(
                    {
                        type: PANEL_COOKIECONSENT_SITE_GROUP_NEW,
                        uid: `cookieconsentsitegroups:${siteId}:new`,
                        config: {
                            siteId: siteId
                        }
                    },
                    `cookieconsentsites:${siteId}:edit`
                )}/>
            </Tooltip>
        </Typography.Title>

        <Table
            dataSource={items}
            loading={loading}
            onChange={onChange}
            pagination={pagination}
            rowKey={record => record['@id']}
            size="small"
            style={{marginTop: '16px'}}
        >
            <Table.Column
                dataIndex="id"
                title="#"
                render={(value, record, index) => index + 1 + (pagination.current - 1) * pagination.pageSize}
            />

            <Table.Column
                dataIndex="name"
                title="Name"
                sorter={!filters.q}
                width="100%"
            />

            <Table.Column
                dataIndex="position"
                title="Pos."
            />

            <Table.Column
                render={(value, record, index) => (
                    <Button icon={<EditOutlined />} shape="circle" size="small" type="primary" onClick={() => onAddPanel({
                            type: PANEL_COOKIECONSENT_SITE_GROUP_EDIT,
                            uid: `cookieconsentsitegroups:${record['@id']}:edit`,
                            config: {
                                siteId: siteId,
                                groupId: record['@id']
                            }},
                        `cookieconsentsites:${siteId}:edit`)}
                    />
                )}
            />
        </Table>
    </>
);

const LoaderWrapper = ({entity, entityId}) => <TableContainer resourceUrl={`${entityId}/groups`} reloadDeps={[entity]}>
    <ListView siteId={entityId}/>
</TableContainer>;

export default ({siteId}) => <EntityLoader entityId={siteId}>
    <LoaderWrapper/>
</EntityLoader>
