import React, {useState} from 'react';
import { GoogleOutlined, LoginOutlined, SecurityScanOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Layout, Steps, Typography } from "antd";
import GoogleLogin from "react-google-login";
import SecurityContext from "../security/SecurityContext";
import Token from "../security/authentication/token/Token";
import AppUser from "../security/user/AppUser";
import GoogleProfile from "../security/user/profile/GoogleProfile";
import {fetch} from "../api/rest";

const {Step} = Steps;

export default () => {
    const [currentStatus, setCurrentStatus] = useState('process');
    const [currentStep, setCurrentStep] = useState(0);

    const onSuccess = (response, callback) => {
        let {email, familyName, givenName, googleId, imageUrl, name} = response.profileObj;
        let googleProfile = new GoogleProfile();
        googleProfile.email = email;
        googleProfile.firstname = givenName;
        googleProfile.fullname = name;
        googleProfile.googleId = googleId;
        googleProfile.imageUrl = imageUrl;
        googleProfile.lastname = familyName;

        setCurrentStep(1);

        fetch('authentication/google', {
            method: 'POST',
            body: JSON.stringify({
                token: response.tokenId,
            })
        })
            .then(({user, roles, token}) => {
                setCurrentStep(2);

                const appUser = new AppUser(user.username);
                appUser.email = user.email;
                appUser.id = user.id;
                appUser.profiles.google = googleProfile;

                const userToken = new Token(appUser, roles, token);

                setTimeout(() => {
                    callback(userToken);
                }, 400);
            })
            .catch((error) => {
                setCurrentStatus('error');
                setTimeout(() => {
                    setCurrentStatus('process');
                    setCurrentStep(0)
                }, 400);
            });
    };

    const onFailure = (response, callback) => {
        setCurrentStatus('error');
        callback();
    };

    return (
        <SecurityContext.Consumer>{({authenticate, deauthenticate}) => (
            <Layout
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100vh',
                    justifyContent: 'center',
                }}
            >
                <Card
                    title={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Avatar size={72} src="android-chrome-144x144.png" alt="MM Zentrale - Logo"/>
                        <Typography.Title type="secondary" style={{marginBottom: 0}}>MANDARIN MEDIEN<br /> Zentrale</Typography.Title>
                    </div>} style={{width: '50%', minWidth: 700}}
                >
                    <Steps current={currentStep} status={currentStatus}>
                        <Step
                            icon={<GoogleOutlined />}
                            title={
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                    cookiePolicy={'single_host_origin'}
                                    onFailure={(response) => onFailure(response, deauthenticate)}
                                    onSuccess={(response) => onSuccess(response, authenticate)}
                                    render={({disabled, onClick}) => (
                                        <Button
                                            disabled={disabled || currentStep > 0}
                                            onClick={onClick}
                                            type="dashed"
                                        >Anmelden mit Google</Button>
                                    )}
                                />
                            }
                        />
                        <Step
                            icon={<SecurityScanOutlined />}
                            title="Überprüfen"
                        />
                        <Step
                            icon={<LoginOutlined />}
                            title="Los geht's"
                        />
                    </Steps>
                </Card>
            </Layout>
        )}</SecurityContext.Consumer>
    );
};
