import store from "./store";
//import { EventSourcePolyfill } from 'event-source-polyfill';

const hubUrl = new URL(process.env.REACT_APP_MERCURE_SUBSCRIBE_URL);

hubUrl.searchParams.append(
    'topic',
    '*'
);

//const eventSource = new EventSourcePolyfill(hubUrl.toString(), {
//    headers: {
//        Authorization: `Bearer ${process.env.REACT_APP_MERCURE_JWT_TOKEN}`
//    }
//});
const eventSource = new EventSource(hubUrl.toString());

eventSource.addEventListener('message', event => {
    const data = JSON.parse(event.data);

    if (1 === Object.keys(data).length) {
        return store.dispatch({type: 'ENTITY_REMOVE', payload: data});
    }

    return store.dispatch({type: 'ENTITIES_UPDATE', payload: [data]});
});
