import React from "react";
import {connect} from "react-redux";
import {Select} from "antd";
import {getEntitiesByType} from "../../../selectors/entity";

export default connect(
    (state) => ({
        users: getEntitiesByType(state, 'User', ['email']),
    }),
)((props) => <Select
    filterOption={(input, option) =>
        option.props.email.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    showSearch
    style={{width: '100%'}}
    {...props}
>
    {props.users.map(value => (
        <Select.Option key={value.id} value={value['@id']} email={value.email}>{value.email}</Select.Option>
    ))}
</Select>);
