import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getEntityById} from "../../../../../selectors/entity";
import {Button, Col, Row, Typography} from "antd";
import {formatDuration} from "../../../../../utils/time";
import CustomerColumn from "../../../../common/table/column/Customer";

export default connect(
  (state, {record}) => ({
    job: getEntityById(state, record.job),
    state: state
  })
)(({record, job, user, state}) => {
  const [project, setProject] = useState();
  const [parentProject, setParentProject] = useState();

  useEffect(() => {
    if (job) {
      setProject(getEntityById(state, job.project));
    }
  }, [job]);

  useEffect(() => {
    if (project) {
      setParentProject(getEntityById(state, project.parent));
    }
  }, [project]);

  return <Row gutter={10} type="flex" style={{
    borderBottom: '1px solid #f0f0f0',
    flexWrap: 'nowrap',
    paddingBottom: 5,
    paddingTop: 5,
  }}>
    <Col span={3}>
      {parentProject && `${parentProject.id} - `}
      {project && project.id}
    </Col>
    <Col span={4}>
      {project && project.name}
    </Col>
    <Col style={{flexGrow: 1, wordBreak: 'break-word'}}>
      {job && job.description}
    </Col>
    <Col span={4}><CustomerColumn customerId={project && project.customer}/></Col>
    <Col span={2}>
      {formatDuration(record.duration, '%h:%mm')}
    </Col>
    <Col span={3}>
      {project && <>
        {((project.customer === '/customers/3' || project.customer === '/customers/900') && project.summary.monetaryBudgeted > 0) &&
        <Typography.Text>
          Intern mit Budget
        </Typography.Text>}
        {((project.customer === '/customers/3' || project.customer === '/customers/900') && project.summary.monetaryBudgeted === 0) &&
        <Typography.Text style={{color: "orange"}}>
          Intern
        </Typography.Text>}
        {(project.customer !== '/customers/3' && project.customer !== '/customers/900') &&
        <Typography.Text style={{color: "green"}}>
          Bezahlt
        </Typography.Text>}
      </>}
    </Col>
    <Col span={4}>
      <Button size="small" block disabled>Slack mit {user.firstname}</Button>
    </Col>
  </Row>
});
