import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getEntityById} from "../../../../selectors/entity";
import {Col, Divider, Row, Typography} from "antd";
import RecordRow from "./useritem/RecordRow";
import RecordSummary from "./useritem/RecordSummary";

export default connect(
  (state, {userId, selectedTeamId}) => ({
    user: getEntityById(state, userId),
    selectedTeam: getEntityById(state, selectedTeamId),
    state: state
  })
)(({user, selectedTeamId, records, state}) => {
  const [userTeam, setUserTeam] = useState();
  useEffect(() => {
    if (user) {
      setUserTeam(getEntityById(state, user.currentTeam));
    }
  }, [user]);

  return (!!user && <>
    <Row gutter={10} type="flex" style={{alignItems: 'baseline', marginTop: 20}}>
      <Typography.Title level={4} type={user.currentTeam !== selectedTeamId ? 'warning' : null} style={{marginBottom: 0}}>
        {user.firstname} {user.lastname} ({user.email})
      </Typography.Title>

      {userTeam && <Typography.Text>
        {(selectedTeamId !== user.currentTeam) ? userTeam.name : ''}
      </Typography.Text>}
    </Row>

    <Row gutter={10} type="flex" style={{marginTop: 20, flexWrap: 'nowrap'}}>
      <Col span={3}><strong>Projektnummer</strong></Col>
      <Col span={4}><strong>Projektname</strong></Col>
      <Col style={{flexGrow: 1}}><strong>Task</strong></Col>
      <Col span={4}><strong>Kunde</strong></Col>
      <Col span={2}><strong>Dauer</strong></Col>
      <Col span={3}><strong>Status</strong></Col>
      <Col span={4}>&#160;</Col>
    </Row>

    {records.map((record, index) => <RecordRow record={record} user={user} key={index}/>)}

    <RecordSummary records={records}/>

    <Divider/>
  </>)
});
