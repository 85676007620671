import React, {useEffect} from "react";
import {connect} from 'react-redux';
import { EditOutlined, PlusOutlined, RobotOutlined } from '@ant-design/icons';
import { Avatar, Button, Table, Tooltip } from "antd";
import {getEntitiesById} from '../../../selectors/entity';
import {addAction as addPanelAction} from "../../../actions/panel";
import Panel from "../../panel/Panel";
import SearchFilter from "../../common/table/filter/Search";
import {
    PANEL_COOKIECONSENT_PROVIDER_EDIT,
    PANEL_COOKIECONSENT_PROVIDER_NEW
} from "../../../factories/panel";
import TableContainer from "../../common/table/Container";
import {EventEmitter} from "../../../events/EventEmitter";
import {COOKIECONSENT__PROVIDER_CREATE} from "../../../events/EventTypes";

const ListView = connect(
    (state, {itemIds}) => ({
        items: getEntitiesById(state, itemIds),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({filters, items, loading, onAddPanel, onChange, onSearch, pagination}) => {
    useEffect(() => {
        const createHandler = data => onChange(pagination, filters);

        EventEmitter.subscribe(COOKIECONSENT__PROVIDER_CREATE, createHandler);

        return () => {
            EventEmitter.unsubscribe(COOKIECONSENT__PROVIDER_CREATE, createHandler);
        }
    }, []);

    return (
        <Panel
            backgroundColor="#660e7a"
            title={<span><RobotOutlined /> Anbieterliste</span>}
            id="cookieconsentproviders::list"
            width="auto"
        >
            <div style={{display: 'flex'}}>
                {/*<Input.Search
                allowClear
                size="large"
                placeholder="Suche nach Anbietern"
                onChange={e => onSearch(e.currentTarget.value)} onSearch={onSearch}
            />
            */}
                <Button size="large" style={{marginLeft: 'auto'}} type="primary" onClick={() => onAddPanel(
                    {
                        type: PANEL_COOKIECONSENT_PROVIDER_NEW,
                        uid: `cookieconsentproviders::new`,
                    },
                    'cookieconsentproviders::list')}>
                    <PlusOutlined /> Anbieter hinzufügen
                </Button>
            </div>

            <Table
                dataSource={items}
                loading={loading}
                onChange={onChange}
                pagination={pagination}
                rowKey={record => record['@id']}
                size="small"
                style={{marginTop: '16px'}}
            >
                <Table.Column
                    dataIndex="id"
                    filterDropdown={props => <SearchFilter {...props}/>}
                    title="ID"
                />

                <Table.Column
                    dataIndex="icon"
                    render={(value, record) =>
                        <Tooltip title={record.title}>
                            <Avatar icon={<RobotOutlined />} src={value} style={{boxShadow: '0 0 2px black', margin: '.125em'}}/>
                        </Tooltip>
                    }
                />

                <Table.Column
                    dataIndex="name"
                    title="Name"
                    sorter={!filters.q}
                    width={220}
                />

                <Table.Column
                    render={(value, record, index) => (
                        <Button icon={<EditOutlined />} shape="circle" size="small" type="primary" onClick={() => onAddPanel({
                                type: PANEL_COOKIECONSENT_PROVIDER_EDIT,
                                uid: `cookieconsentproviders:${record['@id']}:edit`,
                                config: {
                                    providerId: record['@id']

                                }
                            },
                            'cookieconsentproviders::list')}
                        />
                    )}
                    title="Aktionen"
                />
            </Table>
        </Panel>
    );
});

export default () => <TableContainer resourceUrl="cookieconsent/providers">
    <ListView/>
</TableContainer>
