import TokenStorage from "../security/authentication/token/storage/TokenStorage";
import {Modal, notification} from "antd";
import { SubmissionError } from 'redux-form';
import {ForbiddenError, ServerError, NotFoundError, UnauthorizedError, ClientError} from "../errors/HttpErrors";
import store from '../store';
import StoreDispatcher from './utils/StoreDispatcher';
import HydraNormalizer from './utils/HydraNormalizer';

const MIME_TYPE = 'application/ld+json';
const ENTRYPOINT = process.env.REACT_APP_QMAI_API_ENTRYPOINT;

const storeDispatcher = new StoreDispatcher();
const hydraNormalizer = new HydraNormalizer(storeDispatcher);

const preFetch = (query, options = {}) => {
    return hydraNormalizer.prefetch(() => (fetch(query, options)));
};

const fetch = (query, options = {}) => {
    if ('undefined' === typeof options.headers) {
        options.headers = new Headers();
    }

    if (null === options.headers.get('Accept')) {
        options.headers.set('Accept', MIME_TYPE);
    }

    if (
        'undefined' !== options.body &&
        !(options.body instanceof FormData) &&
        null === options.headers.get('Content-Type')
    ) {
        options.headers.set('Content-Type', MIME_TYPE);
    }

    let token = TokenStorage.getToken();
    if (null !== token) {
        options.headers.set('Authorization', `Bearer ${token.rawToken}`);
    }

    return global.fetch(new URL(query, ENTRYPOINT), options)
        .then(response => {
            if (response.ok) {

                if (response.status === 204) {
                    store.dispatch({
                        type: 'ENTITY_REMOVE',
                        payload: {'@id': query}
                    });

                    return;
                }

                return response.json().then(
                    json => {
                        if (!!json['hydra:member']) {
                            storeDispatcher.add(json['hydra:member'].map(v => hydraNormalizer.normalize(v)))
                        } else if (!!json['@id']) {
                            storeDispatcher.add([hydraNormalizer.normalize(json)])
                        }

                        storeDispatcher.dispatch().reset();

                        return json;
                    }
                );
            }

            let error = response.statusText;
            const statusCode = response.status;

            return response.json().then(json => {
                if (!!json['message']) error = json['message'];
                if (!!json['hydra:description']) error = json['hydra:description'];

                switch (true) {
                    case 401 === statusCode:
                        throw new UnauthorizedError(error);
                    case 403 === statusCode:
                        throw new ForbiddenError(error);
                    case 404 === statusCode:
                        throw new NotFoundError(error);
                    case !!json.violations :
                        let errors = {};
                        json.violations.map(violation => errors[violation.propertyPath] = violation.message);
                        throw new SubmissionError(errors);
                    case (400<=statusCode)===(statusCode<=499):
                        throw new ClientError(error, statusCode);
                    case (500<=statusCode)===(statusCode<=599):
                        throw new ServerError(error, statusCode);
                    default:
                        break;
                }

                throw Error(error);
            }, e => {
                switch (true) {
                    case 401 === statusCode:
                        throw new UnauthorizedError(error);
                    case 403 === statusCode:
                        throw new ForbiddenError(error);
                    case 404 === statusCode:
                        throw new NotFoundError(error);
                    case (400<=statusCode)===(statusCode<=499):
                        throw new ClientError(error, statusCode);
                    case (500<=statusCode)===(statusCode<=599):
                        throw new ServerError(error, statusCode);
                    default:
                        break;
                }

                throw Error(error);
            })
        })
        .catch(error => {
            switch (true) {
                case error instanceof UnauthorizedError :
                    Modal.error({
                        centered: true,
                        content: 'Your session has expired. Please sign in again.',
                        okButtonProps: {
                            icon: 'logout',
                        },
                        okText: 'Logout',
                        onOk: (e) => {
                            TokenStorage.reset();
                            global.location.reload();
                        },
                        title: error.message || 'Session expired',
                    });
                    break;
                case error instanceof ForbiddenError :
                    notification.warning({
                        description: error.message,
                        message: 'Insufficient privileges to perform this action',
                    });
                    break;
                case error instanceof NotFoundError :
                    store.dispatch({type: 'ENTITY_REMOVE', payload: {'@id': query}});
                    break;
                case error instanceof SubmissionError :
                    notification.warning({
                        description: error.message,
                        message: 'Submission Error',
                    });
                    throw error;
                case error instanceof ClientError :
                    notification.error({
                        description: error.message,
                        message: 'Client Error',
                    });
                    break;
                case error instanceof ServerError :
                    notification.error({
                        description: error.message,
                        message: 'Server Error',
                    });
                    break;
                default :
                    break;
            }

            return Promise.reject();
        });
};

export {
    preFetch as fetch
};
