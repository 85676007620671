import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { CloseOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Col,
    Divider,
    Input as AntdInput,
    Popconfirm,
    Spin,
    Switch,
    Row,
    Typography,
    Form
} from "antd";
import {getEntityById} from "../../../selectors/entity";
import {
    addAction as addPanelAction,
    removeAction as removePanelAction
} from "../../../actions/panel";
import {fetch} from "../../../api/rest";
import {SubmissionError} from "redux-form";
import RemoteSelect from "../../common/form/RemoteSelect";
import {submissionErrorToFormFields} from "../../../utils/submissionErrorToFormFields";

class Input extends React.Component {
    render() {
        return <AntdInput autoComplete="none" {...this.props}/>;
    }
}

export default (connect(
    (state, {userId}) => ({
        user: getEntityById(state, userId),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })

)(({user, userId, onAddPanel, onRemovePanel}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => form.setFieldsValue(user), [form.setFieldsValue, user]);

    const handleSubmit = values => {
        const {asanaId, auconEmployeeNumber} = values;
        if (typeof asanaId === 'string' && asanaId.length === 0) values.asanaId = null;
        if (typeof asanaId === 'number') values.asanaId = String(asanaId);

        if (typeof auconEmployeeNumber === 'string') {
            if (auconEmployeeNumber.length === 0) {
                values.auconEmployeeNumber = null;
            } else {
                values.auconEmployeeNumber = Number(auconEmployeeNumber);
            }
        }

        setLoading(true);

        return fetch(userId, {method: 'PUT', body: JSON.stringify(values)})
            .then(data => {
                setLoading(false);
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => setLoading(false));
    };

    const doDelete = () => {
        setLoading(true);

        fetch(userId, {method: 'DELETE'})
            .then(e => onRemovePanel(`users:${userId}:edit`))
            .catch(e => {
                setLoading(false);
            })
    };

    return (
        <Spin spinning={loading} size="large">
            <Form layout="vertical" onFinish={handleSubmit} form={form}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography.Title ellipsis level={3}>
                        {user.username}<br />
                        <small>{user.email}</small>
                    </Typography.Title>
                    <Popconfirm title={`"${user.username}" wirklich löschen?`} onConfirm={doDelete}>
                        <Button icon={<DeleteOutlined />} shape="circle" style={{marginLeft: '.5em'}}/>
                    </Popconfirm>
                </div>

                <Divider/>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Vorname" name="firstname">
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Nachname" name="lastname">
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider/>
                <Typography.Title level={4} type="secondary">Einstellungen</Typography.Title>
                <Row gutter={10} type="flex">
                    <Col span={4}>
                        <Form.Item label="aktiviert" name={'active'} valuePropName={'checked'}>
                            <Switch size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Manager" name={'manager'} valuePropName={'checked'}>
                            <Switch size="small" />
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item label="Team" name={'currentTeam'} rules={[{required: true, message: 'Dieser Wert sollte nicht leer sein.'}]}>
                            <RemoteSelect resourceUrl="/users/teams?items_per_page=1000"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider/>

                <Typography.Title level={4} type="secondary">Mitarbeiterdaten</Typography.Title>
                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Asana-ID" name={'asanaId'} rules={[
                            {pattern: /^\d+$/, message: 'Dieses Feld darf nur Ziffern enthalten'}
                        ]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Personalnummer (Aucon)" name={'auconEmployeeNumber'} rules={[
                            {pattern: /^\d+$/, message: 'Dieses Feld darf nur Ziffern enthalten'}
                        ]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{textAlign: 'right'}}>
                    <Button icon={<CloseOutlined />} size="large" style={{marginRight: 10}} onClick={() => onRemovePanel(`users:${userId}:edit`)}>Abbrechen</Button>
                    <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">Speichern</Button>
                </div>
            </Form>
        </Spin>
    );
}));
