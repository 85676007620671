import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { CloseOutlined, InboxOutlined, SaveOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Input as AntdInput,
    Row,
    Select,
    Spin,
    Typography,
    Upload,
    Form
} from "antd";
import {fetch} from "../../../api/rest";
import {SubmissionError} from "redux-form";
import NamedUrlCollection from "../../common/form/NamedUrlCollection";
import moment from 'moment';
import {addAction as addPanelAction, removeAction as removePanelAction} from "../../../actions/panel";
import {getEntityById} from "../../../selectors/entity";
import {PANEL_CUSTOMER_SHOW} from "../../../factories/panel";
import EntityLoader from "../../common/EntityLoader";
import {submissionErrorToFormFields} from "../../../utils/submissionErrorToFormFields";

class Input extends React.Component {
    render() {
        return <AntdInput autoComplete="none" {...this.props}/>;
    }
}

const CustomerContactTitle = ({entity}) => <>{!!entity ? `${entity.salutation} ${entity.title} ${entity.firstname} ${entity.lastname}` : ''}</>;
const CustomerTitle = ({entity}) => <small>{entity && entity.name}</small>;

export default (connect(
    (state, {customerContactId}) => ({
        customerContact: getEntityById(state, customerContactId),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
        onRemovePanel: (uniqueKey) => dispatch(removePanelAction(uniqueKey)),
    })
)(({customerContact, customerContactId, customerId, onAddPanel, onRemovePanel}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (customerContact) {
            if (customerContact.birthday && !(customerContact.birthday instanceof moment)) {
                customerContact.birthday = moment(customerContact.birthday);
            }
            form.setFieldsValue(customerContact)
        }
    }, [customerContact]);

    const handleSubmit = values => {

        const {salutation} = values;

        if (salutation === undefined) {
            values.salutation = null; //make it nullable
        }

        // cleanup when empty uri
        values.onlineProfiles = values.onlineProfiles.filter(value => value.uri);

        setLoading(true);

        return fetch(customerContactId, {method: 'PUT', body: JSON.stringify(values)})
            .then(data => {
                onAddPanel(
                    {
                        type: PANEL_CUSTOMER_SHOW,
                        uid: `customers:${customerId}:show`,
                        config: {customerId}
                    },
                    `customercontacts:${customerContactId}:edit`
                );
                onRemovePanel(`customercontacts:${customerContactId}:edit`);
            })
            .catch(e => {
                if (e instanceof SubmissionError) {
                    form.setFields(submissionErrorToFormFields(e, form));
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <Spin spinning={loading} size="large">
            <Typography.Title level={3}>
                <EntityLoader entityId={customerContactId}>
                    <CustomerContactTitle/>
                </EntityLoader>
                <br />
                <EntityLoader entityId={customerId}>
                    <CustomerTitle/>
                </EntityLoader>
            </Typography.Title>

            <Form layout="vertical" onFinish={handleSubmit} initialValues={{'onlineProfiles': [{title: 'Website'},{title: 'XING'}, {title: 'LinkedIn'}]}} form={form}>
                <Typography.Title level={4} type="secondary">Zentrale Kontaktinformationen</Typography.Title>

                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Form.Item label="Titel" name={'title'}>
                            <Input placeholder="Titel"/>
                        </Form.Item>

                        <Row gutter={10}>
                            <Col span={8}>
                                <Form.Item label="Anrede" name={'salutation'}>
                                    <Select placeholder="Anrede" allowClear dropdownMatchSelectWidth={false}>
                                        <Select.Option value="Frau">Frau</Select.Option>
                                        <Select.Option value="Herr">Herr</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item label="Nachname" name={'lastname'}>
                                    <Input placeholder="Nachname"/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item label="Vorname" name={'firstname'}>
                            <Input placeholder="Vorname"/>
                        </Form.Item>

                        <Form.Item label="Position im Unternehmen" name={'position'}>
                            <Input placeholder="Position"/>
                        </Form.Item>

                        <Form.Item label="Geburtstag" name={'birthday'}>
                            <DatePicker format="L" style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item label="Telefonnummer" name={'phone'}>
                            <Input type="tel" placeholder="+XX XXX XXXXXXX" pattern="^\+?[0-9\s]+\d$"/>
                        </Form.Item>

                        <Form.Item label="Mobilnummer" name={'mobile'}>
                            <Input type="tel" placeholder="XXXX XXXXXXX" pattern="^\+?[0-9\s]+\d$"/>
                        </Form.Item>

                        <Form.Item label="Faxnummer" name={'fax'}>
                            <Input type="tel" placeholder="+XX XXX XXXXXXX" pattern="^\+?[0-9\s]+\d$"/>
                        </Form.Item>

                        <Form.Item label="E-Mail-Adresse" name={'email'}>
                            <Input type="email" placeholder="E-Mail-Adresse"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider style={{marginBottom: 30, marginTop: 20}}/>

                <Row gutter={10} type="flex" justify="space-between">
                    <Col span={11}>
                        <Typography.Title level={4} type="secondary">Profilbild</Typography.Title>
                        <Upload.Dragger beforeUpload={(file, fileList) => false}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text"><small>Klicken oder eine Datei in diesen Bereich ziehen, um sie hochzuladen</small></p>
                            <p className="ant-upload-hint">…noch nicht implementiert…</p>
                        </Upload.Dragger>
                    </Col>

                    <Col span={11}>
                        <Typography.Title level={4} type="secondary">Online-Profile</Typography.Title>
                        <Form.Item name={'onlineProfiles'}>
                            <NamedUrlCollection addButtonText="Online-Profil"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item style={{textAlign: 'right'}}>
                    <Button icon={<CloseOutlined />} size="large" style={{marginRight: 10}} onClick={() => {
                        onAddPanel(
                            {
                                type: PANEL_CUSTOMER_SHOW,
                                uid: `customers:${customerId}:show`,
                                config: {
                                    customerId: customerId
                                }
                            },
                            `customercontacts:${customerContactId}:edit`
                        );

                        onRemovePanel(`customercontacts:${customerContactId}:edit`)
                    }}>Abbrechen</Button>
                    <Button icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">Speichern</Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}));
