import React from "react";
import {connect} from 'react-redux';
import {getEntitiesById, getEntityById} from "../../../../selectors/entity";
import {addAction as addPanelAction} from "../../../../actions/panel";
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {Button, Table, Tooltip, Typography} from "antd";
import {
    PANEL_COOKIECONSENT_PROVIDER_SERVICE_EDIT,
    PANEL_COOKIECONSENT_PROVIDER_SERVICE_NEW,
} from "../../../../factories/panel";
import TableContainer from "../../../common/table/Container";

const ListView = connect(
    (state, {itemIds}) => ({
        items: getEntitiesById(state, itemIds),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({filters, items, loading, onAddPanel, onChange, pagination, providerId}) =>
    <>
        <Typography.Title level={4} type="secondary">
            Dienste
            <Tooltip title="Dienst hinzufügen">
                <Button icon={<PlusCircleOutlined />} shape="circle" type="link" onClick={() => onAddPanel(
                    {
                        type: PANEL_COOKIECONSENT_PROVIDER_SERVICE_NEW,
                        uid: `cookieconsentproviderservices:${providerId}:new`,
                        config: {
                            providerId: providerId
                        }
                    },
                    `cookieconsentproviders:${providerId}:edit`
                )}/>
            </Tooltip>
        </Typography.Title>

        <Table
            dataSource={items}
            loading={loading}
            onChange={onChange}
            pagination={pagination}
            rowKey={record => record['@id']}
            size="small"
            style={{marginTop: '16px'}}
        >
            <Table.Column
                dataIndex="id"
                title="#"
                render={(value, record, index) => index + 1 + (pagination.current - 1) * pagination.pageSize}
            />

            <Table.Column
                dataIndex="name"
                title="Name"
                sorter={!filters.q}
            />

            <Table.Column
                render={(value, record, index) => (
                    <Button icon={<EditOutlined />} shape="circle" size="small" type="primary" onClick={() => onAddPanel({
                            type: PANEL_COOKIECONSENT_PROVIDER_SERVICE_EDIT,
                            uid: `cookieconsentproviderservices:${record['@id']}:edit`,
                            config: {
                                providerId: providerId,
                                serviceId: record['@id']
                            }},
                        `cookieconsentproviders:${providerId}:edit`)}
                    />
                )}
                title="Aktionen"
            />
        </Table>
    </>
);

export default connect(
    (state, {providerId}) => ({
        provider: getEntityById(state, providerId),
    }),
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({provider, providerId}) => {
    return <TableContainer resourceUrl={`${providerId}/services`} reloadDeps={[provider]}>
        <ListView providerId={providerId}/>
    </TableContainer>
})
