const INITIAL_STATE = {
    items: {},
    positions: [],
    current: null,
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'APP_PANEL_ADD' : {
            const {items, positions} = {...state};
            const {component, parentKey} = action.payload;
            const {uid} = component;

            if (!items[uid]) {
                items[uid] = component;

                if (parentKey !== null) {
                    const position = positions.indexOf(parentKey);

                    if (position !== -1) {
                        positions.splice(position + 1, 0, uid);
                    }
                } else {
                    positions.push(uid);
                }
            }

            return {items, positions, current: uid}
        }

        case 'APP_PANEL_REMOVE' : {
            const {items, positions} = {...state};
            const uid = action.payload;

            if (!!items[uid]) {
                delete items[uid];

                const position = positions.indexOf(uid);
                positions.splice(position, 1);
            }

            return {items, positions}
        }

        default :
            return state;
    }
}

export default reducer;
