import React, {Fragment, useState} from "react";
import { connect } from 'react-redux';

import {
    ClockCircleOutlined,
    CrownOutlined,
    InsuranceOutlined,
    NotificationOutlined,
    ProjectOutlined,
    UserOutlined,
} from '@ant-design/icons';

import { AutoComplete, Menu } from "antd";
import {addAction as addPanelAction} from '../../actions/panel';
import {
    PANEL_COOKIECONSENT_SITE_LIST,
    PANEL_CUSTOMER_LIST,
    PANEL_CUSTOMER_SHOW,
    PANEL_NOTIFICATION_LIST,
    PANEL_PROJECT_LIST,
    PANEL_TIMETRACKING_MAIN
} from "../../factories/panel";
import {fetch} from "../../api/rest";
import {debounce, has} from "lodash"
import Highlighter from "react-highlight-words";
import useEventListener from "@use-it/event-listener";

const GlobalSearch = connect(
    null,
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({onAddPanel}) => {
    const [customers, setCustomers] = useState({total: 0, hits: []});

    const onSearch = debounce(value => {
        const params = {
            query: {
                multi_match: {
                    fields: ['*'],
                    query: value,
                    operator: 'and',
                }
            },
            highlight: {
                fields: {
                    '*': {
                        pre_tags: [
                            "@mmzentrale@"
                        ],
                        post_tags: [
                            "@/mmzentrale@"
                        ],
                    }
                }
            }
        };

        fetch('elasticsearch/customer?q=' + encodeURIComponent(JSON.stringify(params)))
            .then(data => {
                setCustomers({total: data.hits.total, hits: data.hits.hits})
            })
    }, 200, {trailing: true});

    const extractHighlights = hlText => {
        return Array
            .from(hlText.join().matchAll(/@mmzentrale@(.*?)@\/mmzentrale@/g))
            .map(match => match[1]);
    };

    const stringContains = (haystack, needles) => {
        let found = false;

        needles.forEach(needle => {
            if (String(haystack).indexOf(needle) !== -1) found = true
        });

        return found;
    };

    const customerOptions = () => {
        return {
            label: `Kunden (${customers.hits.length} gesamt)`,
            options: customers.hits.map(hit => {
                return {
                    value: hit._source.id,
                    label: (<Fragment>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                                <Highlighter
                                    autoEscape
                                    highlightStyle={{backgroundColor: 'rgba(249, 126, 6, .2)'}}
                                    highlightTag="span"
                                    textToHighlight={hit._source.name}
                                    searchWords={has(hit, 'highlight.name') ? extractHighlights(hit.highlight.name) : []}
                                />
                            </div>
                            {!!hit._source.shortName &&
                            <small style={{marginLeft: 10}}>
                                <Highlighter
                                    autoEscape
                                    highlightStyle={{backgroundColor: 'rgba(249, 126, 6, .2)'}}
                                    highlightTag="span"
                                    textToHighlight={hit._source.shortName}
                                    searchWords={has(hit, 'highlight.shortName') ? extractHighlights(hit.highlight.shortName) : []}
                                />
                            </small>
                            }
                        </div>
                        {has(hit, 'highlight') && <>
                            {(!!hit.highlight['contacts.firstname'] || !!hit.highlight['contacts.lastname']) &&
                            <div style={{display: 'flex'}}>
                                {hit._source.contacts.map((contact, index) => {
                                    if (stringContains(contact.firstname, !!hit.highlight['contacts.firstname'] ? extractHighlights(hit.highlight['contacts.firstname']) : [])
                                        || stringContains(contact.lastname, !!hit.highlight['contacts.lastname'] ? extractHighlights(hit.highlight['contacts.lastname']) : [])) {
                                        return (
                                            <small key={index}>
                                                <UserOutlined />
                                                <span style={{marginLeft: 2}}>
                                            <Highlighter
                                                autoEscape
                                                highlightStyle={{backgroundColor: 'rgba(249, 126, 6, .2)'}}
                                                highlightTag="span"
                                                textToHighlight={contact.firstname || ''}
                                                searchWords={!!hit.highlight['contacts.firstname'] ? extractHighlights(hit.highlight['contacts.firstname']) : []}
                                            />
                                        </span>
                                                <span style={{marginLeft: 2, marginRight: 10}}>
                                            <Highlighter
                                                autoEscape
                                                highlightStyle={{backgroundColor: 'rgba(249, 126, 6, .2)'}}
                                                highlightTag="span"
                                                textToHighlight={contact.lastname || ''}
                                                searchWords={!!hit.highlight['contacts.lastname'] ? extractHighlights(hit.highlight['contacts.lastname']) : []}
                                            />
                                        </span>
                                            </small>
                                        );
                                    } else {
                                        return <></>;
                                    }
                                })}
                            </div>
                            }
                        </>}
                    </Fragment>)
                }
            })
        }
    };

    return (
        <AutoComplete
            allowClear
            options={[customerOptions()]}
            onSearch={onSearch}
            onSelect={value => onAddPanel({
                type: PANEL_CUSTOMER_SHOW,
                uid: `customers:/customers/${value}:show`,
                config: {customerId: `/customers/${value}`}
            })}
            placeholder="Suche…"
            dropdownMatchSelectWidth={false}
            style={{ width: 175 }}
        />
    );
});

export default connect(
    null,
    dispatch => ({
        onAddPanel: (component, parentKey) => dispatch(addPanelAction(component, parentKey)),
    })
)(({onAddPanel}) => {
    const [keyboardStates, setKeyboardStates] = useState({});

    useEventListener('keydown', e => {
        const states = {...keyboardStates};
        states[e.code] = true;
        setKeyboardStates(states);
    });

    useEventListener('keyup', e => {
        const states = {...keyboardStates};
        states[e.code] = false;
        setKeyboardStates(states);
    });

    return (
        <Menu style={{border: '0 none'}} selectable={false}>
            <div style={{margin: '16px'}}>
                <GlobalSearch/>
            </div>
            <Menu.Item onClick={() => onAddPanel({type: PANEL_PROJECT_LIST, uid: 'projects::list'})}>
                <ProjectOutlined style={{color: '#3ed115', marginLeft: '.25em'}} />
                Projekte
            </Menu.Item>
            <Menu.Item onClick={() => onAddPanel({type: PANEL_CUSTOMER_LIST, uid: 'customers::list'})}>
                <CrownOutlined style={{color: '#3d8adf', marginLeft: '.25em'}} />
                Kunden
            </Menu.Item>
            {!!keyboardStates.KeyA && !!keyboardStates.AltLeft && <Menu.Item onClick={() => onAddPanel({type: PANEL_TIMETRACKING_MAIN, uid: 'timetracking::main'})}>
                <ClockCircleOutlined style={{color: '#5A6650', marginLeft: '.25em'}} />
                Zeiterfassung
            </Menu.Item>}
            <Menu.Item disabled onClick={() => onAddPanel({type: PANEL_NOTIFICATION_LIST, uid: 'notifications::list'})}>
                <NotificationOutlined style={{color: 'red', marginLeft: '.25em'}} />
                Benachrichtigungen
            </Menu.Item>
            <Menu.Item onClick={() => onAddPanel({type: PANEL_COOKIECONSENT_SITE_LIST, uid: 'cookieconsentsites::list'})}>
                <InsuranceOutlined style={{color: '#882f9c', marginLeft: '.25em'}} />
                Cookie Consent
            </Menu.Item>
        </Menu>
    );
});
