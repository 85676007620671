export const significanceOptions = {
    interested: {
        color: '#cff',
        text: 'Interessent',
    },
    undesirable: {
        color: '#fcc',
        text: 'Unerwünscht',
    },
    standard: {
        color: '#9cf',
        text: 'Kunde',
    },
    desirable: {
        color: '#cfc',
        text: 'Erwünscht',
    },
};
