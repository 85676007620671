import React, {useEffect} from "react";
import {connect} from "react-redux";
import {getEntityById} from "../../../selectors/entity";
import {Avatar, Tooltip} from "antd";
import {fetch} from "../../../api/rest";
import {removeAction as removeHistoryPanelAction} from "../../../actions/panelHistory";

export default connect(
    (state, {customerId}) => ({
        customer: getEntityById(state, customerId),
    }),
    dispatch => ({
        onRemoveHistoryPanel: (uniqueKey) => dispatch(removeHistoryPanelAction(uniqueKey)),
    })
)(({customer, customerId, onRemoveHistoryPanel}) => {
    useEffect(() => {
        if (!customer) {
            fetch(customerId).catch(() => onRemoveHistoryPanel(`customers:${customerId}:show`));
        }
    }, [customer, customerId, onRemoveHistoryPanel]);

    return <>
        <Tooltip title={customer && customer.name} placement="topRight">
            <Avatar size="small" shape="square" style={{backgroundColor: '#3d8adf' ,marginRight: '.5em'}}>
                {customer && customer.name.substr(0, 2).toUpperCase()}
            </Avatar>
        </Tooltip>
        <span>{customer && customer.name}</span>
    </>
});
